import { ResizeMode, Video } from 'expo-av';
import Constants from 'expo-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { Image, Platform, ScrollView, View } from 'react-native';
import { Caption, List, Text, Title } from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';
import { BackHeader } from '../components/BackHeader';
import { API_ENDPOINT, DISCOVERY_EVENT_ID } from '../config';
import { useEvent } from '../events/useEvent';
import { useEvents } from '../events/useEvents';
import { PASTEL_PINK, PRIMARY_DARK } from '../theming';
import { useThemedColor } from '../utils/useThemedColor';

export function TestConnectionScreen() {
  const isMountedRef = useIsMounted();
  const { data: eventsData } = useEvents();
  const events = eventsData?.events;

  const eventHref = useMemo(
    () =>
      events?._index.find((item) =>
        item.href.includes(DISCOVERY_EVENT_ID ?? '__not-set')
      ),
    [events]
  );

  const { data: eventData } = useEvent(eventHref?.href);
  const event = eventData?.event;

  const backendUrl = API_ENDPOINT!;
  const assetServer = Constants.manifest?.bundleUrl || window?.location?.origin;
  const [remoteImageServer, setRemoteImageServer] = useState<
    string | undefined
  >();
  const remoteAudioServer = '-';

  const [backendStatus, setBackendStatus] = useState<boolean | undefined>();
  const [assetStatus, setAssetStatus] = useState<boolean | undefined>();
  const [backendImageStatus, setBackendImageStatus] = useState<
    boolean | undefined
  >();
  const [remoteImageStatus, setRemoteImageStatus] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    fetch(backendUrl, {
      method: 'HEAD',
      headers: {
        accept: 'application/vnd.soundersmusic.configuration.v1.public+json',
      },
    })
      .then((r) => true)
      .catch(() => false)
      .then((result) => {
        isMountedRef.current && setBackendStatus(result);
      });
  }, [backendUrl, isMountedRef, setBackendStatus]);

  useEffect(() => {
    try {
      console.log(require('../../assets/icon.png'));
      setAssetStatus(true);
    } catch {
      setAssetStatus(false);
    }
  }, [setAssetStatus]);

  useEffect(() => {
    const url = event?._links?.foreground_image?.href;

    if (!url) {
      return;
    }

    fetch(url, {
      method: 'HEAD',
      redirect: 'manual',
    })
      .then(() => true)
      .catch((err) => {
        console.error(err.name, err);
        return false;
      })
      .then((result) => {
        isMountedRef.current && setBackendImageStatus(result);
      });
  }, [event?._links?.foreground_image?.href]);

  useEffect(() => {
    const url = event?._links?.foreground_image?.href;

    if (!url) {
      return;
    }

    fetch(url, {
      method: 'GET',
      redirect: 'follow',
      mode: Platform.OS === 'web' ? 'no-cors' : 'cors',
    })
      .then((response) => {
        setRemoteImageServer(response.url);
        return true;
      })
      .catch(() => {
        return false;
      })
      .then((result) => {
        isMountedRef.current && setRemoteImageStatus(result);
      });
  }, [event?._links?.foreground_image?.href]);

  const [imageError, setImageError] = useState<Error | undefined>();

  return (
    <ScrollView
      style={{
        width: '100%',
        height: '100%',
        maxHeight: Platform.select({ web: '100vh', default: '100%' }),
      }}
      contentContainerStyle={{
        width: '100%',
        maxWidth: 600,
        minHeight: '100%',
        alignSelf: 'center',
        overflow: 'hidden',

        paddingTop: 128,
      }}
    >
      <View
        style={{
          width: '100%',
          maxWidth: '100%',
          flex: 1,
          justifyContent: 'space-between',
          maxHeight: 720,
        }}
      >
        <Text
          variant="titleMedium"
          style={{
            color: useThemedColor(PRIMARY_DARK, PASTEL_PINK),
            fontSize: 30,
            letterSpacing: 30 / 20,
            lineHeight: 34,
            maxWidth: 250,
            marginHorizontal: 38,
            marginBottom: 16,
          }}
        >
          Test connection
        </Text>

        <View style={{ marginBottom: 'auto', marginHorizontal: 26 }}>
          <List.Item
            title="API server"
            description={hostName(backendUrl)}
            right={(props) => (
              <List.Icon
                {...props}
                icon={
                  backendStatus === undefined
                    ? 'progress-clock'
                    : backendStatus
                    ? 'check'
                    : 'close'
                }
              />
            )}
          />

          <List.Item
            title="Bundled assets"
            description={hostName(assetServer)}
            right={(props) => (
              <List.Icon
                {...props}
                icon={
                  assetStatus === undefined
                    ? 'progress-clock'
                    : assetStatus
                    ? 'check'
                    : 'close'
                }
              />
            )}
          />

          <List.Item
            title="Backend images"
            description={hostName(event?._links?.foreground_image?.href)}
            right={(props) => (
              <List.Icon
                {...props}
                icon={
                  backendImageStatus === undefined
                    ? 'progress-clock'
                    : backendImageStatus
                    ? 'check'
                    : 'close'
                }
              />
            )}
          />

          <List.Item
            title="Remote images"
            description={hostName(remoteImageServer)}
            right={(props) => (
              <List.Icon
                {...props}
                icon={
                  remoteImageStatus === undefined
                    ? 'progress-clock'
                    : remoteImageStatus
                    ? 'check'
                    : 'close'
                }
              />
            )}
          />

          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                borderWidth: 1,
                borderColor: PRIMARY_DARK,
                width: 100,
                height: 100,
                marginHorizontal: 16,
              }}
            >
              <Image
                source={{
                  uri: event?._links?.foreground_image?.href,
                  width: 100,
                  height: 100,
                }}
                resizeMode="contain"
                onError={(err) => setImageError(err.nativeEvent.error)}
              />
            </View>

            <Caption>{imageError ? imageError.message : ''}</Caption>
          </View>
        </View>
      </View>

      <Video
        nativeID="video"
        useNativeControls
        source={{
          uri: 'http://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
        }}
        shouldPlay
        resizeMode={ResizeMode.COVER}
        style={{
          backgroundColor: 'red',

          flex: 1,
          width: '100%',
          position: 'relative',
          minHeight: 400,
        }}
      />

      <View
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <BackHeader />
      </View>
    </ScrollView>
  );
}

function hostName(url?: string) {
  if (!url) {
    return '-';
  }

  return url.replace(/https?:\/\//, '').split('/')[0];
}
