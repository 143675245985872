import { useLinkTo } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Appbar, Avatar, Button, Text } from 'react-native-paper';
import {
  RemoteAccountProfile,
  useProfile,
  useThumbnail,
} from '../account/useUserProfile';
import { ScreenReaderHidden } from '../components/ScreenReaderHidden';
import { useLocale } from '../hooks/useLocale';
import { useToken } from '../hooks/useToken';
import { defineTranslations, i18n } from '../locale';
import { useWindowDimensions } from '../utils/useLerpWidth';
import { useOpenUrl } from '../utils/useOpenUrl';
import { useCloseMenu } from './MenuActionContext';

defineTranslations({
  en: {
    app: {
      menu: {
        statistics: {
          tracks: {
            label: 'Tracks rated',
          },
          prediction: {
            label: 'Hit prediction',
          },
        },
        links: {
          account: 'Account',
          taste: 'Music taste',
          settings: 'Settings',
          about: 'About Sounders',
          legal: 'Legal',
        },
        actions: {
          logout: 'Sign out',
        },
      },
    },
  },

  nl: {
    app: {
      menu: {
        statistics: {
          tracks: {
            label: 'Aantal stemmen',
          },
          prediction: {
            label: 'Hit voorspelling',
          },
        },
        links: {
          account: 'Account',
          taste: 'Muzieksmaak',
          settings: 'Instellingen',
          about: 'Over Sounders',
          legal: 'Voorwaarden',
        },
        actions: {
          logout: 'Uitloggen',
        },
      },
    },
  },
});

export function SideMenu() {
  const close = useCloseMenu();
  const gotoPath = useLinkTo();
  const { width, height } = useWindowDimensions();

  const gotoAccount = useCallback(() => {
    gotoPath('/settings/account');
    close();
  }, [close]);

  const gotoMusicTaste = useCallback(() => {
    gotoPath('/settings/profile');
    close();
  }, [close]);

  const gotoSettings = useCallback(() => {
    gotoPath('/settings');
    close();
  }, [close]);

  const gotoAbout = useOpenUrl('https://soundersmusic.com');
  const gotoLegal = useOpenUrl('https://soundersmusic.com/terms-of-service');

  const { data: profile } = useProfile();
  const { uri, size } = useThumbnail();

  return (
    <View
      style={{
        width: '100%',
        alignSelf: 'center',
        height: '100%',
        maxHeight: '100%',
      }}
    >
      <Appbar.Header
        style={[
          styles.header,
          {
            elevation: 0,
            backgroundColor: 'transparent',
            paddingHorizontal: 36,
            height: 52 + 36,
            width,
            top: 0,
            position: 'absolute',
          },
        ]}
        statusBarHeight={0}
      >
        <Avatar.Image
          source={{ uri, width: size, height: size }}
          size={52}
          style={{ backgroundColor: '#121212' }}
        />
        <View
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            paddingHorizontal: 12,
          }}
        >
          <Text
            variant="titleSmall"
            style={{
              color: '#fff',
              textAlign: 'left',
              includeFontPadding: false,
              textAlignVertical: 'bottom',
              fontSize: 15,
              width: '100%',
              paddingRight: 36,
              fontWeight: 'bold',
              display: 'flex',
            }}
            numberOfLines={1}
          >
            {profile?.name}
          </Text>
          <Text
            variant="titleSmall"
            style={{
              color: '#ffffff',
              opacity: 0.8,
              textAlign: 'left',
              includeFontPadding: false,
              textAlignVertical: 'top',
              fontSize: 15,
              fontWeight: 'normal',
              width: '100%',
              paddingRight: 36,
              display: 'flex',
            }}
            numberOfLines={1}
          >
            {profile?.email}
          </Text>
        </View>
      </Appbar.Header>

      <View
        style={{
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <View
          style={{
            alignSelf: 'center',
            justifyContent: 'space-between',
            height: '100%',
            maxHeight: Math.min((height * 550) / 812, 600),
            marginVertical: 'auto',
            flex: 1,
            width: '100%',
            paddingTop: Platform.OS === 'ios' ? 24 : 8,
          }}
        >
          <Statistics profile={profile} />

          <Menu
            gotoAccount={gotoAccount}
            gotoMusicTaste={gotoMusicTaste}
            gotoSettings={gotoSettings}
          />
          <Links gotoAbout={gotoAbout} gotoLegal={gotoLegal} />

          <Logout close={close} />
        </View>
      </View>
    </View>
  );
}

function Statistics({ profile }: { profile?: RemoteAccountProfile }) {
  const locale = useLocale();

  return (
    <View
      style={{
        marginHorizontal: 36,
        marginTop: 12,
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <View
        style={{
          backgroundColor: '#12121233',
          borderRadius: 5,
          marginBottom: 2,
          padding: 14,
          paddingLeft: 20,
        }}
      >
        <ScreenReaderHidden>
          <Text
            variant="labelSmall"
            style={{
              color: '#FFFFFF7F',
              textTransform: 'uppercase',
              fontSize: 10,
              lineHeight: 11,
              display: 'flex',
            }}
          >
            {i18n.translate('app.menu.statistics.tracks.label')}
          </Text>
        </ScreenReaderHidden>
        <Text
          style={{
            color: 'white',
            fontSize: 16,
            lineHeight: 18,
            display: 'flex',
          }}
          accessibilityLanguage={locale}
          accessibilityLabel={i18n.translate(
            'app.menu.statistics.tracks.label'
          )}
        >
          {profile?._links.rated_tracks.count || '-'}
        </Text>
      </View>

      <View
        style={{
          backgroundColor: '#12121233',
          borderRadius: 5,
          marginBottom: 2,
          padding: 14,
          paddingLeft: 20,
        }}
      >
        <ScreenReaderHidden>
          <Text
            variant="labelSmall"
            style={{
              color: '#FFFFFF7F',
              textTransform: 'uppercase',
              fontSize: 10,
              lineHeight: 11,
              display: 'flex',
            }}
          >
            {i18n.translate('app.menu.statistics.prediction.label')}
          </Text>
        </ScreenReaderHidden>
        <Text
          style={{
            color: 'white',
            fontSize: 16,
            lineHeight: 18,
            display: 'flex',
          }}
          accessibilityLanguage={locale}
          accessibilityLabel={i18n.translate(
            'app.menu.statistics.prediction.label'
          )}
        >
          -
        </Text>
      </View>
    </View>
  );
}

function Menu({
  gotoAccount,
  gotoMusicTaste,
  gotoSettings,
}: {
  gotoAccount: () => void;
  gotoMusicTaste: () => void;
  gotoSettings: () => void;
}) {
  const locale = useLocale();

  return (
    <View style={{ marginHorizontal: 24 }}>
      <Button
        mode="text"
        textColor="white"
        uppercase={false}
        style={styles.primary}
        labelStyle={styles.primaryLabel}
        onPress={gotoAccount}
        accessibilityLanguage={locale}
      >
        {i18n.translate('app.menu.links.account')}
      </Button>
      <Button
        mode="text"
        textColor="white"
        uppercase={false}
        style={styles.primary}
        labelStyle={styles.primaryLabel}
        onPress={gotoMusicTaste}
        accessibilityLanguage={locale}
      >
        {i18n.translate('app.menu.links.taste')}
      </Button>
      <Button
        mode="text"
        textColor="white"
        uppercase={false}
        style={styles.primary}
        labelStyle={styles.primaryLabel}
        onPress={gotoSettings}
        accessibilityLanguage={locale}
      >
        {i18n.translate('app.menu.links.settings')}
      </Button>
    </View>
  );
}

function Links({
  gotoAbout,
  gotoLegal,
}: {
  gotoAbout: () => void;
  gotoLegal: () => void;
}) {
  const locale = useLocale();

  return (
    <View style={{ marginHorizontal: 24 }}>
      <Button
        mode="text"
        textColor="#ffffff7f"
        uppercase={false}
        style={styles.secondary}
        labelStyle={styles.secondaryLabel}
        onPress={gotoAbout}
        accessibilityLanguage={locale}
      >
        {i18n.translate('app.menu.links.about')}
      </Button>
      <Button
        mode="text"
        textColor="#ffffff7f"
        uppercase={false}
        style={styles.secondary}
        labelStyle={styles.secondaryLabel}
        onPress={gotoLegal}
        accessibilityLanguage={locale}
      >
        {i18n.translate('app.menu.links.legal')}
      </Button>
    </View>
  );
}

function Logout({ close }: { close: () => void }) {
  const { logout } = useToken();

  const signOut = useCallback(() => {
    logout();

    requestAnimationFrame(() => close());
  }, [logout, close]);

  return (
    <View style={{ marginHorizontal: 36 }}>
      <Button
        mode="outlined"
        onPress={signOut}
        style={styles.signout}
        labelStyle={styles.signoutLabel}
        uppercase={false}
      >
        {i18n.translate('app.menu.actions.logout')}
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    zIndex: 1,
    maxWidth: 800,
    margin: 'auto',
    width: '100%',
  },
  primary: {
    alignItems: 'flex-start',
    marginBottom: 4,
    borderRadius: 8,
  },
  primaryLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    includeFontPadding: false,
    textAlignVertical: 'center',
  },
  secondary: {
    alignItems: 'flex-start',
    marginBottom: 4,
    borderRadius: 8,
  },
  secondaryLabel: {
    fontSize: 14,
    fontWeight: 'normal',
    includeFontPadding: false,
    textAlignVertical: 'center',
  },
  signout: {
    marginRight: 'auto',
    borderRadius: 26,
    borderColor: '#FFFFFF70',
  },
  signoutLabel: {
    color: '#fff',
    fontSize: 16,
    includeFontPadding: false,
    textAlignVertical: 'center',
  },
  buttonLabel: {},
});
