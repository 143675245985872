import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { ReadonlyDeep } from 'type-fest';
import { useLocale } from '../hooks/useLocale';
import { useToken } from '../hooks/useToken';
import { authorization } from '../utils/authorization';

export type ApiArtist = ReadonlyDeep<{
  artist: {
    _links: {
      self: {
        href: string;
      };

      website_url?: {
        href: string;
      };
      spotify_url?: {
        href: string;
      };
      twitter_url?: {
        href: string;
      };
      instagram_url?: {
        href: string;
      };
      youtube_url?: {
        href: string;
      };
      facebook_url?: {
        href: string;
      };
      tiktok_url?: {
        href: string;
      };
      bandcamp_url?: {
        href: string;
      };
      soundcloud_url?: {
        href: string;
      };
    };
    name: string;
    description?: string;
  };
}>;

export function useArtist(
  url: string | undefined | null,
  {
    enabled = true,
    onError,
    ...options
  }: UseQueryOptions<ApiArtist, FetchMediaError | Error> = {}
) {
  const { ref, token, logout } = useToken();
  const locale = useLocale();

  return useQuery<ApiArtist, FetchMediaError | Error>(
    [locale, url, 'artist'] as QueryKey,
    async ({ signal }) => {
      if (!ref.current) {
        throw new Error('Expected logged in, actual: no token');
      }

      const response = await fetchMedia(url!, {
        headers: {
          accept:
            'application/vnd.soundersmusic.artist.v2+json, application/vnd.soundersmusic.artist.v1+json; q=0.9',
          acceptLanguage: locale,
          authorization: authorization(ref.current)!,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
      });

      if (response && typeof response === 'object') {
        if ('artist' in response) {
          return response as ApiArtist;
        }

        throw new FetchMediaError(
          'Expected artist in response, actual: ' +
            Object.keys(response).join(', '),
          { status: 500, statusText: '' }
        );
      }

      throw new FetchMediaError(
        'Expected artist response, actual:' + typeof response,
        { status: 500, statusText: '' }
      );
    },
    {
      enabled: Boolean(url && token && enabled),

      onError(error) {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            logout();
          }
        }

        if (onError) {
          onError(error);
        }
      },

      ...options,
    }
  );
}
