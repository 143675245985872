import branch from 'branch-sdk';
import { BRANCH_API_KEY } from './config';

export async function getInitialUrl(): Promise<string | undefined> {
  return new Promise((resolve) => {
    if (BRANCH_API_KEY) {
      branch.init(BRANCH_API_KEY, undefined, (_, data) => {
        console.warn(data);
        resolve(undefined);
      });
    } else {
      resolve(undefined);
    }
  });
}

export function subscribe(_listener: ({ url }: { url: string }) => void) {}

export function unsubscribe() {}
