import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { View } from 'react-native';
import { Card, Paragraph, Surface, Title, useTheme } from 'react-native-paper';
import { AspectRatio } from '../components/AspectRatio';
import { DARK_PURPLE } from '../theming';

export function SkeletonCards() {
  return (
    <React.Fragment>
      <IndexedSkeletonCard index={0} />
      <IndexedSkeletonCard index={1} />
      <IndexedSkeletonCard index={2} />
      <IndexedSkeletonCard index={3} />
      <IndexedSkeletonCard index={4} />
    </React.Fragment>
  );
}

export function IndexedSkeletonCard({
  index,
  title,
}: {
  index: number;
  title?: string;
}) {
  const { dark } = useTheme();

  if (index > 4) {
    return null;
  }

  const key = index === 0 ? 'discovery' : `placeholder-${index}`;
  const ratio = index === 0 ? 1 : 346 / 280;
  const backgroundColor = skeletonBackground(index, dark);

  return (
    <SkeletonCard
      ratio={ratio}
      backgroundColor={backgroundColor}
      title={title}
      key={key}
    />
  );
}

export function skeletonBackground(index: number, dark: boolean) {
  if (dark) {
    return (
      [DARK_PURPLE, '#2A164C', '#371D63', '#45247B', '#522A93'][index] ||
      '#522A93'
    );
  }

  return ['#666', '#888', '#AAA', '#CCC', '#EEE'][index] || '#EEE';
}

export function SkeletonCard({
  ratio,
  title,
  backgroundColor,
  children,
}: React.PropsWithChildren<{
  ratio: number;
  backgroundColor: string;
  title?: string;
}>) {
  return (
    <AspectRatio
      style={[
        {
          width: '100%',
          overflow: 'visible',
          marginBottom: 16,
        },
        { aspectRatio: ratio },
      ]}
      key="discovery"
    >
      <Surface
        key="event"
        theme={{ roundness: 26 / 4 }}
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          backgroundColor,
          borderRadius: 10,
        }}
        elevation={1}
      >
        <View
          style={{
            paddingHorizontal: 20,
            paddingVertical: 15,
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <Title
            numberOfLines={2}
            style={{
              letterSpacing: 0.2,
              maxWidth: 400,
              marginRight: 72,
              minHeight: 40,
              includeFontPadding: false,
            }}
          >
            {title || ' '}
          </Title>
          <Paragraph style={{ color: '#ddd' }}>{children || null}</Paragraph>
        </View>

        <Card
          elevation={2}
          style={{
            width: 52,
            height: 52,
            borderRadius: 26,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: 20,
            right: 20,
            backgroundColor: `#222`,
          }}
        >
          <MaterialCommunityIcons
            name="timer-sand-full"
            size={24}
            color="#fff"
          />
        </Card>
      </Surface>
    </AspectRatio>
  );
}
