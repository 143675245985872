import { FetchMediaError } from 'fetch-media';
import React from 'react';
import { Card, Caption, Button } from 'react-native-paper';

export function NetworkError({
  error,
  loading,
  retry,
}: {
  error?: Error | null;
  loading: boolean;
  retry: () => void;
}) {
  if (!error) {
    return null;
  }

  if (error instanceof FetchMediaError) {
    if (error.response.status >= 500) {
      return (
        <Card
          style={{ marginBottom: 16, borderRadius: 26, marginHorizontal: 8 }}
        >
          <Card.Title
            title="Something went wrong"
            subtitle={error.response.statusText || error.response.status}
          />
          <Card.Content>
            <Caption>
              We'll investigate this issue -- a notification has already been
              sent. You can retry again, but it's unlikely that that will
              resolve the issue.
            </Caption>
            <Button
              mode="contained"
              onPress={retry}
              disabled={loading}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
              style={{ marginRight: 'auto', marginTop: 16 }}
            >
              Retry
            </Button>
          </Card.Content>
        </Card>
      );
    }

    if (error.response.status === 422) {
      return (
        <Card style={{ marginBottom: 16, borderRadius: 26 }}>
          <Card.Title
            title="Unable to process the request"
            subtitle={error.response.statusText || error.response.status}
          />
          <Card.Content>
            <Caption>
              You may try again, or try again later. If this problem persists,
              please contact us, so we can look into resolving this.
            </Caption>
            <Button
              mode="contained"
              onPress={retry}
              disabled={loading}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
              style={{ marginRight: 'auto', marginTop: 16 }}
            >
              Retry
            </Button>
          </Card.Content>
        </Card>
      );
    }
  }

  return (
    <Card style={{ marginBottom: 16, borderRadius: 26 }}>
      <Card.Content>
        <Caption>
          You may try again, or try again later. If this problem persists,
          please contact us, so we can look into resolving this.
        </Caption>
        <Caption style={{ marginTop: 12 }}>{error.message}</Caption>
        <Button
          mode="contained"
          onPress={retry}
          disabled={loading}
          labelStyle={{
            includeFontPadding: false,
            textAlignVertical: 'center',
          }}
          style={{ marginRight: 'auto', marginTop: 16 }}
        >
          Retry
        </Button>
      </Card.Content>
    </Card>
  );
}
