import { fetchMedia, FetchMediaError } from 'fetch-media';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { ReadonlyDeep } from 'type-fest';
import { useLocale } from '../hooks/useLocale';
import { useToken } from '../hooks/useToken';
import { authorization } from '../utils/authorization';

export type ApiFreshestRanking = ReadonlyDeep<{
  ranking: {
    _links: {
      self: {
        href: string;
      };
    };
    _embedded: {
      _links: {
        self: {
          href: string;
        };
        cover?: {
          href: string;
        };
        share?: {
          href: string;
        };
      };
      rank: number;
      count: number;
      title: string;
      _embedded: {
        artist: {
          _links: {
            self: {
              href: string;
            };
            image?: {
              href: string;
            };
          };
          name: string;
        };
      };
    }[];
  };
}>;

export type ApiNominalRanking = ReadonlyDeep<{
  ranking: {
    _links: {
      self: {
        href: string;
      };
    };
    _embedded: {
      _links: {
        self: {
          href: string;
        };
        cover?: {
          href: string;
        };
        share?: {
          href: string;
        };
      };
      ranked: boolean;
      rank: number | null;
      score: null | number;
      vote_count: number;
      title: string;
      _embedded: {
        artist: {
          _links: {
            self: {
              href: string;
            };
            image: {
              href?: string;
            };
          };
          name: string;
        };
      };
    }[];
  };
}>;

export function useEventRanking(
  url: string | undefined | null,
  type: 'freshest',
  options: UseQueryOptions<ApiFreshestRanking, FetchMediaError | Error>
): UseQueryResult<ApiFreshestRanking, FetchMediaError | Error>;
export function useEventRanking(
  url: string | undefined | null,
  type: 'nominal',
  options: UseQueryOptions<ApiNominalRanking, FetchMediaError | Error>
): UseQueryResult<ApiNominalRanking, FetchMediaError | Error>;
export function useEventRanking<
  T extends ApiFreshestRanking | ApiNominalRanking
>(
  url: string | undefined | null,
  type: 'freshest' | 'nominal',
  {
    enabled = true,
    onError,
    ...options
  }: UseQueryOptions<T, FetchMediaError | Error> = {}
) {
  const { ref, token, logout } = useToken();
  const locale = useLocale();

  return useQuery<T, FetchMediaError | Error>(
    [locale, url, 'event'] as QueryKey,
    async ({ signal }) => {
      if (!ref.current) {
        throw new Error('Expected logged in, actual: no token');
      }

      const response = await fetchMedia(url!, {
        headers: {
          accept: [
            type === 'freshest'
              ? 'application/vnd.soundersmusic.event.ranking.v1.freshest+json'
              : 'application/vnd.soundersmusic.event.ranking.v1.nominal+json',
          ].join(', '),
          acceptLanguage: locale,
          authorization: authorization(ref.current)!,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
        debug: __DEV__ && false,
      });

      if (response && typeof response === 'object') {
        if ('ranking' in response) {
          return response as T;
        }

        throw new FetchMediaError(
          'Expected event in response, actual: ' +
            Object.keys(response).join(', '),
          { status: 500, statusText: '' }
        );
      }

      throw new FetchMediaError(
        'Expected event response, actual:' + typeof response,
        { status: 500, statusText: '' }
      );
    },
    {
      enabled: Boolean(url && token && enabled),

      onError(error) {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            logout();
          }
        }

        if (onError) {
          onError(error);
        }
      },

      ...options,
    }
  );
}
