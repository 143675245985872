import { View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { BackHeader } from './BackHeader';

export function LoadingScreen() {
  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <View
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <BackHeader />
      </View>

      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    </View>
  );
}
