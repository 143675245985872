import { useEffect } from 'react';
import { getInitialUrl, subscribe } from '../deeplink';

type BranchListener = (url: string) => void;

const branchListeners: BranchListener[] = [];
const branchUrls: string[] = [];

function emit(url: string) {
  branchUrls.push(url);
  branchListeners.forEach((listener) => listener(url));
}

export function init() {
  getInitialUrl()
    .then((url) => url && emit(url))
    .catch(() => {});

  subscribe(({ url }) => emit(url));
}

export function useBranchUrls() {
  return branchUrls as readonly string[];
}

export function useBranchUrl(onBranchUrl: BranchListener) {
  useEffect(() => {
    branchListeners.push(onBranchUrl);

    return () => {
      const index = branchListeners.indexOf(onBranchUrl);
      if (index !== -1) {
        branchListeners.splice(index, 1);
      }
    };
  }, [onBranchUrl]);
}
