import {
  getActionFromState,
  getStateFromPath,
  NavigationContainerRef,
} from '@react-navigation/core';
import { LinkingOptions } from '@react-navigation/native';
import { Alert } from 'react-native';
import { INTERNAL_PREFIXES } from '../config';
import { LINKING_CONFIG } from './LinkingConfiguration';
import { navigationRef } from './NavigationReadyContext';

export function resetToPath(
  path: string,
  config:
    | undefined
    | LinkingOptions<ReactNavigation.RootParamList>['config'] = LINKING_CONFIG,
  navigation: NavigationContainerRef<ReactNavigation.RootParamList> = navigationRef
) {
  if (
    INTERNAL_PREFIXES.some(
      (prefix) =>
        path.startsWith(prefix) || path.startsWith(prefix.toLocaleLowerCase())
    )
  ) {
    path = INTERNAL_PREFIXES.reduce(
      (nextPath, prefix) =>
        nextPath.replace(prefix, '').replace(prefix.toLocaleLowerCase(), ''),
      path
    );
  }

  if (path[0] !== '/') {
    path = `/${path}`;
  }

  const state = getStateFromPath(path, config);

  if (!state) {
    return false;
  }

  const action = getActionFromState(state);
  if (action === undefined) {
    return false;
  }

  console.log('[deeplink] internal', path, action);
  navigation.reset(state);
  return true;
}

export function goToPath(
  path: string,
  config:
    | undefined
    | LinkingOptions<ReactNavigation.RootParamList>['config'] = LINKING_CONFIG,
  navigation: NavigationContainerRef<ReactNavigation.RootParamList> = navigationRef
) {
  if (
    INTERNAL_PREFIXES.some(
      (prefix) =>
        path.startsWith(prefix) || path.startsWith(prefix.toLocaleLowerCase())
    )
  ) {
    path = INTERNAL_PREFIXES.reduce(
      (nextPath, prefix) =>
        nextPath.replace(prefix, '').replace(prefix.toLocaleLowerCase(), ''),
      path
    );
  }

  if (path[0] !== '/') {
    path = `/${path}`;
  }

  const state = getStateFromPath(path, config);

  if (!state) {
    return false;
  }

  const action = getActionFromState(state);
  if (action === undefined) {
    return false;
  }

  console.log('[deeplink] internal', path, action);
  navigation.dispatch(action);
  return true;
}

export function dispatch(
  action: NonNullable<ReturnType<typeof getActionFromState>>,
  navigation: NavigationContainerRef<ReactNavigation.RootParamList> = navigationRef
) {
  navigation.dispatch(action);
}
