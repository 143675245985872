import { ResponseType } from 'expo-auth-session';
import { useAuthRequest } from 'expo-auth-session/providers/facebook';
import Constants from 'expo-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { Card, IconButton } from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';
import {
  CLIENT_ID_FACEBOOK,
  EXPERIENCE_SLUG,
  EXPO_IDENTIFIER,
  EXPO_SLUG,
} from '../../config';
import { AuthSuccess, ExternalAuthenticate } from '../useAuthentication';

const discovery = {
  authorizationEndpoint: 'https://www.facebook.com/v13.0/dialog/oauth',
  tokenEndpoint: 'https://graph.facebook.com/v13.0/oauth/access_token',
};

const useProxy = Platform.OS === 'ios';

export type FacebookProps = Pick<
  ExternalAuthenticate,
  'isLoading' | 'mutateAsync'
>;

function Facebook_(props: FacebookProps) {
  if (!CLIENT_ID_FACEBOOK || Platform.OS === 'ios') {
    return null;
  }

  return <FacebookButton {...props} />;
}

function FacebookButton({ isLoading, mutateAsync: attempt }: FacebookProps) {
  const [isAuthenticating, setAuthenticating] = useState(false);

  const config = useMemo(
    () => ({
      clientId: CLIENT_ID_FACEBOOK!,
      scopes: ['public_profile', 'email'],
      responseType: ResponseType.Code,
      extraParams: {
        // Use `popup` on web for a better experience
        display: Platform.select({ web: 'popup' }) as string,
        // Optionally you can use this to rerequest declined permissions
        // auth_type: 'rerequest',
      },
    }),
    []
  );

  // console.log({ useProxy, EXPERIENCE_SLUG });

  const [request, response, promptAsync] = useAuthRequest(config, {
    projectNameForProxy: EXPERIENCE_SLUG,
    scheme: EXPO_IDENTIFIER,
  });

  const isMounted = useIsMounted();

  useEffect(() => {
    if (!response) {
      return;
    }

    isMounted.current && setAuthenticating(false);

    if (response.type === 'success') {
      const { code, state } = response.params;

      const result: AuthSuccess<'facebook'> = {
        type: 'facebook',
        success: true,
        redirect_uri: fixRedirectUri(request?.redirectUri!),
        code,
        code_verifier: request!.codeVerifier,
        state,
      };

      isMounted.current && attempt(result).catch(() => {});
    }
  }, [request?.redirectUri, response, attempt, setAuthenticating]);

  return (
    <Card
      style={{
        borderRadius: 30,
        backgroundColor: '#347AE5',
        marginHorizontal: 4,
      }}
      elevation={2}
    >
      <IconButton
        disabled={!request || isAuthenticating || isLoading}
        size={27.5}
        iconColor="#FFFFFF"
        icon="facebook"
        style={{ backgroundColor: '#347AE5' }}
        onPress={() => {
          setAuthenticating(true);
          promptAsync({
            useProxy,
            windowFeatures: { width: 700, height: 600 },
          });
        }}
      />
    </Card>
  );
}

function fixRedirectUri(redirectUri: string, forProxy?: boolean) {
  if (forProxy) {
    return redirectUri;
  }

  if (Platform.OS !== 'web' && !redirectUri.startsWith('fb')) {
    return redirectUri;
  }

  if (Platform.OS === 'web' && redirectUri !== window.origin) {
    return redirectUri;
  }

  return redirectUri + '/';
}

export const Facebook = React.memo(Facebook_);
