import React from 'react';
import { Button } from 'react-native-paper';
import { PRIMARY_DARK, PRIMARY_LIGHT } from '../theming';

export function InvertedButton({
  label,
  onPress,
  loading,
  disabled,
}: {
  label: string;
  onPress: () => void;
  loading?: boolean;
  disabled?: boolean;
}) {
  return (
    <Button
      mode="contained"
      theme={{ colors: { primary: '#FFFFFF' }, roundness: 26 / 4 }}
      labelStyle={{
        color: PRIMARY_DARK,
        fontSize: 18,
        paddingHorizontal: 18,
        paddingVertical: 4,
        includeFontPadding: false,
        textAlignVertical: 'center',
      }}
      onPress={onPress}
      uppercase={false}
      loading={loading}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

export function PrimaryButton({
  label,
  onPress,
  loading,
  disabled,
}: {
  label: string;
  onPress: () => void;
  loading?: boolean;
  disabled?: boolean;
}) {
  return (
    <Button
      mode="contained"
      theme={{ colors: { primary: PRIMARY_LIGHT }, roundness: 26 / 4 }}
      labelStyle={{
        color: '#FFFFFF',
        fontSize: 18,
        paddingHorizontal: 18,
        paddingVertical: 4,
        includeFontPadding: false,
        textAlignVertical: 'center',
      }}
      onPress={onPress}
      uppercase={false}
      loading={loading}
      disabled={disabled}
    >
      {label}
    </Button>
  );
}

export function RoundButton({
  color,
  textColor,
  label,
  style,
  contentStyle,
  labelStyle,
  size = 74,
  onPress,
  ...props
}: {
  color: string;
  textColor: string;
  label: string;
  onPress: () => void;
  size?: number;
} & Omit<
  React.ComponentPropsWithoutRef<typeof Button>,
  'onPress' | 'color' | 'children'
>) {
  return (
    <Button
      mode="contained"
      color={color}
      onPress={onPress}
      style={[
        {
          width: size,
          height: size,
          borderRadius: size / 2,
        },
        style,
      ]}
      contentStyle={[
        {
          width: size,
          height: size,
          borderRadius: size / 2,
          justifyContent: 'center',
        },
        contentStyle,
      ]}
      labelStyle={[
        {
          color: textColor,
          fontSize: 18,
          includeFontPadding: false,
          textAlignVertical: 'center',
        },
        labelStyle,
      ]}
      uppercase={false}
      {...props}
    >
      {label}
    </Button>
  );
}
