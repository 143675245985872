import { Easing } from 'react-native-reanimated';
import { motion, AnimationProps } from 'framer-motion';

const presenceAnimation: Pick<
  AnimationProps,
  'initial' | 'exit' | 'animate' | 'transition'
> = {
  initial: { opacity: 0, translateY: 20 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0 },
  transition: {
    type: 'timing',
    duration: 250 / 1000,
    easing: Easing.bezier(0, 0, 0.2, 1),
  },
};

export function Presence({
  children,
  delay = 0,
}: {
  children: React.ReactNode;
  delay?: number;
}) {
  return (
    <motion.div
      {...presenceAnimation}
      transition={{ delay: delay / 1000, ...presenceAnimation['transition'] }}
    >
      {children}
    </motion.div>
  );
}
