import Color from 'color';
import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { TouchableRipple, useTheme } from 'react-native-paper';
import Animated, { EasingNode as Easing, eq } from 'react-native-reanimated';
import {
  interpolateColor,
  withTimingTransition,
} from 'react-native-redash/lib/module/v1/index';
import { i18n } from '../locale';
import { PRIMARY_DARK } from '../theming';

const HIGHLIGHT_ANIMATION_TIME = 220;
const AnimatedText = Animated.createAnimatedComponent(Text);

function Label({
  x,
  y,
  labelX,
  labelY,
  label,
  labelRotation,
  scale,
  scaleX,
  scaleY,
  value,
  active,
  tap,
  fixedTheme,
  transparentTheme,
}: {
  x: number;
  y: number;
  labelX: number;
  labelY: number;
  value: number;
  active: Animated.Node<number>;
  scale: number;
  scaleX: number;
  scaleY: number;
  label: string;
  labelRotation?: number;
  tap: (value: number) => void;
  fixedTheme?: boolean;
  transparentTheme?: boolean;
}) {
  const {
    dark,
    colors: { primary, surface, onSurface },
  } = useTheme();

  const primaryColorObj = useMemo(() => new Color(primary), [primary]);
  const backgroundColorObj = useMemo(() => new Color(surface), [surface]);
  const textColorObj = useMemo(() => new Color(onSurface), [onSurface]);

  const contrastColor = primaryColorObj.isDark() ? '#fff' : PRIMARY_DARK;
  const backgroundContrastColor = backgroundColorObj.isDark()
    ? '#fff'
    : PRIMARY_DARK;

  const borderColor = transparentTheme
    ? '#FFF'
    : backgroundColorObj.isDark()
    ? backgroundColorObj.lighten(0.2).alpha(0.37).toString()
    : backgroundColorObj.darken(0.2).alpha(0.12).toString();
  const numberColor = transparentTheme
    ? '#FFF'
    : textColorObj.desaturate(0.5).toString();
  const numberColorActive = contrastColor;

  const labelColor = numberColor;
  const labelColorActive = transparentTheme ? '#FFF' : backgroundContrastColor;
  const backgroundColor = transparentTheme
    ? 'transparent'
    : (backgroundColorObj.isDark()
        ? backgroundColorObj.lighten(0.2)
        : fixedTheme
        ? 'rgba(29, 15, 52, 0.1)'
        : backgroundColorObj.darken(0.2)
      ).toString();
  const backgroundColorActive = primaryColorObj.toString();
  const rippleColor = primaryColorObj.alpha(0.5).toString();

  const circleSize = 52 * scale;
  const fontSize = 30 * scale;
  const labelSize = 14 * scale;

  const animate = withTimingTransition(eq(active, value), {
    duration: HIGHLIGHT_ANIMATION_TIME,
    easing: Easing.inOut(Easing.cubic),
  });

  return (
    <Fragment>
      <View
        style={{
          top: labelY * scaleY,
          left: labelX * scaleX,
          width: 89 * scaleX,
          position: 'absolute',
          transform: labelRotation ? [{ rotateZ: `${labelRotation}deg` }] : [],
        }}
        pointerEvents="none"
      >
        <AnimatedText
          selectable={false}
          style={[
            {
              color: interpolateColor(animate, {
                inputRange: [0, 1],
                outputRange: [labelColor, labelColorActive],
              }) as any,
            },
            {
              fontSize: labelSize,
              textAlign: 'center',
              margin: 'auto',
              textAlignVertical: 'center',
              includeFontPadding: false,
            },
          ]}
        >
          {label}
        </AnimatedText>
      </View>

      <Animated.View
        style={[
          {
            backgroundColor: interpolateColor(animate, {
              inputRange: [0, 1],
              outputRange: [backgroundColor, backgroundColorActive],
            }),
          } as any,
          {
            width: circleSize,
            height: circleSize,
            borderRadius: circleSize / 2,
            borderWidth: StyleSheet.hairlineWidth,
            borderColor,
            left: x * scaleX,
            top: y * scaleY,
            position: 'absolute',
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.18,
            shadowRadius: 1.0,

            elevation: Platform.OS !== 'android' || !fixedTheme ? 1 : 0,
          },
        ]}
      >
        <TouchableRipple
          onPress={useCallback(() => tap(value), [tap, value])}
          borderless
          underlayColor={rippleColor}
          rippleColor={rippleColor}
          style={[
            StyleSheet.absoluteFill,
            {
              borderRadius: circleSize / 2,
              justifyContent: 'center',
              alignItems: 'center',
              ...(Platform.OS === 'web' ? { cursor: 'pointer' } : {}),
            },
          ]}
        >
          <AnimatedText
            selectable={false}
            style={[
              {
                color: interpolateColor(animate, {
                  inputRange: [0, 1],
                  outputRange: [numberColor, numberColorActive],
                }),
              } as any,
              {
                fontSize,
                textAlign: 'center',
                margin: 'auto',
                textAlignVertical: 'center',
                includeFontPadding: false,
              },
            ]}
          >
            {value}
          </AnimatedText>
        </TouchableRipple>
      </Animated.View>
    </Fragment>
  );
}

function Labels_({
  width,
  height,
  scale,
  scaleX,
  scaleY,
  active,
  tap,
  fixedTheme,
  transparentTheme,
}: {
  width: number;
  height: number;
  scale: number;
  scaleX: number;
  scaleY: number;
  active: Animated.Node<number>;
  tap: (value: number) => void;
  fixedTheme?: boolean;
  transparentTheme?: boolean;
}) {
  return (
    <Animated.View
      style={{ width, height, top: 0, left: 0, position: 'absolute' }}
    >
      <Label
        x={20}
        y={125}
        value={0}
        label={i18n.translate('app.swipe.labels.0')}
        labelX={10}
        labelY={90}
        scale={scale}
        scaleX={scaleX}
        scaleY={scaleY}
        active={active}
        tap={tap}
        fixedTheme={fixedTheme}
        transparentTheme={transparentTheme}
      />

      <Label
        x={131}
        y={186}
        value={1}
        label={i18n.translate('app.swipe.labels.1')}
        labelX={176}
        labelY={150}
        labelRotation={-41}
        scale={scale}
        scaleX={scaleX}
        scaleY={scaleY}
        active={active}
        tap={tap}
        fixedTheme={fixedTheme}
        transparentTheme={transparentTheme}
      />

      <Label
        x={202}
        y={287}
        value={2}
        label={i18n.translate('app.swipe.labels.2')}
        labelX={255}
        labelY={282}
        labelRotation={-17}
        scale={scale}
        scaleX={scaleX}
        scaleY={scaleY}
        active={active}
        tap={tap}
        fixedTheme={fixedTheme}
        transparentTheme={transparentTheme}
      />

      <Label
        x={202}
        y={412}
        value={3}
        label={i18n.translate('app.swipe.labels.3')}
        labelX={246}
        labelY={445}
        labelRotation={17}
        scale={scale}
        scaleX={scaleX}
        scaleY={scaleY}
        active={active}
        tap={tap}
        fixedTheme={fixedTheme}
        transparentTheme={transparentTheme}
      />

      <Label
        x={127}
        y={510}
        value={4}
        label={i18n.translate('app.swipe.labels.4')}
        labelX={159}
        labelY={571}
        labelRotation={47}
        scale={scale}
        scaleX={scaleX}
        scaleY={scaleY}
        active={active}
        tap={tap}
        fixedTheme={fixedTheme}
        transparentTheme={transparentTheme}
      />

      <Label
        x={26}
        y={552}
        value={5}
        label={i18n.translate('app.swipe.labels.5')}
        labelX={17}
        labelY={616}
        scale={scale}
        scaleX={scaleX}
        scaleY={scaleY}
        active={active}
        tap={tap}
        fixedTheme={fixedTheme}
        transparentTheme={transparentTheme}
      />
    </Animated.View>
  );
}

export const Labels = memo(Labels_);
