import {
  Poppins_100Thin,
  // Poppins_100Thin_Italic,
  // Poppins_200ExtraLight,
  // Poppins_200ExtraLight_Italic,
  Poppins_300Light,
  // Poppins_300Light_Italic,
  Poppins_400Regular,
  // Poppins_400Regular_Italic,
  Poppins_500Medium,
  // Poppins_500Medium_Italic,
  Poppins_600SemiBold,
  // Poppins_600SemiBold_Italic,
  Poppins_700Bold,
  // Poppins_700Bold_Italic,
  Poppins_800ExtraBold,
} from '@expo-google-fonts/poppins';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { FetchMediaError } from 'fetch-media';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { QUERY_CLIENT } from '../components/QueryCache';
import { API_ENDPOINT } from '../config';
import { fetchConfiguration } from './useConfiguration';
import { fetchEndpoint } from './useEndpoint';

export default function useCachedResources({
  onProgress,
}: {
  onProgress(progress: number): void;
}) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [failedToLoadConfiguration, setFailedToLoadConfiguration] = useState<
    FetchMediaError | false
  >(false);

  // Store onProgress so it doesn't need to start loading when this hook changes
  // whilst the useCachedResources hook is already mounted.
  const onProgressRef = useRef(onProgress);
  useLayoutEffect(() => {
    onProgressRef.current = onProgress;
  }, [onProgress]);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      let completed = 0;
      const total = 3;

      function increment() {
        completed += 1;
        return Math.round((completed / total) * 100) / 100;
      }

      try {
        // Load fonts
        const fonts = Font.loadAsync({
          ...MaterialCommunityIcons.font,
          Poppins_100Thin,
          Poppins_300Light,
          Poppins_400Regular,
          Poppins_500Medium,
          Poppins_600SemiBold,
          Poppins_700Bold,
          Poppins_800ExtraBold,
        });

        await Promise.all([
          fonts
            .catch(() => {})
            .then(() => onProgressRef.current(increment()))
            .then(() => console.debug('[cache] fonts done')),

          /*
          Asset.fromModule(require('../../assets/splash.png'))
            .downloadAsync()
            .catch(() => {})
            .then(() => onProgressRef.current(increment()))
            .then(() => console.debug('[cache] splash done')),
          */

          // Pre-fetch configuration on app start
          fetchEndpoint()
            .then((endpoint) => {
              onProgressRef.current(increment());
              console.debug('[cache] endpoint found');

              const final = endpoint || API_ENDPOINT!;

              return QUERY_CLIENT.fetchQuery(
                [final, 'configuration'],
                () =>
                  fetchConfiguration(final).then(() => {
                    onProgressRef.current(increment());
                    console.debug('[cache] configuration found');
                  }),
                {
                  staleTime: 60 * 1000,
                  retry: 1,
                }
              );
            })
            .catch((error) => setFailedToLoadConfiguration(error)),
        ]);
      } catch (e) {
        // We might want to provide this error information to an error reporting
        // service, but we also get a lot of "false positives" because errors
        // don't discriminate between user's internet is failing and server is
        // broken.
        //
        // For now, keep this as a warning locally only.
        console.warn(e);
      } finally {
        setLoadingComplete(true);

        onProgress(1);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return { ready: isLoadingComplete, error: failedToLoadConfiguration };
}
