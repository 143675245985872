import {
  makeRedirectUri,
  Prompt,
  useAuthRequest,
  useAutoDiscovery,
} from 'expo-auth-session';
import Constants from 'expo-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { Card, IconButton } from 'react-native-paper';
import Svg, { G, Path } from 'react-native-svg';
import { useIsMounted } from 'use-is-mounted';
import {
  CLIENT_ID_GOOGLE,
  CLIENT_ID_GOOGLE_PROXY,
  EXPERIENCE_SLUG,
  EXPO_IDENTIFIER,
} from '../../config';
import { AuthSuccess, ExternalAuthenticate } from '../useAuthentication';

const useProxy = Platform.select({
  web: false,
  native: Constants.appOwnership === 'expo',
  default: true,
});

const GOOGLE_GUID = (
  useProxy ? CLIENT_ID_GOOGLE_PROXY : CLIENT_ID_GOOGLE
)!.replace('.apps.googleusercontent.com', '');

export type GoogleProps = Pick<
  ExternalAuthenticate,
  'isLoading' | 'mutateAsync'
>;

function Google_(props: GoogleProps) {
  if (!CLIENT_ID_GOOGLE || !GOOGLE_GUID) {
    return null;
  }

  return <GoogleButton {...props} />;
}

function GoogleButton({ isLoading, mutateAsync: attempt }: GoogleProps) {
  const [isAuthenticating, setAuthenticating] = useState(false);
  const redirectUri = makeRedirectUri({
    // For usage in bare and standalone
    native: `${EXPO_IDENTIFIER}:` /*Platform.select({
      android: `${Constants.manifest.scheme}:`,
      ios: `com.googleusercontent.apps.${GOOGLE_GUID}:/redirect`,
    }),*/,
    useProxy,
    projectNameForProxy: EXPERIENCE_SLUG,
  });

  const discovery = useAutoDiscovery('https://accounts.google.com');
  const config = useMemo(
    () => ({
      clientId: CLIENT_ID_GOOGLE!,
      redirectUri,
      scopes: ['openid', 'profile', 'email'],

      // Optionally should the user be prompted to select or switch accounts
      prompt: Prompt.SelectAccount,

      // Optional
      extraParams: {
        /// Change language
        // hl: 'fr',
        /// Select the user
        // login_hint: 'user@gmail.com',
      },
    }),
    []
  );

  const [request, response, promptAsync] = useAuthRequest(config, discovery);

  const isMounted = useIsMounted();

  useEffect(() => {
    if (!response) {
      return;
    }

    isMounted.current && setAuthenticating(false);

    if (response?.type === 'success') {
      const { code, state } = response.params;

      const result: AuthSuccess<'google'> = {
        type: 'google',
        success: true,
        redirect_uri: config.redirectUri,
        code,
        state,

        code_verifier: request!.codeVerifier,
        platform: useProxy ? 'web' : Platform.OS,
      };
      isMounted.current && attempt(result).catch(() => {});
    }
  }, [response, request, attempt, setAuthenticating]);

  return (
    <Card
      style={{
        borderRadius: 30,
        marginHorizontal: 4,
        backgroundColor: '#FFF',
      }}
      elevation={2}
    >
      <IconButton
        icon={GoogleIcon}
        disabled={!request || isAuthenticating || isLoading}
        size={27.5}
        style={{
          backgroundColor: '#FFF',
          elevation: 0,
        }}
        onPress={() => {
          setAuthenticating(true);

          promptAsync({ useProxy });
        }}
      />
    </Card>
  );
}

type IconProps = {
  size: number;
  allowFontScaling?: boolean;
  color: string;
};

export function GoogleIcon(props: IconProps) {
  return (
    <Svg width={36} height={36} viewBox="0 0 27.5 27.5" pointerEvents="none">
      <G fill="none" fillRule="evenodd" x={-9} y={-9}>
        <Path
          d="M31.64 23.205c0-.639-.057-1.252-.164-1.841H23v3.481h4.844a4.14 4.14 0 01-1.796 2.716v2.259h2.908c1.702-1.567 2.684-3.875 2.684-6.615z"
          fill="#4285F4"
        />
        <Path
          d="M23 32c2.43 0 4.467-.806 5.956-2.18l-2.908-2.259c-.806.54-1.837.86-3.048.86-2.344 0-4.328-1.584-5.036-3.711h-3.007v2.332A8.997 8.997 0 0023 32z"
          fill="#34A853"
        />
        <Path
          d="M17.964 24.71a5.41 5.41 0 01-.282-1.71c0-.593.102-1.17.282-1.71v-2.332h-3.007A8.996 8.996 0 0014 23c0 1.452.348 2.827.957 4.042l3.007-2.332z"
          fill="#FBBC05"
        />
        <Path
          d="M23 17.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C27.463 14.891 25.426 14 23 14a8.997 8.997 0 00-8.043 4.958l3.007 2.332c.708-2.127 2.692-3.71 5.036-3.71z"
          fill="#EA4335"
        />
      </G>
    </Svg>
  );
}

export const Google = React.memo(Google_);
