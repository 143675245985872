import { AVPlaybackStatusSuccess } from 'expo-av';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';
import { Card, Surface } from 'react-native-paper';
import Svg, { G, Path } from 'react-native-svg';
import { MediaPlayer } from '../player/MediaPlayer';
import { CoverImage } from './CoverImage';

export function Disc({
  discSize,
  scale,
  coverImageHref,
  playerRef,
}: {
  discSize: number;
  scale: number;
  coverImageHref?: string;
  playerRef: RefObject<MediaPlayer | null>;
}) {
  const toggle = useCallback(() => {
    playerRef.current?.togglePlay();
  }, [playerRef]);

  const [isPlaying, setIsPlaying] = useState(
    () => playerRef.current?.state.isPlaying ?? false
  );

  const statusRef = useRef<AVPlaybackStatusSuccess | null>(null);

  useEffect(() => {
    playerRef.current?.addStatusListener((status) => {
      if (statusRef.current?.isPlaying !== status.isPlaying) {
        setIsPlaying(status.isPlaying);
      }

      statusRef.current = status;
    });
  }, [playerRef, statusRef]);

  return (
    <Card
      elevation={5}
      style={{
        backgroundColor: '#121212',
        width: discSize,
        height: discSize,
        borderRadius: discSize / 2,
        position: 'relative',
      }}
    >
      <CoverImage
        src={coverImageHref}
        isPlaying={isPlaying}
        containerSize={discSize}
      />

      <Pressable
        onPress={(event) => {
          toggle();
          event.preventDefault();
          event.stopPropagation();
        }}
        style={{
          width: discSize * 0.32,
          height: discSize * 0.32,
          borderRadius: (discSize / 2) * 0.32,
          position: 'absolute',
          top: (discSize * (1 - 0.32)) / 2,
          left: (discSize * (1 - 0.32)) / 2,
        }}
      >
        <Surface
          elevation={0}
          style={{
            backgroundColor: '#00000040',
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: '#ffffff',
            width: discSize * 0.32,
            height: discSize * 0.32,
            borderRadius: (discSize / 2) * 0.32,
            ...(Platform.OS === 'web' ? { cursor: 'pointer' } : {}),
          }}
        >
          {isPlaying ? (
            <Svg
              viewBox="0 0 9 9"
              width={20 * scale}
              height={20 * scale}
              style={{
                position: 'absolute',
                top: (discSize * 0.32 - 20 * scale) / 2,
                left: (discSize * 0.32 - 20 * scale) / 2,
              }}
            >
              <G
                fill="none"
                stroke="#ffffff"
                strokeLinecap="round"
                strokeWidth={3}
              >
                <Path d="M0 0L0 6" transform="translate(1.5 1.5)" />
                <Path d="M0 0L0 6" transform="translate(7.5 1.5)" />
              </G>
            </Svg>
          ) : (
            <Svg
              width={20 * scale}
              height={26 * scale}
              viewBox="0 0 20 23"
              style={{
                position: 'absolute',
                top: (discSize * 0.32 - 26 * scale) / 2,
                left: (discSize * 0.32 - 20 * scale) / 2 + 2,
              }}
            >
              <Path
                d="M9.766,3.015a2,2,0,0,1,3.468,0L21.277,17a2,2,0,0,1-1.734,3H3.457a2,2,0,0,1-1.734-3Z"
                transform="translate(20) rotate(90)"
                fill="#ffffff"
              />
            </Svg>
          )}
        </Surface>
      </Pressable>
    </Card>
  );
}
