import { useLayoutEffect } from 'react';
import {
  OnboardedFeature,
  RemoteAccountProfile,
  useProfile,
} from '../account/useUserProfile';
import { resetToPath } from '../navigation/utils';

function missingFeature(
  profile: RemoteAccountProfile,
  feature: OnboardedFeature
) {
  return !profile.onboarded_features.includes(feature);
}

const PREDICATES_TO_SECTION: (({
  profile,
}: {
  profile: RemoteAccountProfile;
}) => string | false)[] = [
  ({ profile }) =>
    missingFeature(profile, 'preferences.2020-07-20') && 'preferences',
];

/**
 ({ profile }) =>
    missingFeature(profile, 'how-to-swipe.2020-07-20') && 'tutorial',
  ({ profile }) => (profile.discovery_vote_profile ? false : 'samples'),
 */

export function useRedirectToOnboarding() {
  const { data: profile, isFetched } = useProfile();

  // This redirects if we hit home
  useLayoutEffect(() => {
    if (!profile) {
      return;
    }

    for (const predicate of PREDICATES_TO_SECTION) {
      const section = predicate({ profile });
      if (section) {
        resetToPath(`/getting-started/${section}`);
        return;
      }
    }
  }, [profile]);

  return isFetched;
}
