import { useEffect, useState } from 'react';
import { Dimensions, ScaledSize } from 'react-native';

export function useLerpWidth(from: [number, number], to: [number, number]) {
  const width = useWindowDimensions().width;

  // clamp from
  if (width < from[0]) {
    return from[1];
  }

  // clamp to
  if (width > to[0]) {
    return to[1];
  }

  // lerp
  const difference = to[0] - from[0];
  const offset = width - from[0];
  const progress = offset / difference;

  return from[1] + (to[1] - from[1]) * progress;
}

type EventValue = {
  window: ScaledSize;
  screen: ScaledSize;
};

export function useWindowDimensions() {
  const [dimensions, setDimensions] = useState(() => Dimensions.get('window'));

  // Start listening to changes
  useEffect(() => {
    let request: number | undefined = undefined;

    const onChange = (nextDimensions: EventValue) => {
      if (request) {
        cancelAnimationFrame(request);
      }

      request = requestAnimationFrame(() => {
        request = undefined;
        setDimensions(nextDimensions['window']);
      });
    };

    const subscription = Dimensions.addEventListener('change', onChange);

    return () => {
      if (subscription) {
        subscription.remove();
      } else {
        (Dimensions as any).removeEventListener('change', onChange);
      }

      request && cancelAnimationFrame(request);
    };
  }, [setDimensions]);

  return dimensions;
}
