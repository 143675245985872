import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { ReadonlyDeep } from 'type-fest';
import { useLocale } from '../hooks/useLocale';
import { usePrivateConfiguration } from '../hooks/usePrivateConfiguration';
import { useToken } from '../hooks/useToken';
import { authorization } from '../utils/authorization';
import { ApiEvent } from './useEvent';

export type ApiCompetition = ReadonlyDeep<{
  competition: {
    _links: {
      self: { href: string; title: string };
    };

    start_at: null | string;
    end_at: null | string;

    _embedded: readonly ApiEvent[];
  };
}>;

export type ApiCompetitions = ReadonlyDeep<{
  competitions: {
    _links: {
      self: {
        href: string;
        count: number;
        page: number;
        pages: number;
      };

      first?: {
        href: string;
        page: number;
      };

      prev?: {
        href: string;
        page: number;
      };

      next?: {
        href: string;
        page: number;
      };
      last?: {
        href: string;
        page: number;
      };
    };

    _embedded: readonly ApiCompetition[];
  };
}>;

export function useCompetitions({
  enabled = true,
  onError,
  ...options
}: UseQueryOptions<ApiCompetitions, FetchMediaError | Error> = {}) {
  const { data } = usePrivateConfiguration();
  const { ref, token, logout } = useToken();
  const url = data?.configuration._links.competitions.href;
  const locale = useLocale();

  return useQuery<ApiCompetitions, FetchMediaError | Error>(
    [locale, url, 'index'] as QueryKey,
    async ({ signal }) => {
      if (!ref.current) {
        throw new Error('Expected logged in, actual: no token');
      }

      const response = await fetchMedia(url!, {
        headers: {
          accept: [
            'application/vnd.soundersmusic.competition.v1.collection+json',
          ].join(', '),
          acceptLanguage: locale,
          authorization: authorization(ref.current)!,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
      });

      if (response && typeof response === 'object') {
        if ('competitions' in response) {
          return response as ApiCompetitions;
        }

        throw new FetchMediaError(
          'Expected competitions in response, actual: ' +
            Object.keys(response).join(', '),
          { status: 500, statusText: '' }
        );
      }

      throw new FetchMediaError(
        'Expected competitions response, actual:' + typeof response,
        { status: 500, statusText: '' }
      );
    },
    {
      enabled: Boolean(url && token && enabled),

      onError(error) {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            logout();
          }
        }

        if (onError) {
          onError(error);
        }
      },

      ...options,
    }
  );
}
