import { motion, MotionProps } from 'framer-motion';
import { useEffect, useReducer } from 'react';
import { ImageURISource } from 'react-native';

export function AnimatedImage({
  animateOpacity,
  animateY,
  accessibilityLabel,
  source,
  ...props
}: Omit<MotionProps, 'from' | 'animate'> & {
  animateOpacity?: boolean;
  animateY?: number;
  accessibilityLabel?: string;
  source: ImageURISource;
}) {
  const [loaded, setLoaded] = useReducer(() => true, false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setLoaded();
    };
    image.src = source.uri!;
  }, [source]);

  return (
    <motion.img
      {...props}
      alt={accessibilityLabel}
      initial={{
        ...(animateOpacity ? { opacity: 0 } : {}),
        ...(animateY ? { translateY: animateY } : {}),
      }}
      src={source['uri']}
      animate={loaded ? { opacity: 1, translateY: 0 } : {}}
      onLoad={setLoaded}
      onError={setLoaded}
    />
  );
}
