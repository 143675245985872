import React, { useRef } from 'react';
import { View } from 'react-native';
import { HelperText, Text, useTheme } from 'react-native-paper';
import { ScreenReaderHidden } from '../components/ScreenReaderHidden';
import { i18n } from '../locale';
import { useThemedColor } from '../utils/useThemedColor';

const MIN_DATE = new Date(
  new Date().getTime() - 1000 * 60 * 60 * 24 * 364 * 125
);
const MAX_DATE = new Date(
  new Date().getTime() - 1000 * 60 * 60 * 24 * 364 * 13
);

export function BornAtField({
  value,
  onChange,
}: {
  value: string | null;
  onChange(next: string): void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const color = useThemedColor('rgb(29, 15, 52)', '#fff');
  const borderColor = useThemedColor('rgb(29, 15, 52)', '#FFF');
  return (
    <View
      style={{
        position: 'relative',
        width: '100%',
        marginTop: 24,
        paddingVertical: 8,
      }}
    >
      <ScreenReaderHidden>
        <Text
          variant="labelSmall"
          nativeID="born-at-label"
          style={{ display: 'flex' }}
        >
          {i18n.translate('app.fields.born_at.label')}
        </Text>
      </ScreenReaderHidden>

      <input
        ref={inputRef}
        min={MIN_DATE.toISOString().split('T').shift()}
        max={MAX_DATE.toISOString().split('T').shift()}
        type="date"
        onChange={() => onChange(inputRef.current!.value)}
        value={value?.split('T').shift() || ''}
        aria-labelledby="born-at-label"
        aria-describedby="born-at-description"
        style={{
          padding: '24px 0px 4px',
          height: 56,
          fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
          letterSpacing: 0.15,
          fontSize: 16,
          color,
          verticalAlign: 'middle',
          textAlign: 'left',
          boxSizing: 'border-box',
          alignItems: 'stretch',
          border: 0,
          borderBottom: '1px solid ' + borderColor,
          display: 'flex',
          flexBasis: 'auto',
          flexDirection: 'row',
          flexShrink: 0,
          listStyle: 'none',
          margin: 0,
          minHeight: 0,
          minWidth: 0,
          position: 'relative',
          textDecoration: 'none',
          zIndex: 0,
          background: 'transparent',
          top: -20,
          marginBottom: -20,
        }}
      />
      <ScreenReaderHidden
        webStyle={{ marginTop: 8 }}
        nativeStyle={{ marginTop: 8 }}
      >
        <HelperText
          nativeID="born-at-description"
          type="info"
          style={{ paddingHorizontal: 0 }}
        >
          {i18n.translate('app.fields.born_at.helper_text')}
        </HelperText>
      </ScreenReaderHidden>
    </View>
  );
}
