import { CSSProperties } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

export function ScreenReaderHidden({
  children,
  nativeStyle,
  webStyle,
}: {
  children: React.ReactNode;
  nativeStyle?: StyleProp<ViewStyle>;
  webStyle?: CSSProperties;
}) {
  return (
    <div aria-hidden="true" style={webStyle}>
      {children}
    </div>
  );
}
