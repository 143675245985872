import { createNavigationContainerRef } from '@react-navigation/native';
import React, {
  createContext,
  createElement,
  useContext,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { useToken } from '../hooks/useToken';

export const navigationRef = createNavigationContainerRef();

const NavigationReadyContext = createContext([
  false as boolean,
  (next: boolean) => {},
] as const);

export function NavigationReadyProvider({
  children,
}: React.PropsWithChildren<object>) {
  const value = useState(Platform.OS === 'web');
  return createElement(NavigationReadyContext.Provider, { value }, children);
}
export function useNavigationReady() {
  const { hydrated } = useToken();
  const [ready, setReady] = useContext(NavigationReadyContext);
  return [ready && hydrated, setReady] as const;
}
