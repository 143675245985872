import { motion } from 'framer-motion';
import { StyleProp, View, ViewStyle } from 'react-native';

export function AnimatedBackgroundView({
  from,
  animate,
  children,
  style,
  duration = 1000,
}: {
  from: string;
  animate: string;
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  duration?: number;
}) {
  return (
    <View style={style}>
      <motion.div
        initial={{ backgroundColor: from }}
        animate={{ backgroundColor: animate }}
        transition={{ duration: duration / 1000 }}
        style={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </motion.div>
    </View>
  );
}
