import { StatusBar } from 'expo-status-bar';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useEffect, useState } from 'react';
import { View } from 'react-native';
import { ProgressBar, Text } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { i18n } from '../locale';
import { BACKGROUND_DARK, DARK_PURPLE, PASTEL_PINK } from '../theming';

export function SplashGuard({
  ready,
  progress,
  children,
}: {
  ready: boolean;
  progress: number;
  children: ReactNode;
}) {
  const [realReady, setRealReady] = useState<boolean | undefined>();

  useEffect(() => {
    const timeout = setTimeout(() => setRealReady(ready), 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [ready, setRealReady]);

  return (
    <AnimatePresence exitBeforeEnter>
      {realReady === true ? (
        <motion.div
          key="app"
          style={{
            flex: 1,
            flexDirection: 'column',
            width: '100%',
            display: 'flex',
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {children}
        </motion.div>
      ) : null}
      {realReady === false ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            flex: 1,
            flexDirection: 'column',
            width: '100%',
            display: 'flex',
          }}
        >
          <SafeAreaProvider>
            <StatusBar translucent style="dark" backgroundColor="#FFFFFF00" />
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                width: '100%',
                backgroundColor: DARK_PURPLE,
              }}
            >
              <Text
                variant="displayMedium"
                style={{
                  textAlign: 'center',
                  paddingHorizontal: 16,
                  marginHorizontal: 'auto',
                  color: '#FFFFFF',
                  display: 'flex',
                }}
              >
                {i18n.translate('app.loading.title')}
              </Text>
              <Text
                variant="bodyLarge"
                style={{
                  textAlign: 'center',
                  paddingHorizontal: 16,
                  marginHorizontal: 'auto',
                  color: '#FFFFFFAA',
                  marginTop: 12,
                  display: 'flex',
                }}
              >
                {i18n.translate('app.loading.body')}
              </Text>
              <View
                style={{
                  alignItems: 'center',
                  marginTop: 24,
                  minHeight: 200,
                }}
              >
                <ProgressBar
                  progress={progress}
                  color={PASTEL_PINK}
                  style={{
                    width: 250,
                    height: 2,
                    marginHorizontal: 'auto',
                    backgroundColor: BACKGROUND_DARK,
                  }}
                />
              </View>
            </View>
          </SafeAreaProvider>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
