import * as AppleAuthentication from 'expo-apple-authentication';
import {
  AuthRequestConfig,
  makeRedirectUri,
  useAuthRequest,
} from 'expo-auth-session';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { Card, IconButton } from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';
import { EXPERIENCE_SLUG, EXPO_IDENTIFIER } from '../../config';
import { AuthSuccess, ExternalAuthenticate } from '../useAuthentication';

export type AppleProps = Pick<
  ExternalAuthenticate,
  'isLoading' | 'mutateAsync'
>;

function Apple_({ isLoading, mutateAsync: attempt }: AppleProps) {
  if (Platform.OS !== 'ios' && Platform.OS !== 'macos') {
    return <FallbackApple isLoading={isLoading} mutateAsync={attempt} />;
  }

  return (
    <Fragment>
      <Card
        elevation={2}
        style={{
          backgroundColor: '#000',
          marginHorizontal: 4,
          borderRadius: 30,
        }}
      >
        <AppleAuthentication.AppleAuthenticationButton
          buttonType={
            AppleAuthentication.AppleAuthenticationButtonType.CONTINUE
          }
          buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
          cornerRadius={54 / 2}
          style={{ width: 54, height: 54, opacity: isLoading ? 0.8 : 1 }}
          onPress={async () => {
            if (isLoading) {
              return;
            }

            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
              ],
            });

            const result: AuthSuccess<'apple'> = {
              success: true,
              redirect_uri: '',
              type: 'apple',
              code: credential.authorizationCode!,
              state: credential.state!,

              token: credential.identityToken!,
              name: {
                first_name: credential.fullName?.givenName || undefined,
                last_name: credential.fullName?.familyName || undefined,
                display_name: credential.fullName?.nickname || undefined,
              },
              email: credential.email || undefined,
            };

            attempt(result);
            // signed in
          }}
        />
      </Card>
    </Fragment>
  );
}

const discovery = {
  authorizationEndpoint: 'https://appleid.apple.com/auth/authorize',
  tokenEndpoint: 'https://appleid.apple.com/auth/token',
};

const DISABLED = true;

function FallbackApple({ isLoading, mutateAsync: attempt }: AppleProps) {
  const [isAuthenticating, setAuthenticating] = useState(false);
  const redirectUri = makeRedirectUri({
    useProxy: true,
    projectNameForProxy: EXPERIENCE_SLUG,
    native: `${EXPO_IDENTIFIER}:`,
  });

  const config = useMemo(
    () =>
      ({
        clientId: EXPO_IDENTIFIER,
        scopes: ['name', 'email'],
        // For usage in managed apps using the proxy
        redirectUri,
        extraParams: { response_mode: 'form_post' },
      } satisfies AuthRequestConfig),
    []
  );

  const [request, response, promptAsync] = useAuthRequest(config, discovery);

  const isMounted = useIsMounted();

  useEffect(() => {
    if (!response) {
      return;
    }

    isMounted.current && setAuthenticating(false);

    if (response?.type === 'success') {
      const { code, state } = response.params;

      const result: AuthSuccess<'spotify'> = {
        type: 'spotify',
        success: true,
        redirect_uri: config.redirectUri,
        code,
        state,
      };

      isMounted.current && attempt(result).catch(() => {});
    }
  }, [response, attempt, setAuthenticating]);

  if (DISABLED) {
    return null;
  }

  return (
    <Card
      elevation={2}
      style={{
        backgroundColor: '#000',
        marginHorizontal: 4,
        borderRadius: 30,
      }}
    >
      <IconButton
        disabled={!request || isAuthenticating || isLoading}
        size={27.5}
        iconColor="#FFFFFF"
        icon="apple"
        style={{ backgroundColor: '#000', opacity: isLoading ? 0.8 : 1 }}
        onPress={() => {
          setAuthenticating(true);
          promptAsync({
            windowFeatures: { width: 700, height: 600 },
          });
        }}
      />
    </Card>
  );
}

export const Apple = React.memo(Apple_);
