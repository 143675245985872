import {
  getStateFromPath,
  useIsFocused,
  useNavigationState,
} from '@react-navigation/core';
import { useEffect } from 'react';
import { View } from 'react-native';
import { ActivityIndicator, Caption } from 'react-native-paper';
import { LINKING_CONFIG } from '../navigation/LinkingConfiguration';
import {
  navigationRef,
  useNavigationReady,
} from '../navigation/NavigationReadyContext';
import { resetToPath } from '../navigation/utils';
import { useRedirectBack } from './useRedirectBack';

export function RedirectBack() {
  const { path, hydrated, redirected } = useRedirectBack();

  const focused = useIsFocused();
  const from = path?.includes('/welcome') ? null : path;
  const screens = from ? getStateFromPath(from, LINKING_CONFIG) : null;

  const isFromContext = path === from;

  const [ready] = useNavigationReady();
  const isReady = focused && hydrated && ready && navigationRef.isReady();

  useEffect(() => {
    if (!isReady) {
      console.debug('[redirect] back: not ready', {
        focused,
        hydrated,
        ready,
        isReady,
      });
      return;
    }

    redirected().then(() => {
      if (!navigationRef) {
        console.debug('[redirect] back: no navigation');
        return;
      }

      if (!from) {
        console.debug('[redirect] back: redirect to root');
        resetToPath('/discover');
        return;
      }

      console.debug('[redirect] back: redirect to path', from);
      resetToPath(from);
    });
  }, [isReady, isFromContext, from]);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ActivityIndicator />
      <Caption>From: {from}</Caption>
      <Caption>Screens: {!!screens}</Caption>
    </View>
  );
}
