import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import merge from 'lodash.merge';

const SUPPORTED = ['en', 'nl'];

export const i18n = new I18n({});

i18n.defaultLocale = 'en';
i18n.enableFallback = true;

// Naive lookup. TODO: think about less specific look ups etc.
i18n.locale =
  Localization.getLocales().find(
    (locale) =>
      SUPPORTED.includes(locale.languageCode) ||
      SUPPORTED.includes(locale.languageTag)
  )?.languageCode ?? i18n.defaultLocale;

export function defineTranslations(
  translations: Record<string, Record<string, unknown>>
) {
  Object.keys(translations).forEach((language) => {
    // Ensure language exists
    const current = i18n.translations[language] ?? {};

    // Deep merge
    const next = merge(current, translations[language]);
    i18n.store({ [language]: next });
  });
}
