import React, {
  createContext,
  createElement,
  useContext,
  useMemo,
} from 'react';

const MenuActionContext = createContext({ open() {}, close() {} });

export function ProvideMenuActions({
  open,
  close,
  children,
}: React.PropsWithChildren<{ open(): void; close(): void }>) {
  const value = useMemo(() => ({ open, close }), [open, close]);
  return createElement(MenuActionContext.Provider, { value }, children);
}

export function useOpenMenu() {
  return useContext(MenuActionContext).open;
}

export function useCloseMenu() {
  return useContext(MenuActionContext).close;
}
