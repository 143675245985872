import { MaterialBottomTabNavigationOptions } from '@react-navigation/material-bottom-tabs';
import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  ParamListBase,
  StackRouter,
  TabActionHelpers,
  TabNavigationState,
  TabRouter,
  TabRouterOptions,
  useNavigationBuilder,
} from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Image, MotiView } from 'moti';
import React, { useCallback, useEffect, useState } from 'react';
import { BackHandler, Platform, StyleSheet, Text, View } from 'react-native';
import {
  Portal,
  Provider,
  TouchableRipple,
  useTheme,
} from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useToken } from '../hooks/useToken';
import { BACKGROUND_DARK, PRIMARY_DARK, PRIMARY_LIGHT } from '../theming';
import { useWindowDimensions } from '../utils/useLerpWidth';
import { AnimatedBackground } from './AnimatedBackground';
import { AnimatedRightSide } from './AnimatedRightSide';
import { ProvideMenuActions } from './MenuActionContext';
import { SideMenu } from './SideMenu';

// Props accepted by the view
type MenuNavigationConfig = {
  /* */
};

// Supported screen options
type MenuNavigationOptions = {
  title?: string;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type MenuNavigationEventMap = {
  open: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};

export type MenuNavigatorProps<ParamList extends ParamListBase> =
  DefaultNavigatorOptions<
    ParamList,
    TabNavigationState<ParamList>,
    MenuNavigationOptions,
    MenuNavigationEventMap
  > &
    TabRouterOptions &
    MenuNavigationConfig;

function MenuNavigator<ParamList extends ParamListBase>({
  initialRouteName,
  children,
  screenListeners,
  screenOptions,
}: MenuNavigatorProps<ParamList>) {
  const { state, navigation, descriptors, NavigationContent } =
    useNavigationBuilder<
      TabNavigationState<ParamList>,
      TabRouterOptions,
      TabActionHelpers<ParamList>,
      MenuNavigationOptions,
      MenuNavigationEventMap
    >(TabRouter as any, {
      children,
      initialRouteName,
      screenListeners: screenListeners as any,
      screenOptions: screenOptions as any,
    });

  const { token, refreshing } = useToken();
  const defaultOpen = false;

  const { dark } = useTheme();

  const width = useWindowDimensions().width;
  const space = Math.max(250, ((width / 2) * 550) / 812);
  const edgeOffset = space === 250 ? width / 2 - (250 - width / 3) : width / 2;

  const [active, setActive] = useState(defaultOpen);

  //navigation.closeTab.bind(navigation);
  const close = useCallback(() => setActive(false), [setActive]);
  const open = useCallback(() => setActive(true), [setActive]);

  useEffect(() => {
    if (active === defaultOpen) {
      return;
    }

    const handleHardwareBack = () => {
      // We shouldn't handle the back button if the parent screen isn't focused
      // This will avoid the tab overriding event listeners from a focused screen
      if (!navigation.isFocused()) {
        return false;
      }

      if (defaultOpen) {
        open();
      } else {
        close();
      }

      return true;
    };

    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleHardwareBack();
      }
    };

    // We only add the listeners when tab opens
    // This way we can make sure that the listener is added as late as possible
    // This will make sure that our handler will run first when back button is pressed
    const subscription = BackHandler.addEventListener(
      'hardwareBackPress',
      handleHardwareBack
    );

    if (Platform.OS === 'web') {
      document?.body?.addEventListener?.('keyup', handleEscape);
    }

    return () => {
      subscription.remove();

      if (Platform.OS === 'web') {
        document?.body?.removeEventListener?.('keyup', handleEscape);
      }
    };
  }, [defaultOpen, active, open, close, navigation]);

  const mustClose = !token && !refreshing && active;
  useEffect(() => {
    if (!mustClose) {
      return;
    }

    setActive(false);
  }, [mustClose, setActive]);

  // console.log(state.key);

  return (
    <Portal.Host>
      <ProvideMenuActions close={close} open={open}>
        <NavigationContent>
          <View
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              backgroundColor: PRIMARY_LIGHT,
              alignItems: 'center',
            }}
          >
            <Image
              source={/*imageSource*/ {}}
              style={{ width: '100%', height: '100%' }}
              resizeMode="cover"
            />
            <View
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                paddingBottom: 164,
                top: 0,
                left: 0,
              }}
            >
              <View style={{ flex: 1 }} />
              <LinearGradient
                colors={[`${PRIMARY_DARK}00`, PRIMARY_DARK]}
                style={{ flex: 5, width: '100%' }}
              />
              <View
                style={{
                  height: 164,
                  backgroundColor: PRIMARY_DARK,
                  position: 'absolute',
                  bottom: 0,
                  width: '100%',
                }}
              />
            </View>
            <View
              style={{
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                right: edgeOffset,
              }}
            >
              <SafeAreaView style={{ flex: 1 }}>
                <View
                  style={{
                    width: '100%',
                    alignSelf: 'flex-end',
                    height: '100%',
                    maxWidth: 400,
                  }}
                >
                  <SideMenu />
                </View>
              </SafeAreaView>
            </View>
          </View>

          <AnimatedBackground
            active={active}
            space={space}
            backgroundColor={dark ? BACKGROUND_DARK : 'white'}
          >
            <View
              style={{
                width: '100%',
                height: '100%',
                flex: 1,
              }}
              pointerEvents={active ? 'none' : 'auto'}
            >
              <View
                key={state.routes[state.index].key}
                style={[StyleSheet.absoluteFill]}
              >
                {descriptors[state.routes[state.index].key].render()}
                {/*<Text>{state.routes[state.index].name}</Text>*/}
              </View>
            </View>

            <AnimatedRightSide
              active={active}
              backgroundColor={dark ? BACKGROUND_DARK : 'white'}
            >
              <TouchableRipple
                style={StyleSheet.absoluteFill}
                disabled={!active}
                onPress={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  // Delay hiding so that touch is consumed, and pointer events don't
                  // propagate!
                  setTimeout(() => close(), 0);
                }}
              >
                <View style={StyleSheet.absoluteFill} />
              </TouchableRipple>
            </AnimatedRightSide>
          </AnimatedBackground>
        </NavigationContent>
      </ProvideMenuActions>
    </Portal.Host>
  );
}

export const createMenuNavigator = createNavigatorFactory<
  TabNavigationState<ParamListBase>,
  MenuNavigationOptions & MaterialBottomTabNavigationOptions,
  MenuNavigationEventMap,
  typeof MenuNavigator
>(MenuNavigator);
