import { useEffect, useState } from 'react';

export function useIsConnected() {
  const [connected, setConnected] = useState(navigator.onLine);

  useEffect(() => {
    const onOffline = () => setConnected(false);
    const onOnline = () => setConnected(true);

    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  }, []);

  return connected;
}
