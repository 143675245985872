import { applicationId } from 'expo-application';
import { StoredMemoryValue } from 'expo-use-memory-value';
import { RefObject, useEffect, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { API_ENDPOINT } from '../config';

const ENDPOINT = new StoredMemoryValue<string>(
  `${applicationId}.endpoint.v2`,
  true,
  API_ENDPOINT
);

export function useEndpoint() {
  const queryClient = useQueryClient();

  const { data } = useQuery<string | null, never>(['endpoint'], fetchEndpoint, {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    placeholderData: ENDPOINT.current,

    notifyOnChangeProps: ['data'],
  });

  useEffect(() => {
    if (data && typeof data !== 'string') {
      console.warn(
        `Expected endpoint to be a string, actual: ${typeof data}. Falling back to BASE_ENDPOINT (${API_ENDPOINT})`
      );
      queryClient.setQueryData(['endpoint'], API_ENDPOINT);
      ENDPOINT.emit(API_ENDPOINT);
    }
  }, [data, queryClient]);

  if (typeof data !== 'string') {
    return API_ENDPOINT;
  }

  return data;
}

export function useEndpointRef(): RefObject<string | null | undefined> {
  const endpoint = useEndpoint();
  const ref = useRef(endpoint);

  useEffect(() => {
    ref.current = endpoint;
  }, [endpoint, ref]);

  return ref;
}

export async function fetchEndpoint() {
  await ENDPOINT.hydrate();
  return ENDPOINT.current ?? null;
}

export function useSetEndpoint() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(
    ['endpoint'],
    async (next: string) => {
      await ENDPOINT.emit(next);
      return next;
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries(['endpoint']);
      },

      onSettled: async () => {
        await queryClient.invalidateQueries(['endpoint']);
      },

      onSuccess: (next: string) => {
        queryClient.setQueryData(['endpoint'], next);
      },
    }
  );

  return mutateAsync;
}
