export function sanitizeColor(color: string, fallback: string) {
  if (
    !color ||
    typeof color !== 'string' ||
    color[0] !== '#' ||
    ![4, 7, 9].includes(color.length)
  ) {
    return fallback;
  }

  return color;
}
