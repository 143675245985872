import { AuthError } from 'expo-auth-session';
import { dismissAuthSession, maybeCompleteAuthSession } from 'expo-web-browser';
import {
  fetchMedia,
  FetchMediaError,
  fetchMediaWrapped,
  StructuredErrors,
} from 'fetch-media';
import { Platform } from 'react-native';
import { useMutation, UseMutationOptions } from 'react-query';
import { useConfiguration } from '../hooks/useConfiguration';
import { useToken } from '../hooks/useToken';
import { i18n } from '../locale';

export type AuthSuccess<T extends string> = {
  type: T;
  success: true;
  redirect_uri: string;
  code: string;
  state: string;

  name?: {
    first_name?: string;
    last_name?: string;
    display_name?: string;
  };
  token?: string;
  email?: string;
  code_verifier?: string;
  platform?: string;
};

export type AuthFailure<T extends string> = {
  type: T;
  success: false;
  error: AuthError | Error | null | undefined;
};

export type Permit = {
  stamp: string;
  token: string;
  expires_at: string;

  _links: {
    configuration: { href: string };
    my_permit: { href: string };
  };
};

interface PermitRequest {
  permit: {
    email: string;
    password: string;
  };
}

interface RegisterRequest {
  user: {
    email: string;
    password: string;
    password_confirmation: string;
  };
}

interface ExternalAuthRequest {
  auth: {
    source: string;
    code: string;
    state?: string | null;
    redirect_uri?: string;
    token?: string;
    code_verifier?: string;
    name?: {
      first_name?: string | null;
      last_name?: string | null;
      display_name?: string | null;
    };
    email?: string | null;
    platform?: string | null;
  };
}

export class UsernameError extends Error {
  constructor(message: string) {
    super(message.replace('Validation failed: ', ''));

    Object.setPrototypeOf(this, UsernameError.prototype);
  }
}

export class PasswordError extends Error {
  constructor(message: string) {
    super(message.replace('Validation failed: ', ''));

    Object.setPrototypeOf(this, PasswordError.prototype);
  }
}

export class ConflictError extends Error {
  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, ConflictError.prototype);
  }
}

type AuthenticateParams = {
  username: string;
  password: string;
};

type AuthenticateError =
  | FetchMediaError
  | UsernameError
  | PasswordError
  | Error;

export function useAuthenticate() {
  const { authenticated } = useToken();
  const { data: configuration } = useConfiguration();
  const endpoint = configuration?._links.login.href;

  return useMutation<Permit, AuthenticateError, AuthenticateParams>(
    async ({ username, password }) => {
      if (!username) {
        throw new UsernameError('Enter a username');
      }

      if (!password) {
        throw new PasswordError('Enter a password');
      }

      if (!endpoint) {
        throw new Error('Not ready (no endpoint)');
      }

      const body: PermitRequest = {
        permit: {
          email: username,
          password,
        },
      };

      const response = await fetchMediaWrapped(endpoint!, {
        headers: {
          accept: 'application/vnd.soundersmusic.permit.v1.stamped+json',
          contentType: 'application/vnd.soundersmusic.permit.v1+json',
        },
        method: 'POST',
        body,
        disableFormData: true,
        disableFormUrlEncoded: true,
      });

      if (!response.ok()) {
        const error = response.result as RegisterError;

        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            throw new PasswordError('E-mail or password incorrect');
          }
        }

        throw error;
      }

      const result = response.unwrap();

      if (
        typeof result === 'string' ||
        !result ||
        !Object.prototype.hasOwnProperty.call(result, 'permit')
      ) {
        throw new Error('Expected permit, but did not receive it');
      }

      const { permit } = result as { permit: Permit };
      authenticated(permit);

      return permit;
    }
  );
}

type RegisterParams = {
  username: string;
  password: string;
  passwordConfirmation: string;
};

type RegisterError =
  | FetchMediaError
  | UsernameError
  | PasswordError
  | ConflictError
  | Error;

export function useRegister(
  options: UseMutationOptions<Permit, RegisterError, RegisterParams> = {}
) {
  const { authenticated } = useToken();
  const { data: configuration } = useConfiguration();
  const endpoint = configuration?._links.register.href;

  return useMutation<Permit, RegisterError, RegisterParams>(
    async ({ username, password, passwordConfirmation }) => {
      if (!username) {
        throw new UsernameError('Enter a username');
      }

      if (!password) {
        throw new PasswordError('Enter a password');
      }

      if (!passwordConfirmation || password !== passwordConfirmation) {
        throw new PasswordError('Password does not match confirmation');
      }

      if (!endpoint) {
        throw new Error('Not ready (no endpoint)');
      }

      const body: RegisterRequest = {
        user: {
          email: username,
          password,
          password_confirmation: passwordConfirmation,
        },
      };

      const response = await fetchMediaWrapped(endpoint!, {
        headers: {
          accept: 'application/vnd.soundersmusic.permit.v1.stamped+json',
          contentType:
            'application/vnd.soundersmusic.user.v1.registration+json',
        },
        method: 'POST',
        body,
        disableFormData: true,
        disableFormUrlEncoded: true,
      });

      if (!response.ok()) {
        const error = response.result as RegisterError;

        if (error instanceof FetchMediaError) {
          if (error.response.status === 409) {
            throw new ConflictError(
              'This e-mail address belongs to a registered account. Try logging in instead.'
            );
          }
        }

        if (error instanceof StructuredErrors) {
          const downcased = error.message.toLocaleLowerCase();

          if (downcased.includes('password')) {
            throw new PasswordError(error.message);
          } else if (
            ['username', 'e-mail', 'email'].some((trigger) =>
              downcased.includes(trigger)
            )
          ) {
            throw new UsernameError(error.message);
          }
        }

        throw error;
      }

      const result = response.unwrap();

      if (
        typeof result === 'string' ||
        !result ||
        !Object.prototype.hasOwnProperty.call(result, 'permit')
      ) {
        throw new Error('Expected permit, but did not receive it');
      }

      const { permit } = result as { permit: Permit };
      authenticated(permit);

      return permit;
    },
    options
  );
}

type ExternalAuthenticateParams = AuthSuccess<string>;
type ExternalAuthenticateError = FetchMediaError | Error;

export function useExternalAuthenticate() {
  const { authenticated } = useToken();
  const { data: configuration } = useConfiguration();
  const endpoint = configuration?._links.external_auth.href;

  return useMutation<
    Permit,
    ExternalAuthenticateError,
    ExternalAuthenticateParams
  >(
    async (auth) => {
      if (!endpoint) {
        throw new Error('Not ready (no endpoint)');
      }

      const { type: source, success, ...props } = auth;

      const body: ExternalAuthRequest = {
        auth: {
          source,
          ...props,
        },
      };

      const response = await fetchMedia(endpoint!, {
        headers: {
          accept: 'application/vnd.soundersmusic.permit.v1.stamped+json',
          acceptLanguage: i18n.locale,
          contentType:
            'application/vnd.soundersmusic.external-auth.v1.request+json',
        },
        method: 'POST',
        body,
        disableFormData: true,
        disableFormUrlEncoded: true,
      });

      if (
        typeof response === 'string' ||
        !response ||
        !Object.prototype.hasOwnProperty.call(response, 'permit')
      ) {
        throw new Error('Expected permit, but did not receive it');
      }

      const { permit } = response as { permit: Permit };
      authenticated(permit);

      return permit;
    },
    {
      onError: () => {
        dismissAuthSession();
      },
    }
  );
}

export type ExternalAuthenticate = ReturnType<typeof useExternalAuthenticate>;

export function useForgotPassword(
  options: UseMutationOptions<
    boolean,
    FetchMediaError | Error,
    { email: string }
  > = {}
) {
  const { data: configuration } = useConfiguration();
  const endpoint = configuration?._links.forgot.href;

  return useMutation(async ({ email }: { email: string }) => {
    if (!endpoint) {
      throw new Error('Not ready (no endpoint)');
    }

    if (!email) {
      return false;
    }

    const body = {
      user: {
        email,
      },
    };

    const response = await fetchMediaWrapped(endpoint!, {
      headers: {
        accept: 'application/vnd.soundersmusic.empty',
        contentType: 'application/vnd.soundersmusic.user.v1.forgotten+json',
      },
      method: 'POST',
      body,
      disableFormData: true,
      disableFormUrlEncoded: true,
    });

    return response.ok();
  }, options);
}

type ResetParams = {
  email: string;
  password: string;
  passwordConfirmation: string;
  resetToken: string;
};

export function useResetPassword(
  options: UseMutationOptions<
    boolean,
    FetchMediaError | Error,
    ResetParams
  > = {}
) {
  const { data: configuration } = useConfiguration();
  const endpoint = configuration?._links.reset.href;

  return useMutation(
    async ({
      email,
      password,
      passwordConfirmation,
      resetToken,
    }: ResetParams) => {
      if (!endpoint) {
        throw new Error('Not ready (no endpoint)');
      }

      if (!email) {
        return false;
      }

      const body = {
        user: {
          email,
          password,
          password_confirmation: passwordConfirmation,
        },
      };

      const response = await fetchMediaWrapped(
        endpoint
          .replace(/\{token\}/, resetToken)
          .replace(/%7Btoken%7D/, resetToken),
        {
          headers: {
            accept: 'application/vnd.soundersmusic.permit.v1.stamped+json',
            acceptLanguage: i18n.locale,
            contentType: 'application/vnd.soundersmusic.user.v1.reset+json',
          },
          method: 'POST',
          body,
          disableFormData: true,
          disableFormUrlEncoded: true,
        }
      );

      return response.ok();
    },
    options
  );
}
