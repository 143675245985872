import { MaterialCommunityIcons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useTheme } from 'react-native-paper';
import { DiscoveryTab } from '../discover/DiscoveryTab';
import { FavoritesScreen } from '../favorites/FavoritesScreen';
import { useLocale } from '../hooks/useLocale';
import { defineTranslations, i18n } from '../locale';
import { PRIMARY_DARK, PRIMARY_LIGHT } from '../theming';
import { useRedirectToOnboarding } from './useRedirectToOnboarding';

const Tabs = createBottomTabNavigator();

export function HomeScreen() {
  return <BottomTabNavigator />;
}

defineTranslations({
  en: {
    app: {
      tabs: {
        discover: 'Discover',
        favorites: 'Favorites',
      },
    },
  },
  nl: {
    app: {
      tabs: {
        discover: 'Ontdek',
        favorites: 'Favorieten',
      },
    },
  },
});

function BottomTabNavigator() {
  const {
    dark,
    colors: { surface },
  } = useTheme();

  useRedirectToOnboarding();
  useLocale();

  return (
    <Tabs.Navigator
      screenOptions={{
        headerShown: false,
        tabBarLabelPosition: 'below-icon',
        tabBarLabelStyle: { position: 'relative', bottom: 8 },
        tabBarStyle: {
          backgroundColor: surface,
          borderTopWidth: 0,
        },

        tabBarActiveTintColor: dark ? '#FFF' : PRIMARY_LIGHT,
        tabBarInactiveTintColor: dark ? PRIMARY_LIGHT : `${PRIMARY_DARK}40`,
      }}
    >
      <Tabs.Screen
        name="Discover"
        component={DiscoveryTab}
        options={{
          // tabBarIcon: 'disc',
          title:
            i18n.translate('app.tabs.discover') + ' · Sounders Music Discovery',
          tabBarLabel: i18n.translate('app.tabs.discover'),
          tabBarIcon: ({ focused, color, size }) => (
            <MaterialCommunityIcons name="disc" {...{ color, size }} />
          ),
        }}
      />
      <Tabs.Screen
        name="Favorites"
        component={FavoritesScreen}
        options={{
          // tabBarIcon: 'heart',
          title:
            i18n.translate('app.tabs.favorites') +
            ' · Sounders Music Discovery',
          tabBarLabel: i18n.translate('app.tabs.favorites'),
          tabBarIcon: ({ focused, color, size }) => (
            <MaterialCommunityIcons name="heart" {...{ color, size }} />
          ),
        }}
      />
    </Tabs.Navigator>
  );
}
