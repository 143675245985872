import { SoundersToken } from '../hooks/useToken';

export function authorization(token: SoundersToken): string;
export function authorization(token: null | undefined): null;
export function authorization(
  token: SoundersToken | null | undefined
): string | null;
export function authorization(token: SoundersToken | null | undefined) {
  if (!token) {
    return null;
  }

  return `Token token=${token.token}, stamp=${token.stamp}`;
}
