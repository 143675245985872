import { makeRedirectUri, useAuthRequest } from 'expo-auth-session';
import Constants from 'expo-constants';
import React, { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import { Card, IconButton } from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';
import {
  CLIENT_ID_SPOTIFY,
  EXPERIENCE_SLUG,
  EXPO_IDENTIFIER,
} from '../../config';
import { AuthSuccess, ExternalAuthenticate } from '../useAuthentication';

const discovery = {
  authorizationEndpoint: 'https://accounts.spotify.com/authorize',
  tokenEndpoint: 'https://accounts.spotify.com/api/token',
};

// TODO: maybe this should go
const useProxy = Constants.appOwnership === 'expo' && Platform.OS !== 'web';

export type SpotifyProps = Pick<
  ExternalAuthenticate,
  'isLoading' | 'mutateAsync'
>;

function Spotify_(props: SpotifyProps) {
  if (!CLIENT_ID_SPOTIFY) {
    return null;
  }

  return <SpotifyButton {...props} />;
}

function SpotifyButton({ isLoading, mutateAsync: attempt }: SpotifyProps) {
  const [isAuthenticating, setAuthenticating] = useState(false);
  const redirectUri = makeRedirectUri({
    // For usage in bare and standalone
    native: `${EXPO_IDENTIFIER}://redirect`,
    useProxy,
    projectNameForProxy: EXPERIENCE_SLUG,
  });

  const config = useMemo(
    () => ({
      clientId: CLIENT_ID_SPOTIFY!,
      scopes: ['user-read-email', 'user-read-private'],
      // In order to follow the "Authorization Code Flow" to fetch token after authorizationEndpoint
      // this must be set to false
      usePKCE: false,
      // For usage in managed apps using the proxy
      redirectUri,
    }),
    []
  );

  const [request, response, promptAsync] = useAuthRequest(config, discovery);

  const isMounted = useIsMounted();

  useEffect(() => {
    if (!response) {
      return;
    }

    isMounted.current && setAuthenticating(false);

    if (response?.type === 'success') {
      const { code, state } = response.params;

      const result: AuthSuccess<'spotify'> = {
        type: 'spotify',
        success: true,
        redirect_uri: config.redirectUri,
        code,
        state,
      };

      isMounted.current && attempt(result).catch(() => {});
    }
  }, [response, attempt, setAuthenticating]);

  return (
    <Card
      style={{
        borderRadius: 30,
        backgroundColor: '#1FD760',
        marginHorizontal: 4,
      }}
      elevation={2}
    >
      <IconButton
        disabled={!request || isAuthenticating || isLoading}
        size={27.5}
        iconColor="#FFFFFF"
        icon="spotify"
        style={{ backgroundColor: '#1FD760' }}
        onPress={() => {
          setAuthenticating(true);
          promptAsync({ useProxy });
        }}
      />
    </Card>
  );
}

export const Spotify = React.memo(Spotify_);
