import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { ReadonlyDeep } from 'type-fest';
import { useLocale } from '../hooks/useLocale';
import { useToken } from '../hooks/useToken';
import { authorization } from '../utils/authorization';

export type ApiEvent = ReadonlyDeep<{
  event: {
    _links: {
      self: {
        href: string;
        title: string;
      };
      tracks: {
        href: string;
        count: number;
      };
      background_image?: {
        href: string;
        templated: true;
      };
      foreground_image?: {
        href: string;
        templated: true;
      };
      icon?: {
        href: string;
        templated: true;
      };
    };

    start_at: string | null;
    end_at: null | null;
    primary: string;
    background_color: string;
    description: string;

    subtitle?: string | null;
    email_verification_required: boolean;
    phone_verification_required: boolean;

    options: object;
  };
}>;

export type RemoteEventImageVariants =
  | 'size_1x'
  | 'size_2x'
  | 'size_3x'
  | 'size_4x';

export function useEvent(
  url: string | undefined | null,
  {
    enabled = true,
    onError,
    ...options
  }: UseQueryOptions<ApiEvent, FetchMediaError | Error> = {}
) {
  const { ref, token, logout } = useToken();
  const locale = useLocale();

  return useQuery<ApiEvent, FetchMediaError | Error>(
    [locale, url, 'event', 'v3'] as QueryKey,
    async ({ signal }) => {
      if (!ref.current) {
        throw new Error('Expected logged in, actual: no token');
      }

      const response = await fetchMedia(url!, {
        headers: {
          accept: ['application/vnd.soundersmusic.event.v3+json'].join(', '),
          acceptLanguage: locale,
          authorization: authorization(ref.current)!,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
        debug: __DEV__ && false,
      });

      if (response && typeof response === 'object') {
        if ('event' in response) {
          return response as ApiEvent;
        }

        throw new FetchMediaError(
          'Expected event in response, actual: ' +
            Object.keys(response).join(', '),
          { status: 500, statusText: '' }
        );
      }

      throw new FetchMediaError(
        'Expected event response, actual:' + typeof response,
        { status: 500, statusText: '' }
      );
    },
    {
      enabled: Boolean(url && token && enabled),

      onError(error) {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            logout();
          }
        }

        if (onError) {
          onError(error);
        }
      },

      ...options,
    }
  );
}
