import React from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, View } from 'react-native';
import {
  ActivityIndicator,
  Avatar,
  Button,
  Card,
  HelperText,
  Text,
  TextInput,
  Title,
} from 'react-native-paper';
import { BackHeader, useGoBack } from '../components/BackHeader';
import { ScreenReaderHidden } from '../components/ScreenReaderHidden';
import { useSnackbar } from '../components/SnackbarProvider';
import { defineTranslations, i18n } from '../locale';
import { PASTEL_BLUE, PASTEL_PINK, PRIMARY_DARK } from '../theming';
import { useForceUpdate } from '../utils/useForceUpdate';
import { useThemedColor } from '../utils/useThemedColor';
import { IMAGE_PIXEL_RATIO, variantImageUrl } from '../utils/variants';
import { usePickedImage } from './usePickedImage';
import {
  useProfile,
  useUpdateProfile,
  useUpdateProfileImage,
} from './useUserProfile';

defineTranslations({
  en: {
    app: {
      account: {
        title: 'My account',

        actions: {
          new_picture: 'Upload new profile picture',
          save: 'Save changes',
          delete: 'Delete account',
        },

        delete_manually:
          'We have temporary suspended automatic deletion of accounts in order to preserve artist accounts. However, you can still exercise your right by emailing info@soundersmusic.com.',
      },
    },
  },

  nl: {
    app: {
      account: {
        title: 'Mijn account',

        actions: {
          new_picture: 'Wijzig profiel plaatje',
          save: 'Opslaan',
          delete: 'Account verwijderen',
        },

        delete_manually:
          'We hebben tijdelijk het automatisch verwijderen van accounts toestaan uitgezet om te voorkomen dat artiesten per ongeluk hun account verwijderen. Je kan je aanvraag indienen op info@soundersmusic.com.',
      },
    },
  },
});

export function AccountScreen() {
  const forceUpdate = useForceUpdate();
  const onGoBack = useGoBack();

  const { isLoading } = useProfile();
  const {
    profileRef,
    mutation: { mutateAsync: update, isLoading: isUpdating },
    prepare,
  } = useUpdateProfile({
    onSuccess: () => {
      onGoBack();
    },
  });

  const { mutateAsync: updateImage, isLoading: updatingImage } =
    useUpdateProfileImage();

  const [pickedImage, openFilePicker] = usePickedImage(updateImage);
  const imageBaseUri =
    pickedImage?.uri || profileRef.current?._links.profile_image.href || '';

  const ratio = IMAGE_PIXEL_RATIO;
  const size = ratio * 150;
  const uri = variantImageUrl(imageBaseUri, 'size');

  const title = useThemedColor(PRIMARY_DARK, PASTEL_PINK);
  const onTitle = useThemedColor('#FFF', '#000');
  const { show } = useSnackbar();

  return (
    <KeyboardAvoidingView>
      <ScrollView
        style={{
          width: '100%',
          height: '100%',
          maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        }}
        contentContainerStyle={{
          width: '100%',
          maxWidth: 600,
          minHeight: '100%',
          alignSelf: 'center',
          overflow: 'hidden',

          paddingTop: 128,
        }}
      >
        <View
          style={{
            width: '100%',
            maxWidth: '100%',
            flex: 1,
            justifyContent: 'space-between',
            maxHeight: 720,
          }}
        >
          <Text
            variant="titleLarge"
            style={{
              color: title,
              fontSize: 30,
              letterSpacing: 30 / 20,
              lineHeight: 34,
              maxWidth: 250,
              marginHorizontal: 38,
              marginBottom: 16,
              includeFontPadding: false,
              display: 'flex',
            }}
          >
            {i18n.translate('app.account.title')}
          </Text>

          <View
            style={{
              marginHorizontal: 38,
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Card
              onPress={openFilePicker}
              onLongPress={openFilePicker}
              style={{ borderRadius: size / 2, height: size, width: size }}
            >
              <Avatar.Image
                size={size}
                source={{
                  uri,
                  width: size,
                  height: size,
                }}
              />
            </Card>
            {updatingImage ? (
              <ActivityIndicator
                style={{
                  width: size,
                  height: size,
                  position: 'absolute',
                  top: 0,
                }}
                size="large"
              />
            ) : null}
            <Button
              disabled={updatingImage || isLoading}
              mode="text"
              textColor={title}
              uppercase={false}
              style={{ marginTop: 20 }}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
              onPress={openFilePicker}
            >
              {i18n.translate('app.account.actions.new_picture')}
            </Button>
          </View>

          <View style={{ marginHorizontal: 38 }}>
            <TextInput
              mode="flat"
              style={{
                backgroundColor: 'transparent',
                paddingHorizontal: 0,
                textAlignVertical: 'center',
                includeFontPadding: false,
              }}
              label={i18n.translate('app.fields.display_name.label')}
              numberOfLines={1}
              keyboardType="default"
              returnKeyType="next"
              autoCapitalize="words"
              autoCorrect={false}
              autoComplete="name"
              {...Platform.select({
                android: {},
                ios: {
                  textContentType: 'nickname',
                  autoComplete: 'nickname' as string as 'name',
                },
                default: {},
              })}
              theme={{ colors: { primary: title } }}
              accessibilityLabelledBy="display-name-description"
              key={profileRef.current ? 'done' : 'current'}
              disabled={!!isUpdating}
              defaultValue={profileRef.current?.name || ''}
              onBlur={forceUpdate}
              onChangeText={(text) => {
                if (profileRef.current) {
                  profileRef.current.name = text;
                }
              }}
            />
            <ScreenReaderHidden
              webStyle={{ marginTop: 8 }}
              nativeStyle={{ marginTop: 8 }}
            >
              <HelperText
                nativeID="display-name-description"
                type="info"
                style={{ paddingHorizontal: 0 }}
              >
                {i18n.translate('app.fields.display_name.helper_text')}
              </HelperText>
            </ScreenReaderHidden>
          </View>

          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginVertical: 24,
              marginHorizontal: 'auto',
              paddingHorizontal: 38,
            }}
          >
            <Button
              mode="contained-tonal"
              disabled={updatingImage || isLoading}
              buttonColor={title}
              textColor={onTitle}
              style={{
                borderRadius: 20,
                alignSelf: 'center',
                marginRight: 24,
                marginTop: 12,
              }}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
              onPress={() => {
                const patch = prepare({ next: profileRef.current! });
                if (patch) {
                  update({ patch }).catch(() => {});
                } else {
                  onGoBack();
                }
              }}
            >
              {i18n.translate('app.account.actions.save')}
            </Button>

            <Button
              mode="contained"
              disabled={updatingImage || isLoading}
              buttonColor={PASTEL_BLUE}
              textColor="#000"
              style={{
                borderRadius: 20,
                alignSelf: 'center',
                marginTop: 12,
              }}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
              onPress={() => {
                show(i18n.translate('app.account.delete_manually'));
              }}
            >
              {i18n.translate('app.account.actions.delete')}
            </Button>
          </View>
        </View>

        <View
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <BackHeader />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}
