import { useLayoutEffect, useState } from 'react';
import { useIsConnected } from '../hooks/useIsConnected';
import { usePersistentSnackbar } from './SnackbarProvider';

const REPORTING_TIMEOUT = 1000 * 5;

export function Connectivity() {
  const [forceDismiss, setForceDismiss] = useState(false);

  const info = useIsConnected();
  const { show, hide } = usePersistentSnackbar('connectivity');

  useLayoutEffect(() => {
    let isMounted = true;

    if (info || forceDismiss) {
      hide();
      return;
    }

    const timeout = setTimeout(() => {
      if (isMounted) {
        show(
          "Can't reach the internet. Check your connection, or continue offline. Some pages or functionalities won't work properly until you re-connect.",
          {
            label: 'Dismiss',
            onPress: () => setForceDismiss(true),
            type: 'info',
          }
        );
      }
    }, REPORTING_TIMEOUT);

    return () => {
      clearTimeout(timeout);
      isMounted = false;
    };
  }, [info, forceDismiss]);

  return null;
}
