import React, { memo, useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import Animated, {
  EasingNode as Easing,
  eq,
  interpolateNode as interpolate,
} from 'react-native-reanimated';
import { withTimingTransition } from 'react-native-redash/lib/module/v1/index';
import Svg, { Defs, LinearGradient, Polygon, Stop } from 'react-native-svg';

const HIGHLIGHT_ANIMATION_TIME = 220;

function DropAreas_({
  width,
  height,
  active,
  transparentTheme,
}: {
  width: number;
  height: number;
  active: Animated.Node<number>;
  transparentTheme?: boolean;
}) {
  const {
    colors: { primary, surface },
  } = useTheme();

  const backgroundColor = transparentTheme ? surface : primary;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const animate0 = withTimingTransition(eq(active, 0), {
    duration: HIGHLIGHT_ANIMATION_TIME,
    easing: Easing.inOut(Easing.cubic),
  });
  const animate1 = withTimingTransition(eq(active, 1), {
    duration: HIGHLIGHT_ANIMATION_TIME,
    easing: Easing.inOut(Easing.cubic),
  });
  const animate2 = withTimingTransition(eq(active, 2), {
    duration: HIGHLIGHT_ANIMATION_TIME,
    easing: Easing.inOut(Easing.cubic),
  });
  const animate3 = withTimingTransition(eq(active, 3), {
    duration: HIGHLIGHT_ANIMATION_TIME,
    easing: Easing.inOut(Easing.cubic),
  });
  const animate4 = withTimingTransition(eq(active, 4), {
    duration: HIGHLIGHT_ANIMATION_TIME,
    easing: Easing.inOut(Easing.cubic),
  });
  const animate5 = withTimingTransition(eq(active, 5), {
    duration: HIGHLIGHT_ANIMATION_TIME,
    easing: Easing.inOut(Easing.cubic),
  });

  return (
    <View
      style={{
        opacity: mounted ? 1 : 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width,
        height,
      }}
    >
      <Animated.View
        nativeID="drop-0"
        style={[
          {
            opacity: interpolate(animate0, {
              inputRange: [0, 1],
              outputRange: [0, 0.38],
            }),
          },
          {
            position: 'absolute',
            top: 0,
            left: 0,
            width,
            height,
          },
        ]}
      >
        <Svg
          preserveAspectRatio="none"
          width={width}
          height={height}
          viewBox="0 0 375 812"
        >
          <Defs>
            <LinearGradient
              id="area-0"
              gradientTransform={Platform.select({
                android: 'translate(-500, 0) rotate(-80)',
                default: 'rotate(-80)',
              })}
            >
              <Stop
                offset={Platform.select({ android: '0%', default: '0%' })}
                stopColor={primary}
                stopOpacity={1}
              />
              <Stop
                offset={Platform.select({ android: '23%', default: '5%' })}
                stopColor={primary}
                stopOpacity={0.5}
              />
              <Stop
                offset={Platform.select({ android: '40%', default: '10%' })}
                stopColor={primary}
                stopOpacity={0}
              />
            </LinearGradient>
          </Defs>
          <Polygon
            points={['0 0', '230.23 0', '62.3 241.09', '0 241.09']}
            fill="url(#area-0)"
          />
        </Svg>
      </Animated.View>

      <Animated.View
        nativeID="drop-1"
        style={[
          {
            opacity: interpolate(animate1, {
              inputRange: [0, 1],
              outputRange: [0, 0.38],
            }),
          },
          {
            position: 'absolute',
            top: 0,
            left: 0,
            width,
            height,
          },
        ]}
      >
        <Svg
          preserveAspectRatio="none"
          width={width}
          height={height}
          viewBox="0 0 375 812"
        >
          <Defs>
            <LinearGradient
              id="area-1"
              gradientTransform={Platform.select({
                android: 'translate(-50, 0) rotate(-45)',
                default: 'rotate(-45)',
              })}
            >
              <Stop offset="0%" stopColor={backgroundColor} stopOpacity={1} />
              <Stop
                offset="20%"
                stopColor={backgroundColor}
                stopOpacity={0.5}
              />
              <Stop offset="30%" stopColor={backgroundColor} stopOpacity={0} />
            </LinearGradient>
          </Defs>
          <Polygon
            points={[
              '65.65 235.77',
              '230.23 0',
              '375.59 0',
              '375.59 160.64',
              '147.2 287.28',
            ]}
            fill="url(#area-1)"
          />
        </Svg>
      </Animated.View>

      <Animated.View
        nativeID="drop-2"
        style={[
          {
            opacity: interpolate(animate2, {
              inputRange: [0, 1],
              outputRange: [0, 0.38],
            }),
          },
          {
            position: 'absolute',
            top: 0,
            left: 0,
            width,
            height,
          },
        ]}
      >
        <Svg
          preserveAspectRatio="none"
          width={width}
          height={height}
          viewBox="0 0 375 812"
        >
          <Defs>
            <LinearGradient id="area-2" gradientTransform="rotate(-10)">
              <Stop
                offset={Platform.select({ android: '40%', default: '50%' })}
                stopColor={backgroundColor}
                stopOpacity={1}
              />
              <Stop
                offset={Platform.select({ android: '60%', default: '70%' })}
                stopColor={backgroundColor}
                stopOpacity={0.5}
              />
              <Stop
                offset={Platform.select({ android: '70%', default: '80%' })}
                stopColor={backgroundColor}
                stopOpacity={0}
              />
            </LinearGradient>
          </Defs>
          <Polygon
            points={[
              '375.49 160.95',
              '375.49 377.25',
              '174.82 377.25',
              '141.58 290.36',
            ]}
            fill="url(#area-2)"
          />
        </Svg>
      </Animated.View>

      <Animated.View
        nativeID="drop-3"
        style={[
          {
            opacity: interpolate(animate3, {
              inputRange: [0, 1],
              outputRange: [0, 0.38],
            }),
          },
          {
            position: 'absolute',
            top: 0,
            left: 0,
            width,
            height,
          },
        ]}
      >
        <Svg
          preserveAspectRatio="none"
          width={width}
          height={height}
          viewBox="0 0 375 812"
        >
          <Defs>
            <LinearGradient
              id="area-3"
              gradientTransform={Platform.select({
                default: 'translate(375, 812) rotate(10) translate(-375, -812)',
                web: 'rotate(10)',
              })}
            >
              <Stop
                offset={Platform.select({ default: '40%', web: '70%' })}
                stopColor={backgroundColor}
                stopOpacity={1}
              />
              <Stop
                offset={Platform.select({ default: '60%', web: '80%' })}
                stopColor={backgroundColor}
                stopOpacity={0.5}
              />
              <Stop
                offset={Platform.select({ default: '70%', web: '98%' })}
                stopColor={backgroundColor}
                stopOpacity={0}
              />
            </LinearGradient>
          </Defs>
          <Polygon
            points={['375 376.5', '375 588.43', '146.3 461.49', '175.53 376.5']}
            fill="url(#area-3)"
          />
        </Svg>
      </Animated.View>

      <Animated.View
        nativeID="drop-4"
        style={[
          {
            opacity: interpolate(animate4, {
              inputRange: [0, 1],
              outputRange: [0, 0.38],
            }),
          },
          {
            position: 'absolute',
            top: 0,
            left: 0,
            width,
            height,
          },
        ]}
      >
        <Svg
          preserveAspectRatio="none"
          width={width}
          height={height}
          viewBox="0 0 375 812"
        >
          <Defs>
            <LinearGradient
              id="area-4"
              gradientTransform={Platform.select({
                default: 'translate(375, 812) rotate(45) translate(-375, -812)',
                web: 'rotate(45)',
              })}
            >
              <Stop
                offset={Platform.select({ default: '20%', web: '67%' })}
                stopColor={backgroundColor}
                stopOpacity={1}
              />
              <Stop
                offset={Platform.select({ default: '32%', web: '74%' })}
                stopColor={backgroundColor}
                stopOpacity={0.5}
              />
              <Stop
                offset={Platform.select({ default: '45%', web: '93%' })}
                stopColor={backgroundColor}
                stopOpacity={0}
              />
            </LinearGradient>
          </Defs>
          <Polygon
            points={[
              '91 458',
              '149.32 463.26',
              '375.29 589.22',
              '375.29 812.22',
              '256.83 812.22',
              '68.52 514.44',
            ]}
            fill="url(#area-4)"
          />
        </Svg>
      </Animated.View>

      <Animated.View
        nativeID="drop-5"
        style={[
          {
            opacity: interpolate(animate5, {
              inputRange: [0, 1],
              outputRange: [0, 0.38],
            }),
          },
          {
            position: 'absolute',
            top: 0,
            left: 0,
            width,
            height,
          },
        ]}
      >
        <Svg
          preserveAspectRatio="none"
          width={width}
          height={height}
          viewBox="0 0 375 812"
        >
          <Defs>
            <LinearGradient
              id="area-5"
              gradientTransform={Platform.select({
                default: 'translate(375, 812) rotate(70) translate(-375, -812)',
                web: 'rotate(70)',
              })}
            >
              <Stop offset="70%" stopColor={backgroundColor} stopOpacity={1} />
              <Stop
                offset="85%"
                stopColor={backgroundColor}
                stopOpacity={0.5}
              />
              <Stop offset="98%" stopColor={backgroundColor} stopOpacity={0} />
            </LinearGradient>
          </Defs>
          <Polygon
            points={['0 458', '68.52 514.44', '256.83 812.22', '0 812.22']}
            fill="url(#area-5)"
          />
        </Svg>
      </Animated.View>
    </View>
  );
}

export const DropAreas = memo(DropAreas_);
