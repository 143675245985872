import { fetchMedia, FetchMediaError } from 'fetch-media';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { IS_DEBUG } from '../debug';
import { i18n } from '../locale';
import { useEndpoint } from './useEndpoint';
import { useLocale } from './useLocale';

const DEBUG_CONFIGURATION = false;

export type RemoteConfiguration =
  | RemoteConfigurationV1
  | RemoteConfigurationV2
  | RemoteConfigurationV3;

type RemoteConfigurationV1 = {
  _links: {
    self: { href: string };
    forgot: { href: string };
    login: { href: string };
    external_auth: { href: string };
    register: { href: string };
    reset: { href: string; templated: true };
    unlock: { href: string; templated: true };
    verify: { href: string; templated: true };
    journey: {
      href: string;
      templated: true;
    };
  };
};

type RemoteConfigurationV2 = RemoteConfigurationV1 & {
  _links: {
    countries: {
      href: string;
    };
    genres: {
      href: string;
    };
    profile_tags: {
      href: string;
    };
    segments: {
      href: string;
    };
  };
};

type RemoteConfigurationV3 = RemoteConfigurationV1 &
  RemoteConfigurationV2 & {
    _links: {
      invitation: {
        href: string;
        templated: true;
      };
    };
  };

export function isAtLeastV2(
  configuration: RemoteConfiguration | undefined
): configuration is RemoteConfigurationV2 | RemoteConfigurationV3 {
  return Boolean(configuration && 'profile_tags' in configuration._links);
}

export function useConfiguration({
  enabled = true,
  ...options
}: UseQueryOptions<RemoteConfiguration, FetchMediaError> = {}): UseQueryResult<
  RemoteConfiguration,
  FetchMediaError
> {
  const endpoint = useEndpoint();
  const locale = useLocale();

  return useQuery<RemoteConfiguration, FetchMediaError>(
    [locale, endpoint, 'configuration'] as QueryKey,
    ({ signal }) => {
      return fetchConfiguration(endpoint!, locale, signal);
    },
    {
      enabled: Boolean(enabled) && Boolean(endpoint),
      cacheTime: 15 * 60 * 1000, // 15 minutes fresh
      staleTime: 60 * 1000, // 60 seconds stale
      ...options,
    }
  );
}

export async function fetchConfiguration(
  endpoint: string,
  locale = i18n.locale,
  signal?: AbortSignal
) {
  if (!endpoint) {
    throw new Error('Requires endpoint to be set');
  }

  // TODO allow base endpoint be configurable
  const result = await fetchMedia(endpoint, {
    method: 'GET',
    headers: {
      accept: [
        'application/vnd.soundersmusic.configuration.v3.public+json',
        'application/vnd.soundersmusic.configuration.v2.public+json; q=0.9',
        'application/vnd.soundersmusic.configuration.v1.public+json; q=0.8',
      ].join(', '),
      acceptLanguage: locale,
    },

    disableFormData: true,
    disableFormUrlEncoded: true,
    disableText: true,

    debug: IS_DEBUG && DEBUG_CONFIGURATION,
    signal,
  });

  // TODO: cancellable

  if (typeof result !== 'object' || !result) {
    throw new Error(
      `Expected configuration object, got ${result} (${typeof result})`
    );
  }

  if (Object.prototype.hasOwnProperty.call(result, 'configuration')) {
    return (result as { configuration: RemoteConfiguration }).configuration;
  }

  throw new Error(
    `Expected 'config' key in result, actual: ${Object.keys(result)}`
  );
}
