import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import cloneDeep from 'lodash.clonedeep';
import React, { Fragment, memo, useCallback, useEffect } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { Title } from 'react-native-paper';
import Animated, {
  DerivedValue,
  Extrapolation,
  interpolate,
  runOnUI,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withDelay,
  withRepeat,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { useIsMounted } from 'use-is-mounted';
import { useUpdateProfile } from '../account/useUserProfile';
import { RoundButton } from '../components/Button';
import { defineTranslations, i18n } from '../locale';
import { resetToPath } from '../navigation/utils';
import { PRIMARY_DARK } from '../theming';
import { useWindowDimensions } from '../utils/useLerpWidth';

defineTranslations({
  en: {
    app: {
      tutorial: {
        title: 'How to rate:',
        explain_tap: 'Or simply tap a number',
        explain_drag:
          'Drag the record to the rating you think the track deserves.',
      },
    },
  },
  nl: {
    app: {
      tutorial: {
        title: 'Stemmen werkt zo:',
        explain_tap: 'Of druk op een getal',
        explain_drag:
          'Sleep de muziekplaat over de rating die past bij jouw mening.',
      },
    },
  },
});

export function TutorialScreen() {
  const isMounted = useIsMounted();

  const {
    prepare,
    profileRef,
    mutation: { isLoading: isUpdating, mutateAsync: submitProfile },
  } = useUpdateProfile();

  const submit = useCallback(() => {
    const { current: profile } = profileRef;

    if (!profile) {
      return;
    }

    const nextProfile = cloneDeep(profile);

    const onboardedFeatures = nextProfile.onboarded_features
      .concat(['how-to-swipe.2020-07-20'])
      .filter((feature, index, self) => self.indexOf(feature) === index);

    nextProfile.onboarded_features = onboardedFeatures;

    const patch = prepare({ next: nextProfile });
    if (!patch) {
      return resetToPath('/getting-started/soundality');
    }

    return submitProfile({ patch })
      .then(
        () => isMounted.current && resetToPath('/getting-started/soundality')
      )
      .catch(() => {});
  }, [submitProfile, isMounted]);

  return (
    <View
      style={{
        width: '100%',
        backgroundColor: 'white',
        alignItems: 'center',
        overflow: 'hidden',
        height: '100%',
        maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        alignSelf: 'center',
      }}
    >
      <Animation />

      <View
        style={{
          minHeight: 400,
          maxHeight: 1200,
          width: '100%',
          maxWidth: '100%',
          justifyContent: 'space-evenly',
          flex: 1,
        }}
      >
        <View style={{ alignItems: 'center', marginTop: 'auto', padding: 32 }}>
          <RoundButton
            color={PRIMARY_DARK}
            textColor="#fff"
            disabled={!!isUpdating}
            label="Go"
            onPress={submit}
          />
        </View>
      </View>
    </View>
  );
}

const LOOP_TIME = 7 * 1000;
const SLIDES = 2;

function Animation_() {
  const loop = useSharedValue(0);

  // Make it loop indefinitely
  useEffect(() => {
    const startLoop = () => {
      loop.value = withRepeat(withTiming(1, { duration: LOOP_TIME }), -1);
    };

    runOnUI(startLoop)();
  }, [loop]);

  const animation = useDerivedValue(
    () => interpolate(loop.value, [0, 1], [0, SLIDES]),
    [loop]
  );

  return (
    <Fragment>
      <Visuals
        animation={animation}
        start={0}
        blur
        vertical={32}
        header={i18n.translate('app.tutorial.title')}
        description={i18n.translate('app.tutorial.explain_drag')}
      />
      <Visuals
        animation={animation}
        start={1}
        blur
        blurDisc
        vertical={32}
        header={i18n.translate('app.tutorial.title')}
        description={i18n.translate('app.tutorial.explain_tap')}
      />

      <AnimationProgress current={animation} count={2} />
    </Fragment>
  );
}

const Animation = memo(Animation_);

// discovery_vote_profile
function Visuals({
  blur,
  blurDisc,
  animation,
  start,
  vertical,
  header,
  description,
}: {
  blur?: boolean;
  blurDisc?: boolean;
  animation: DerivedValue<number>;
  start: number;
  vertical: number;
  header?: string;
  description?: string;
}) {
  const { width, height } = useWindowDimensions();

  const widthToUse = width;
  const heightToUse = height - vertical * 2;

  const limitedWidth = Math.min(widthToUse, 600);
  const limitedHeight = Math.min(1000, heightToUse);

  const limit =
    widthToUse / heightToUse > 361 / 619 ? limitedHeight : limitedWidth;
  const dimRatio = 361 / 619;

  const actualHeight =
    limit === limitedHeight ? limitedHeight : limitedWidth / dimRatio;
  const actualWidth =
    limit === limitedWidth ? limitedWidth : limitedHeight * dimRatio;

  const ratio =
    limit === limitedWidth ? limitedWidth / 361 : limitedHeight / 619;

  const discSize = 366 * ratio;
  const selectedWidth = 187 * ratio;
  const selectedHeight = 217 * ratio;
  const highlightWidth = selectedWidth * (226 / 171);
  const highlightHeight = (highlightWidth / 678) * 636;
  const top = 333.5 * ratio;
  const discTop = (actualHeight - discSize) / 2 + 38 * ratio;
  const discLeft = -141 * ratio;

  const initial = !blur && !blurDisc;
  const shouldAnimateDisc = blur && !blurDisc;
  const shouldAnimateHighlight = !initial;

  const discAnimation = useDerivedValue(() => {
    return animation.value;
  }, [start, animation]);

  const opacityStyle = useAnimatedStyle(() => {
    return {
      opacity: initial
        ? 1
        : interpolate(
            animation.value,
            [start, start + 0.1, start + 0.9, start + 1],
            [0, 1, 1, 0],
            Extrapolation.CLAMP
          ),
    };
  }, [initial, animation, start]);

  const opacitySelectedStyle = useAnimatedStyle(() => {
    return {
      opacity: shouldAnimateHighlight
        ? interpolate(
            animation.value,
            [start + 0.3, start + 0.5],
            [1, 0],
            Extrapolation.CLAMP
          )
        : 0,
    };
  }, [shouldAnimateHighlight, animation, start]);

  const opacityHighlightStyle = useAnimatedStyle(() => {
    return {
      opacity: shouldAnimateHighlight
        ? interpolate(
            animation.value,
            [start + 0.3, start + 0.5],
            [0, 1],
            Extrapolation.CLAMP
          )
        : 0,
    };
  }, [shouldAnimateHighlight, animation, start]);

  const discStyle = useAnimatedStyle(() => {
    return {
      opacity: shouldAnimateDisc
        ? interpolate(
            animation.value,
            [start + 0.4, start + 0.5],
            [1, 0],
            Extrapolation.CLAMP
          )
        : 1,
      transform: blurDisc
        ? []
        : [
            {
              translateX: interpolate(
                discAnimation.value,
                [0, 1, 2],
                [0, actualWidth * 0.5, 0],
                Extrapolation.CLAMP
              ),
            },
            {
              translateY: interpolate(
                discAnimation.value,
                [0, 1, 2],
                [0, (top - discTop) / 2, 0],
                Extrapolation.CLAMP
              ),
            },
            {
              scale: interpolate(
                discAnimation.value,
                [0, 1, 2],
                [1, 0.55, 0],
                Extrapolation.CLAMP
              ),
            },
          ],
    };
  }, [shouldAnimateDisc, blurDisc, start, actualWidth, top, discTop]);

  return (
    <Animated.View
      style={[
        opacityStyle,
        {
          backgroundColor: 'white',
          alignItems: 'center',
          top: vertical,
          bottom: vertical,
          right: 0,
          left: 0,
          overflow: 'hidden',
          position: 'absolute',
        },
      ]}
    >
      <Animated.View
        style={{
          maxHeight: actualHeight,
          maxWidth: actualWidth,
          width: actualWidth,
          height: actualHeight,
          overflow: 'visible',
          position: 'absolute',
          top: 0,
        }}
      >
        <Image
          source={require('../../assets/tutorial/grid_2x.png')}
          style={{
            width: actualWidth,
            height: actualHeight,
            position: 'absolute',
            opacity: 1,
          }}
          blurRadius={blur ? 4 : 0}
        />
        <Animated.Image
          source={require('../../assets/tutorial/selected_2x.png')}
          style={[
            opacitySelectedStyle,
            {
              width: selectedWidth,
              height: selectedHeight,
              position: 'absolute',
              right: -1.5 * ratio,
              top,
            },
          ]}
        />

        <Animated.Image
          source={require('../../assets/tutorial/highlight_2x.png')}
          style={[
            opacityHighlightStyle,
            {
              width: highlightWidth,
              height: highlightHeight,
              position: 'absolute',
              right: -1.5 * ratio - (highlightWidth - selectedWidth) / 2,
              top,
            },
          ]}
        />
        <Animated.Image
          source={require('../../assets/tutorial/disc_2x.png')}
          style={[
            discStyle,
            {
              width: discSize,
              height: discSize,
              position: 'absolute',
              left: discLeft,
              top: discTop,
            },
          ]}
          resizeMode="cover"
          blurRadius={blurDisc ? 6 : 0}
        />
      </Animated.View>

      {header ? (
        <LinearGradient
          colors={['#ffffff', '#ffffff', '#ffffff00', '#ffffff00', '#ffffff']}
          style={StyleSheet.absoluteFill}
        />
      ) : null}

      <Animated.View
        style={{
          maxWidth: actualWidth,
          width: actualWidth,
          overflow: 'visible',
          position: 'absolute',
          top: dimRatio * 64,
          paddingHorizontal: 32,
        }}
      >
        <Title
          style={{
            fontSize: 30,
            color: `${PRIMARY_DARK}80`,
            lineHeight: 34,
            maxWidth: '100%',
            width: 259,
          }}
        >
          {header}
        </Title>
        <Title
          style={{
            fontSize: 30,
            color: PRIMARY_DARK,
            lineHeight: 34,
            maxWidth: '100%',
            width: 259,
          }}
        >
          {description}
        </Title>
      </Animated.View>
    </Animated.View>
  );
}

function AnimationProgress({
  current,
  count,
}: {
  current: DerivedValue<number>;
  count: number;
}) {
  const animation = useDerivedValue(() => {
    return withSpring(Math.floor(current.value), { damping: 15, mass: 0.5 });
  }, [current]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: animation.value * 20 }],
    };
  }, [animation]);

  return (
    <View
      style={{
        position: 'absolute',
        marginHorizontal: 'auto',
        alignSelf: 'center',
        maxWidth: 800,
        width: '100%',
        bottom: 0,
      }}
      pointerEvents="none"
    >
      <View
        style={{
          position: 'absolute',
          left: 32,
          bottom: 32,
          height: 74,
          width: (count - 1) * 45,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {new Array(count).fill(null).map((_, i) => (
          <View
            key={i}
            style={{
              width: 8,
              height: 8,
              borderRadius: 4,
              backgroundColor: `${PRIMARY_DARK}70`,
            }}
          />
        ))}

        <Animated.View
          style={[
            animatedStyle,
            {
              width: 26,
              borderRadius: 8,
              height: 8,
              backgroundColor: PRIMARY_DARK,
              position: 'absolute',
            },
          ]}
        />
      </View>
    </View>
  );
}
