import {
  getCameraPermissionsAsync,
  getMediaLibraryPermissionsAsync,
  ImagePickerResult,
  launchCameraAsync,
  launchImageLibraryAsync,
  MediaTypeOptions,
  requestCameraPermissionsAsync,
  requestMediaLibraryPermissionsAsync,
} from 'expo-image-picker';
import { useCallback } from 'react';
import { Alert } from 'react-native';

export function usePickImageFromLibrary(
  onResult: (result: ImagePickerResult) => void
) {
  return useCallback(async () => {
    const permission = await getMediaLibraryPermissionsAsync(false);
    if (permission.granted) {
      return launchImageLibraryAsync({
        allowsEditing: true,
        allowsMultipleSelection: false,
        mediaTypes: MediaTypeOptions.Images,
        exif: true,
        base64: false,
      }).then(onResult);
    }

    if (permission.canAskAgain) {
      const newPermission = await requestMediaLibraryPermissionsAsync(false);
      if (newPermission.granted) {
        return launchImageLibraryAsync({
          allowsEditing: true,
          allowsMultipleSelection: false,
          mediaTypes: MediaTypeOptions.Images,
          exif: true,
          base64: false,
        }).then(onResult);
      }
    }

    Alert.alert(
      'Permission required',
      'Media library permissions is required to choose a photo from your library'
    );
  }, [onResult]);
}

export function usePickImageFromCamera(
  onResult: (result: ImagePickerResult) => void
) {
  return useCallback(async () => {
    const permission = await getCameraPermissionsAsync();
    if (permission.granted) {
      return launchCameraAsync({
        allowsEditing: true,
        allowsMultipleSelection: false,
        mediaTypes: MediaTypeOptions.Images,
        exif: true,
        base64: false,
      }).then(onResult);
    }

    if (permission.canAskAgain) {
      const newPermission = await requestCameraPermissionsAsync();
      if (newPermission.granted) {
        return launchCameraAsync({
          allowsEditing: true,
          allowsMultipleSelection: false,
          mediaTypes: MediaTypeOptions.Images,
          exif: true,
          base64: false,
        }).then(onResult);
      }
    }

    Alert.alert(
      'Permission required',
      'Camera permissions is required to take a photo'
    );
  }, []);
}
