import NetInfo from '@react-native-community/netinfo';
import { FetchMediaError } from 'fetch-media';
import React from 'react';
import type { AppStateStatus } from 'react-native';
import { AppState, Platform } from 'react-native';
import {
  focusManager,
  onlineManager,
  QueryClient,
  QueryClientProvider,
  setLogger,
} from 'react-query';
import { RetryValue } from 'react-query/types/core/retryer';
import { MaybeDevTools } from './MaybeDevTools';

// import { MaybeDevTools } from './MaybeDevTools';

setLogger({
  error: (error) => console.log(`[error] ${error}`, error),
  log: () => {},
  warn: () => {},
});

focusManager.setEventListener((onFocus) => {
  if (Platform.OS === 'web') {
    window.addEventListener('focus', onFocus as any);
    return () => window.removeEventListener('focus', onFocus as any);
  }

  const handleAppStateChange = (appState: AppStateStatus) => {
    if (appState === 'active') {
      onFocus();
    }
  };

  const subscription = AppState.addEventListener(
    'change',
    handleAppStateChange
  );
  return () => subscription.remove();
});

onlineManager.setEventListener((setOnline: (isOnline: boolean) => void) => {
  return NetInfo.addEventListener((state) => {
    setOnline(Boolean(state.isConnected));
  });
});

type ClientErrorStatus =
  | 400 // Bad Request
  | 401 // Unauthorized
  | 402 // Payment Required
  | 403 // Forbidden
  | 404 // Not Found
  | 405 // Method Not Allowed
  | 406 // Not Acceptable
  | 407 // Proxy Authentication Required
  | 408 // Request Timeout
  | 409 // Conflict
  | 410 // Gone
  | 411 // Length Required
  | 412 // Precondition Failed
  | 413 // Payload Too Large
  | 414 // Request-URI Too Long
  | 415 // Unsupported Media Type
  | 416 // Requested Range Not Satisfiable
  | 417 // Expectation Failed
  | 418 // I'm a teapot
  | 421 // Misdirected Request
  | 422 // Unprocessable Entity
  | 423 // Locked
  | 424 // Failed Dependency
  | 426 // Upgrade Required
  | 428 // Precondition Required
  | 429 // Too Many Requests
  | 431 // Request Header Fields Too Large
  | 444 // Connection Closed Without Response
  | 451 // Unavailable For Legal Reasons
  | 499; // Client Closed Request
function isClientError(
  error: null | Error
): error is FetchMediaError & { response: { status: ClientErrorStatus } } {
  return (
    error instanceof FetchMediaError &&
    error.response.status >= 400 &&
    error.response.status < 500
  );
}

export const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // When the error is a client error, there is no need to retry the
        // query. In this case, retrying won't solve the initial issue (unless
        // the server is somehow misbehaving).
        if (error instanceof Error && isClientError(error)) {
          if (error.response.status === 429) {
            const when = (error.response as Response).headers.get(
              'Retry-After'
            );
            if (when) {
              return true;
            }
          }

          return false;
        }

        // At most retry {retryCount} times. By default it will use exponential
        // backing to retry the query.
        if (failureCount >= 3) {
          return false;
        }

        return true;
      },
    },
  },
});

export const RetryMutation: RetryValue<any> = function retry(
  failureCount,
  error
) {
  // When the error is a client error, there is no need to retry the
  // query. In this case, retrying won't solve the initial issue (unless
  // the server is somehow misbehaving).
  if (error instanceof Error && isClientError(error)) {
    if (error.response.status === 429) {
      const when = (error.response as Response).headers.get('Retry-After');
      if (when) {
        return true;
      }
    }

    if (error.response.status === 401) {
      // Should be true if refreshing
      return false;
    }

    return false;
  }

  // At most retry {retryCount} times. By default it will use exponential
  // backing to retry the query.
  if (failureCount >= 3) {
    return false;
  }

  return true;
};

export function QueryCacheProvider({
  children,
}: React.PropsWithChildren<object>) {
  return (
    <QueryClientProvider client={QUERY_CLIENT}>
      {children}
      <MaybeDevTools />
    </QueryClientProvider>
  );
}
