import { Pressable, PressableProps, ViewProps } from 'react-native';
import { Surface, SurfaceProps } from 'react-native-paper';

export function PressableSurface({
  linkProps,
  children,
  style,
  elevation,

  ...surfaceProps
}: {
  linkProps?: {
    href?: string;
    accessibilityRole: ViewProps['accessibilityRole'];
    onPress?: PressableProps['onPress'];
  };
} & SurfaceProps) {
  if (!linkProps?.href || linkProps.accessibilityRole === 'button') {
    return (
      <Pressable {...linkProps}>
        {({ pressed }) => (
          <Surface
            {...surfaceProps}
            style={[style, { opacity: pressed ? 0.9 : 1 }]}
            elevation={pressed ? 0 : elevation}
          >
            {children}
          </Surface>
        )}
      </Pressable>
    );
  }

  return (
    <Surface
      {...surfaceProps}
      {...linkProps}
      style={style}
      elevation={elevation}
    >
      {children}
    </Surface>
  );
}
