import { applicationId } from 'expo-application';
import {
  AnyMemoryValue,
  AnyValue,
  SecureStoredMemoryValue,
  StoredMemoryValue,
} from 'expo-use-memory-value';
import { FetchMediaError, fetchMediaWrapped } from 'fetch-media';
import {
  createContext,
  createElement,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { Platform } from 'react-native';
import { useQueryClient, UseQueryOptions } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { IS_DEBUG } from '../debug';

const DEBUG_TOKEN = false;

export type SoundersToken = {
  _links: {
    configuration: { href: string };
    my_permit: { href: string };
  };
  expires_at: string;
  stamp: string;
  token: string;
};

const TOKEN_STORAGE_KEY = `${applicationId ?? 'com.soundersmusic.app'}.token`;
const TOKEN: AnyMemoryValue<SoundersToken | null> & {
  hydrate(): Promise<unknown>;
} =
  Platform.OS === 'web'
    ? new StoredMemoryValue<SoundersToken | null>(
        TOKEN_STORAGE_KEY,
        false,
        undefined
      )
    : new SecureStoredMemoryValue<SoundersToken | null>(
        TOKEN_STORAGE_KEY,
        false,
        undefined
      );

if (IS_DEBUG) {
  TOKEN.subscribe((next) => {
    console.debug(`[token] New token emitted ${next?.token}`);
  });
}

export function hasToken() {
  return !!TOKEN.current?.token;
}

const SLACK_TO_REFRESH = 1000 * 60 * 5; // 5 minutes

export type UseTokenOptions = UseQueryOptions<
  SoundersToken | null,
  FetchMediaError
>;

const TokenContext = createContext<
  TokenState & { ref: React.RefObject<TokenState['token']> } & {
    authenticated(next: SoundersToken): void;
    refresh(prev: SoundersToken | null): Promise<unknown>;
    logout(): Promise<unknown>;
  }
>({
  ...makeInitialState(),
  ref: { current: null },
  authenticated() {},
  refresh() {
    return Promise.reject(new Error(''));
  },
  logout() {
    return Promise.reject(new Error(''));
  },
});

export function TokenProvider({ children }: React.PropsWithChildren<object>) {
  const result = useProvideToken();
  return createElement(TokenContext.Provider, { value: result }, children);
}

export function useToken() {
  const value = useContext(TokenContext);
  if (value === null) {
    throw new Error(
      'Expected provided TokenContext value, got null. Did you forget to wrap the app in a TokenProvider?'
    );
  }

  return value;
}

function useProvideToken() {
  const isMountedRef = useIsMounted();
  const queryClient = useQueryClient();

  const ref: MutableRefObject<TokenState['token'] | null> =
    useRef<TokenState['token']>(null);
  const [state, dispatch] = useReducer(tokenReducer, null, makeInitialState);

  ref.current = state.token;

  const authenticated = useCallback(
    async (nextToken: SoundersToken) => {
      await TOKEN.emit(nextToken);
      if (!isMountedRef.current) {
        return;
      }

      dispatch({ type: 'authenticated', token: nextToken });
    },
    [dispatch]
  );

  const refresh = useCallback(
    async (token: SoundersToken | null) => {
      if (!token) {
        dispatch({ type: 'logout' });
        return false;
      }

      // Already refreshing
      if (state.refreshing || REFRESHING.current) {
        await REFRESHING.current;
        return !!ref.current;
      }

      const tokenNow = ref.current;
      if (tokenNow?.token !== token?.token) {
        throw new Error('Cannot refresh because token to refresh is stale.');
      }

      dispatch({ type: 'refresh' });

      // The promise has the logic to refresh the token in the ref, and also
      // dispatch the action. The token is already stored to storage, regardless
      // of the mounted state of this effect.
      //
      // refreshToken(token._links.refresh.href)
      REFRESHING.current = Promise.reject(new Error('Cannot refresh'))
        .catch(async () => {
          await logout();
          return null;
        })
        .then((next) => {
          if (isMountedRef.current) {
            ref.current = next;
            dispatch({ type: 'refreshed', token: next });
          }
          REFRESHING.current = null;
          return next;
        });

      return (await REFRESHING.current) !== null;
    },
    [ref, dispatch, isMountedRef, state.refreshing]
  );

  const logout = useCallback(async () => {
    await TOKEN.emit(null, true, false);
    await queryClient.cancelQueries();
    queryClient.clear();

    if (!isMountedRef.current) {
      return;
    }

    dispatch({ type: 'logout' });
  }, [dispatch, queryClient, isMountedRef]);

  // Set the refreshing action for the queryClient in this sub-tree.
  useEffect(() => {
    const previousOptions = queryClient.getDefaultOptions();

    queryClient.setDefaultOptions({
      queries: {
        // This query function is NEVER called by itself, because it's a
        // default, but it does allow piercing through an entire app-tree (for
        // this context + query client) and provide a way to ALWAYS wait for the
        // token to be refreshed.
        //
        // Access it through queryClient.
        async queryFn() {
          await REFRESHING.current;
        },

        // This onError query function is called when a query fails (and its
        // retry logic is either exhausted or returns false otherwise). This
        // will force a refresh of the token, if applicable.
        //
        // If you overwrite onError for a single query, make sure to call this
        // so that it will retain this logic.
        async onError(error) {
          const currentToken = ref.current;

          if (error instanceof FetchMediaError) {
            if (error.response.status === 401) {
              // Attempt to refresh
              if (await refresh(currentToken ?? null).catch(() => false)) {
                return;
              }
            }

            console.log('[error]', error.response.status);
          }
        },
        ...previousOptions.queries,
      },
      mutations: {
        // This mutation function is NEVER called by itself, because it's a
        // default, but it does allow piercing through an entire app-tree (for
        // this context + query client) and provide a way to ALWAYS wait for the
        // token to be refreshed.
        //
        // Access it through queryClient.
        async mutationFn() {
          await REFRESHING.current;
        },

        // Same as mutationFn
        async onMutate() {
          await REFRESHING.current;
        },

        // This onError function is normally ONLY called if the mutation is in
        // the error state, but by exposing it like this, it's possible to
        // actually call this without being in the error state, to force a
        // refresh, followed by a retry.
        //
        // if (!wrapped.ok()) {
        //   try {
        //     wrapped.unwrap(); // this always throws
        //     // never reaches this
        //   } catch (error) {
        //      throw await ensureMutationError(queryClient, error as Error);
        //   }
        // }
        async onError(error) {
          const currentToken = ref.current;

          if (error instanceof FetchMediaError) {
            if (error.response.status === 401) {
              // Attempt to refresh
              if (await refresh(currentToken ?? null).catch(() => false)) {
                return;
              }
            }

            console.log('[error]', error.response.status);
          }
        },
        ...previousOptions.mutations,
      },
    });

    return () => {
      queryClient.setDefaultOptions({
        queries: { ...previousOptions.queries },
        mutations: { ...previousOptions.mutations },
      });
    };
  }, [queryClient, refresh]);

  // Hydrate the store, reducer, and token when it first mounts. This will
  // also set the current value of the global TOKEN variable, which will remain
  // being set even if this provider (hook) unmounts.
  useEffect(() => {
    if (state.hydrated) {
      return;
    }

    let mounted = true;
    TOKEN.hydrate()
      .then(() => {
        if (!mounted) {
          return;
        }

        dispatch({ type: 'hydrated', token: TOKEN.current ?? null });
      })
      .catch((e) => {
        console.log('Tried to hydrated', state.hydrated, e);

        if (!mounted) {
          // no-op
          return;
        }

        dispatch({ type: 'hydrated', token: TOKEN.current ?? null });
      });

    return () => {
      mounted = false;
    };
  }, [state.hydrated]);

  // Automatically refresh the token if it's close to the moment to refresh. It
  // will cancel this timer when this hook unmounts, or when the token is
  // refreshed.
  useEffect(() => {
    if (!state.token || state.refreshing) {
      return;
    }

    const timeToExpiration = Math.max(
      0,
      new Date(state.token.expires_at).getTime() -
        new Date().getTime() -
        SLACK_TO_REFRESH
    );
    const timer = setTimeout(refresh, timeToExpiration);
    return () => clearTimeout(timer);
  }, [state.refreshing, state.token, dispatch, refresh]);

  return {
    ...state,
    ref,
    authenticated,
    refresh,
    logout,
  };
}

const REFRESHING = {
  current: null as null | Promise<SoundersToken | null>,
};

async function refreshToken(href: string) {
  console.debug('[token] token is refreshing', href);

  const wrapped = await fetchMediaWrapped(href, {
    method: 'POST',
    headers: {
      accept: [
        'application/vnd.soundersmusic.refreshtoken.v3+json',
        'application/vnd.soundersmusic.refreshtoken.v2+json; q=0.9',
        'application/vnd.soundersmusic.refreshtoken.v1+json; q=0.8',
      ].join(', '),
      cacheControl: 'no-cache',
    },
    disableFormData: true,
    disableFormUrlEncoded: true,
    debug: IS_DEBUG && DEBUG_TOKEN,
  });

  if (!wrapped.ok()) {
    try {
      wrapped.unwrap();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const [contentType] = (
    wrapped.response.headers.get('content-type') ?? ''
  ).split(';');

  if (!contentType.startsWith('application/vnd.soundersmusic.refreshtoken')) {
    throw new FetchMediaError(
      `Expected application/vnd.soundersmusic.refreshtoken.*, actual ${contentType}`,
      wrapped.response
    );
  }

  const nextToken = (wrapped.unwrap() as { refresh_token: SoundersToken })
    .refresh_token;

  if (IS_DEBUG) {
    console.debug('[token] token is now fresh', nextToken.token);
  }

  return TOKEN.emit(nextToken).then(
    () => {
      return nextToken;
    },
    (error) => {
      throw error;
    }
  );
}

function makeInitialState(): TokenState {
  return {
    token: TOKEN.current ?? null,
    refreshedAt: new Date(),
    refreshing: false,
    hydrated: TOKEN.current !== undefined,
  };
}

type TokenState = {
  token: null | SoundersToken;
  refreshedAt: null | Date;
  refreshing: boolean;
  hydrated: boolean;
};

type TokenAction =
  | {
      type: 'hydrated';
      token: null | SoundersToken;
    }
  | {
      type: 'authenticated';
      token: SoundersToken;
    }
  | { type: 'refresh' }
  | { type: 'refreshed'; token: null | SoundersToken }
  | { type: 'logout' };

function tokenReducer(state: TokenState, action: TokenAction): TokenState {
  switch (action.type) {
    case 'hydrated': {
      return { ...state, token: action.token, hydrated: true };
    }
    case 'authenticated': {
      return { ...state, token: action.token, refreshedAt: new Date() };
    }
    case 'refresh': {
      if (state.refreshing) {
        return state;
      }

      return { ...state, refreshing: true };
    }
    case 'refreshed': {
      if (!state.refreshing) {
        console.debug('[token] refreshed but state was not refreshing');
      }

      return {
        ...state,
        refreshing: false,
        refreshedAt: action.token ? new Date() : state.refreshedAt,
        token: action.token,
      };
    }
    case 'logout': {
      return makeInitialState();
    }
  }

  return state;
}

export function runOnLogout(listener: () => void) {
  const onLogout = (next: AnyValue<SoundersToken | null>) => {
    if (next === null) {
      listener();
    }
  };

  TOKEN.subscribe(onLogout);
}
