import { LoginScreen } from '../authentication/LoginScreen';
import { useToken } from '../hooks/useToken';
import { RedirectBack } from './RedirectBack';

export function LandingScreen() {
  const { token } = useToken();

  if (token) {
    return <RedirectBack />;
  }

  return <LoginScreen />;
}
