import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { isAtLeastV2, useConfiguration } from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';

export type SoundersCountriesCollection = {
  _links: {
    self: { href: string };
  };
  _embedded: readonly { country: SoundersCountry }[];
};

export type SoundersCountry = {
  _links: {
    self: { href: string };
    flag: { href: string };
  };
  name: string;
  code2: string;
};

export function usePublicCountries({
  enabled = true,
  ...options
}: UseQueryOptions<SoundersCountriesCollection, FetchMediaError | Error> = {}) {
  const { data: configuration } = useConfiguration();
  const url = isAtLeastV2(configuration)
    ? configuration._links.countries.href
    : undefined;
  const locale = useLocale();

  return useQuery<SoundersCountriesCollection, FetchMediaError | Error>(
    [url, locale] as QueryKey,
    async ({ signal }) => {
      const response = await fetchMedia(url!, {
        headers: {
          accept: [
            'application/vnd.soundersmusic.country.v1.collection+json',
          ].join(', '),
          acceptLanguage: locale,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
      });

      if (typeof response === 'string' || !response) {
        throw new Error(`Expected countries, but got a ${typeof response}`);
      }

      if (!Object.prototype.hasOwnProperty.call(response, 'countries')) {
        throw new Error(
          `Expected countries, but got ${Object.keys(response).join(', ')}`
        );
      }

      return (response as { countries: SoundersCountriesCollection }).countries;
    },
    {
      enabled: Boolean(url && enabled),
      ...options,
    }
  );
}
