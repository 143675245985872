import {
  getPathFromState,
  useIsFocused,
  useNavigation,
  useNavigationState,
  useRoute,
} from '@react-navigation/core';
import { MaterialBottomTabNavigationProp } from '@react-navigation/material-bottom-tabs';
import { useEffect } from 'react';
import linking from '../navigation/LinkingConfiguration';
import { BaseParamList } from '../types';
import { useRedirectBack } from './useRedirectBack';

export function RedirectToLanding() {
  const { hydrated, redirect } = useRedirectBack();

  const focused = useIsFocused();
  const route = useRoute();
  const path = useNavigationState((state) => {
    const routes = state.routes.slice();

    // For whatever reason, the custom menu (Root) may not be included in the
    // state. In that case, it must be manually injected for the
    // getPathFromState function to behave correctly.
    if (
      !['Root', 'Onboarding', 'Landing', 'NotFound'].includes(routes[0].name)
    ) {
      routes[0] = {
        name: 'Root',
        path: '',
        key: '',
        state: { routes: routes.slice() as any },
      };
    }

    // When the screen is not found, it should not redirect to it after login.
    if (routes[0].name === 'NotFound') {
      return '/';
    }

    return getPathFromState({ routes: routes as any }, linking.config);
  });
  const sourcePath = route.path || path;

  const { jumpTo: replace } =
    useNavigation<MaterialBottomTabNavigationProp<BaseParamList>>();

  useEffect(() => {
    if (!focused || !hydrated) {
      return;
    }
    let mounted = true;

    redirect(sourcePath)
      .catch(() => {})
      .then(() => {
        if (!mounted) {
          return;
        }

        replace('Landing', { section: 'login' });
      });

    return () => {
      mounted = false;
    };
  }, [redirect, replace, sourcePath, focused, hydrated]);

  return null;
}
