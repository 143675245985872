import { motion } from 'framer-motion';
import { StyleSheet } from 'react-native';

export function AnimatedRightSide({
  children,
  active,
  backgroundColor,
}: {
  children: React.ReactNode;
  active: boolean;
  backgroundColor: string;
}) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: active ? 1 : 0 }}
      transition={{
        type: 'spring',
        damping: 40,
        mass: 0.1,
        stiffness: 500,
      }}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor,
        ...StyleSheet.absoluteFillObject,
        pointerEvents: active ? 'auto' : 'none',
      }}
    >
      {children}
    </motion.div>
  );
}
