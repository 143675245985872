import { useMemo } from 'react';

const HEX_TEST = /^#([\da-f]{3}){1,2}$|^#([\da-f]{4}){1,2}$/i;
const RGB_TEST =
  /(rgb)a?\((\s*\d+%?\s*?,?\s*){2}(\s*\d+%?\s*?,?\s*\)?)(\s*,?\s*\/?\s*(0?\.?\d+%?\s*)?|1|0)?\)$/i;

const optionalCommaOrRequiredSpace = `((\\s*,\\s*)|(\\s+))`;
const optionalDecimals = `(\\.\\d+)?`;
const hundredPercent = `(([0-9]|[1-9][0-9]|100)%)`;
const degRegex = `(-?([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-9][0-9]|3[0-5][0-9]|360)(deg)?)`;
const graRegex = `(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-9][0-9]|3[0-9][0-9]|400)gra)`;
const radRegex = `((([0-5])?\\.\\d+|6\\.([0-9]|1[0-9]|2[0-8])|[0-6])rad)`;
const turnRegex = `((0?${optionalDecimals}|1)turn)`;
const regexLogic = `(hsl)a?\\((\\s*?(${degRegex}|${graRegex}|${radRegex}|${turnRegex})${optionalCommaOrRequiredSpace})(\\s*?(0|${hundredPercent})${optionalCommaOrRequiredSpace})(\\s*?(0|${hundredPercent})\\s*?\\)?)(\\s*?(\\/?|,?)\\s*?(((${hundredPercent}))|(0?${optionalDecimals})|1))?\\)$`;
const HSL_REGEX = new RegExp(regexLogic);

export function useValidColor(color: string | undefined, fallback: string) {
  return useMemo(() => {
    if (!color) {
      if (fallback[0] === '#') {
        return fallback; // hexAToHSLA(fallback);
      }

      return fallback;
    }

    if (HEX_TEST.test(color)) {
      return color; // hexAToHSLA(color);
    }

    if (RGB_TEST.test(color)) {
      return color;
    }

    if (HSL_REGEX.test(color)) {
      return color;
    }

    if (fallback[0] === '#') {
      return fallback; // hexAToHSLA(fallback);
    }

    return fallback;
  }, [color, fallback]);
}

function hexAToHSLA(H: string) {
  let r = 0,
    g = 0,
    b = 0,
    a = 1;

  switch (H.length) {
    case 4: {
      r = Number('0x' + H[1] + H[1]);
      g = Number('0x' + H[2] + H[2]);
      b = Number('0x' + H[3] + H[3]);
      break;
    }
    case 5: {
      r = Number('0x' + H[1] + H[1]);
      g = Number('0x' + H[2] + H[2]);
      b = Number('0x' + H[3] + H[3]);
      a = Number('0x' + H[4] + H[4]) / 255;
      break;
    }
    case 7: {
      r = Number('0x' + H[1] + H[2]);
      g = Number('0x' + H[3] + H[4]);
      b = Number('0x' + H[5] + H[6]);
      break;
    }
    case 9: {
      r = Number('0x' + H[1] + H[2]);
      g = Number('0x' + H[3] + H[4]);
      b = Number('0x' + H[5] + H[6]);
      a = Number('0x' + H[7] + H[8]) / 255;
      break;
    }
  }

  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;

  const cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin;

  let h = 0,
    s = 0,
    l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  a = Number(a.toFixed(3));

  return 'hsla(' + h + ',' + s + '%,' + l + '%,' + a + ')';
}
