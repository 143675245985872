import { RouteProp, useRoute } from '@react-navigation/native';
import { AnimatePresence } from 'moti';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Dimensions,
  Image,
  ImageSourcePropType,
  LayoutChangeEvent,
  PixelRatio,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {
  ActivityIndicator,
  Caption,
  Divider,
  List,
  Paragraph,
  Text,
  Title,
} from 'react-native-paper';
import Animated, {
  Easing,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import Svg, { Path } from 'react-native-svg';
import { useQueryClient } from 'react-query';
import {
  RemoteDiscoveryVoteProfile,
  useProfile,
} from '../account/useUserProfile';
import { BackHeader } from '../components/BackHeader';
import { PrimaryButton, RoundButton } from '../components/Button';
import { Presence } from '../components/Presence';
import { resetToPath } from '../navigation/utils';
import { PASTEL_PINK, PRIMARY_DARK, PRIMARY_LIGHT } from '../theming';
import { RootStackParamList } from '../types';
import { useThemedColor } from '../utils/useThemedColor';

export function TastingProfileScreen() {
  const hasProfile = useRef(false);
  const { initial } = useRoute<
    RouteProp<RootStackParamList, 'TastingProfile'>
  >().params || {
    initial: 'no',
  };

  const { data: profile, isLoading } = useProfile({
    refetchInterval: hasProfile.current ? false : 500,
  });

  const submit = useCallback(() => {
    resetToPath('/discover');
  }, []);

  const isLoadingForReal =
    profile?.discovery_vote_profile?.danceable === undefined;

  useEffect(() => {
    if (!isLoadingForReal) {
      hasProfile.current = true;
    }
  }, [isLoadingForReal, hasProfile]);

  return (
    <SafeAreaView
      style={{
        width: '100%',
        maxWidth: 600,
        overflow: 'hidden',
        height: '100%',
        maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        alignSelf: 'center',

        paddingTop: initial === 'yes' ? 32 : 96,
      }}
    >
      <View
        style={{
          minHeight: 400,
          maxHeight: 1200,
          width: '100%',
          maxWidth: '100%',
          justifyContent: 'space-evenly',
          flex: 1,
        }}
      >
        {isLoadingForReal ? (
          <Fragment>
            <ActivityIndicator
              size="large"
              style={{ alignSelf: 'center', margin: 'auto' }}
            />
            <Caption
              style={{ alignSelf: 'center', marginTop: 4, marginBottom: 32 }}
            >
              Calculating music taste...
            </Caption>
          </Fragment>
        ) : (
          <Tastes profile={profile!.discovery_vote_profile!} />
        )}

        {initial === 'yes' ? (
          <View
            style={{
              alignItems: 'center',
              padding: 16,
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <PrimaryButton
              label={isLoadingForReal ? 'Skip' : 'Start'}
              onPress={submit}
              disabled={isLoading}
            />
          </View>
        ) : /* todo: share */ null}
      </View>

      {initial === 'yes' ? null : (
        <View
          style={{
            position: 'absolute',
            maxWidth: 600,
            alignSelf: 'center',
            width: '100%',
            top: 0,
          }}
        >
          <BackHeader />
        </View>
      )}
    </SafeAreaView>
  );
}

function Tastes({ profile }: { profile: RemoteDiscoveryVoteProfile }) {
  const scrollRef = useRef<ScrollView | null>(null);
  const supportedKeys = useMemo(
    () =>
      (Object.keys(profile) as (keyof RemoteDiscoveryVoteProfile)[]).filter(
        isSupported
      ),
    [profile]
  );

  const query = useQueryClient();

  useEffect(() => {
    query.removeQueries(['tracks']);
    query.invalidateQueries(['tracks']);
  }, [query]);

  const [activeIndex, setActiveIndex] = useState(() => {
    const values = supportedKeys.map((key) => profile[key]);
    const max = Math.max(...values);
    return values.indexOf(max);
  });

  const [width, setWidth] = useState(() =>
    Math.min(800, Dimensions.get('window').width)
  );
  const determineWidth = useCallback(
    (event: LayoutChangeEvent) => {
      setWidth(event.nativeEvent.layout.width);
    },
    [setWidth]
  );

  return (
    <View
      style={{
        width: '100%',
        maxWidth: '100%',
        flex: 1,
      }}
    >
      <Title
        style={{
          color: useThemedColor(PRIMARY_DARK, PASTEL_PINK),
          fontSize: 30,
          letterSpacing: 30 / 20,
          lineHeight: 34,
          maxWidth: 250,
          marginHorizontal: 38,
          marginBottom: 40,
        }}
      >
        My Music Taste
      </Title>

      <ScrollView
        ref={scrollRef}
        horizontal
        style={{ maxHeight: 100, marginBottom: 0, width: '100%' }}
        contentContainerStyle={{
          paddingHorizontal: 38,
        }}
      >
        {supportedKeys.map((key: SupportedVoteProfileKey, index) => (
          <TastePercentage
            key={key}
            value={profile[key]}
            current={activeIndex === index}
            onPress={() => setActiveIndex(index)}
          />
        ))}
      </ScrollView>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingTop: 16,
          paddingBottom: 80,
          maxWidth: 800,
          width: '100%',
        }}
      >
        <View
          style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}
          onLayout={determineWidth}
        >
          <TasteDescription
            activeIndex={activeIndex}
            width={width}
            supportedKeys={supportedKeys}
          />
          <TasteTracks taste={supportedKeys[activeIndex]} />
        </View>
      </ScrollView>
    </View>
  );
}

function TastePercentage({
  current,
  onPress,
  value,
}: {
  current: boolean;
  value: number;
  onPress: () => void;
}) {
  const inactiveColor = useThemedColor('#fff', PRIMARY_LIGHT);
  const inactiveTextColor = useThemedColor(`${PRIMARY_DARK}7F`, '#ffffff');
  const inactiveBackgroundColor = useThemedColor('#fff', PRIMARY_LIGHT);

  const activeBackgroundColor = useThemedColor(PRIMARY_DARK, PRIMARY_DARK);

  return (
    <RoundButton
      mode="outlined"
      accessibilityState={{ expanded: current ? true : undefined }}
      color={current ? PRIMARY_DARK : inactiveColor}
      textColor={current ? '#fff' : inactiveTextColor}
      style={{
        backgroundColor: current
          ? activeBackgroundColor
          : inactiveBackgroundColor,
        marginHorizontal: 4,
      }}
      onPress={onPress}
      label={`${(value * 100).toFixed(0)}%`}
      labelStyle={{
        padding: 0,
        width: '100%',
        margin: 0,
      }}
      size={74}
    />
  );
}

function TasteDescription({
  activeIndex,
  width,
  supportedKeys,
}: {
  activeIndex: number;
  width: number;
  supportedKeys: SupportedVoteProfileKey[];
}) {
  const titleColor = useThemedColor(PRIMARY_DARK, '#8D6ABD');
  const paragraphColor = useThemedColor(`${PRIMARY_DARK}7F`, '#FFFFFFAB');

  const animation = useDerivedValue(() => {
    return withTiming(activeIndex, {
      duration: 460,
      easing: Easing.inOut(Easing.cubic),
    });
  }, [activeIndex]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: -1 * animation.value * width }],
    };
  }, [animation, width]);

  return (
    <View
      style={{
        width,
        flex: 1,
        minHeight: width / 10 + 23,
      }}
    >
      <Animated.View
        style={[
          {
            minHeight: '100%',
            width: supportedKeys.length * width,
          },
          animatedStyle,
        ]}
      >
        <View
          style={{
            flexDirection: 'row',
            minHeight: '100%',
            width: supportedKeys.length * width,
          }}
        >
          {supportedKeys.map((taste, index) => (
            <View
              style={{ width, minHeight: '100%' }}
              key={taste}
              accessibilityElementsHidden={activeIndex !== index}
              {...Platform.select({
                web: { 'aria-hidden': String(activeIndex !== index) },
                default: {},
              })}
            >
              <Title
                style={{
                  fontSize: 20,
                  lineHeight: 23,
                  color: titleColor,
                  marginHorizontal: 38,
                }}
              >
                {CONTENT[taste]?.title}
              </Title>
              <Paragraph
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: paragraphColor,
                  marginHorizontal: 38,
                }}
              >
                {CONTENT[taste]?.content}
              </Paragraph>
            </View>
          ))}
        </View>
      </Animated.View>
    </View>
  );
}

function TasteTracks({ taste }: { taste: SupportedVoteProfileKey }) {
  const tracks = CONTENT[taste].samples as TrackData[];

  return (
    <View style={{ width: '100%', marginTop: 32 }}>
      <List.Subheader style={{ paddingHorizontal: 38 }}>
        Sample tracks
      </List.Subheader>
      {tracks.map((track, index, self) => (
        <Fragment key={track.name}>
          <AnimatePresence>
            <Presence delay={index * 50}>
              <SampleTrack track={track} key={track.name} />
            </Presence>
          </AnimatePresence>
          {index !== self.length - 1 ? (
            <Divider
              style={{
                height: PixelRatio.roundToNearestPixel(
                  StyleSheet.hairlineWidth
                ),
              }}
            />
          ) : null}
        </Fragment>
      ))}
    </View>
  );
}

function SampleTrack({ track }: { track: TrackData }) {
  const titleColor = useThemedColor(PRIMARY_DARK, '#8D6ABD');
  const captionColor = useThemedColor(`${PRIMARY_DARK}7F`, '#FFFFFFAB');

  return (
    <View
      style={{
        flexDirection: 'row',
        paddingHorizontal: 38,
        paddingVertical: 16,
      }}
    >
      <Image
        source={track.image}
        style={{
          width: 52,
          height: 52,
          borderRadius: 5,
          backgroundColor: PRIMARY_DARK,
        }}
        accessibilityLabel={`Cover for ${track.name} by ${track.artist}`}
      />
      <View style={{ marginLeft: 16, justifyContent: 'center', flex: 1 }}>
        <Title
          style={{
            fontSize: 14,
            color: titleColor,
            includeFontPadding: false,
            textAlignVertical: 'center',
            lineHeight: 17,
            margin: 0,
            marginTop: 4,
          }}
        >
          {track.name}
        </Title>
        <Caption
          style={{
            fontWeight: 'normal',
            fontSize: 14,
            lineHeight: 17,
            color: captionColor,
            includeFontPadding: false,
            textAlignVertical: 'center',
            margin: 0,
          }}
        >
          {track.artist}
        </Caption>
      </View>
      <View style={{ justifyContent: 'center' }}>
        <View
          style={{
            flexDirection: 'row',
            marginLeft: 16,
            alignItems: 'center',
            minHeight: 18,
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: `${PRIMARY_DARK}1A`,
            borderRadius: 40,
            paddingHorizontal: 4,
            width: 54,
          }}
        >
          <Svg
            viewBox="0 0 11 10"
            style={{ width: 11, height: 10, marginRight: 4 }}
          >
            <Path
              fill="#ffbe16"
              d="M5.5,0,7.15,3.357,11,3.82,8.17,6.357,8.9,10,5.5,8.211,2.1,10,2.83,6.357,0,3.82l3.85-.463Z"
            />
          </Svg>

          <Text
            style={{
              fontSize: 12,
              color: titleColor,
              display: 'flex',
            }}
          >
            {track.rating.toFixed(2)}
          </Text>
        </View>
      </View>
    </View>
  );
}

type TrackData = {
  image: ImageSourcePropType;
  name: string;
  artist: string;
  rating: number;
};

const CONTENT = {
  danceable: {
    title: 'Danceable',
    content:
      'If you could eat music for breakfast, you would. You are always on the lookout for new music, or push the repeat button on your favorite song.',
    samples: [
      {
        image: require('../../assets/taste/danceable/track-gecko.png'),
        name: 'Gecko',
        artist: 'Oliver Heldens',
        rating: 4.38,
      },
      {
        image: require('../../assets/taste/danceable/track-krantenwijk.png'),
        name: 'Krantenwijk',
        artist: 'Lil Kleine',
        rating: 4.17,
      },
      {
        image: require('../../assets/taste/danceable/track-suavemente.png'),
        name: 'Suavemente',
        artist: 'Elvis Crespo',
        rating: 4.01,
      },
      {
        image: require('../../assets/taste/danceable/track-love-no-more.png'),
        name: 'Love no more',
        artist: 'Loud Luxury Anders',
        rating: 3.81,
      },
    ] as TrackData[],
  },
  faithful: {
    title: 'Familiar',
    content:
      'You love to listen to music in Dutch language or German Schlagers, Country and sing-along-oldies. You like it when that one song you know hits the radio.',
    samples: [
      {
        image: require('../../assets/taste/familiar/track-zoutelande.png'),
        name: 'Zoutelande',
        artist: 'Bløf',
        rating: 4.63,
      },
      {
        image: require('../../assets/taste/familiar/track-kleine-jongen.png'),
        name: 'Kleine Jongen',
        artist: 'Andre Hazes',
        rating: 4.47,
      },
      {
        image: require('../../assets/taste/familiar/track-heb-je-even-voor-mij.png'),
        name: 'Heb je even voor mij?',
        artist: 'Frans Bauer',
        rating: 4.29,
      },
      {
        image: require('../../assets/taste/familiar/track-lief-zoals-je-bent.png'),
        name: 'Lief zoals je bent',
        artist: 'Maan',
        rating: 3.17,
      },
    ] as TrackData[],
  },
  intense: {
    title: 'Intense',
    content:
      'Music is not meant to be played in backgrounds. You prefer your music performed live or at least played on live volume. You keep an eye out for anything new and you love to scout new artists',
    samples: [
      {
        image: require('../../assets/taste/intense/track-raining-blood.png'),
        name: 'Raining Blood',
        artist: 'Slayer',
        rating: 3.9,
      },
      {
        image: require('../../assets/taste/intense/track-tool.png'),
        name: 'Tool',
        artist: 'Stinkfest',
        rating: 3.78,
      },
      {
        image: require('../../assets/taste/intense/track-cell-zero.png'),
        name: 'Cell Zero',
        artist: 'Enemies',
        rating: 3.78,
      },
      {
        image: require('../../assets/taste/intense/track-tree-fear-of-a-blank-planet.png'),
        name: 'Tree fear of a Blank Planet',
        artist: 'Porcupine',
        rating: 3.61,
      },
    ],
  },
  multiple: {
    title: 'Eclectic',
    content:
      'You are being the jack-of-all-trades, you find it hard to choose between Mozart and Lil’Kleine and why should you. You know your music and actively search online for new artists and albums.',
    samples: [
      {
        image: require('../../assets/taste/eclectic/track-zoutelande.png'),
        name: 'Zoutelande',
        artist: 'Bløf',
        rating: 4.02,
      },
      {
        image: require('../../assets/taste/eclectic/track-suavemente.png'),
        name: 'Suavemente',
        artist: 'Elvis Crespo',
        rating: 3.91,
      },
      {
        image: require('../../assets/taste/eclectic/track-gecko.png'),
        name: 'Gecko',
        artist: 'Oliver Heldens',
        rating: 3.91,
      },
      {
        image: require('../../assets/taste/eclectic/track-in-your-eyes.png'),
        name: 'In Your Eyes',
        artist: 'BadBadNotGood',
        rating: 3.53,
      },
    ] as TrackData[],
  },
  relaxed: {
    title: 'Laid Back',
    content:
      "Be it pop, rock, country or folk, you can spend hours listening to your favorite singer song-writer. It doesn't matter whereas it is at home, in the car, or at work, there's always something playing in the background.",
    samples: [
      {
        image: require('../../assets/taste/laid-back/track-train-in-the-distance.png'),
        name: 'Train in the Distance',
        artist: 'Paul Simon',
        rating: 4.04,
      },
      {
        image: require('../../assets/taste/laid-back/track-sea-of-heart-break.png'),
        name: 'Sea of Heart-break',
        artist: 'Johnny Cash',
        rating: 3.86,
      },
      {
        image: require('../../assets/taste/laid-back/track-firecracker.png'),
        name: 'Firecracker',
        artist: 'Ryan Adams',
        rating: 3.79,
      },
      {
        image: require('../../assets/taste/laid-back/track-should-have-known-better.png'),
        name: 'Should have known better',
        artist: 'Sufjan Stevens',
        rating: 3.91,
      },
    ] as TrackData[],
  },
  traditional: {
    title: 'Traditional',
    content:
      'Classical composers are what is appreciating for you. It can sometimes range as far as Modern Classical or even a jazzy song but always with a classical touch.',
    samples: [
      {
        image: require('../../assets/taste/traditional/track-mondscheinsonate-adagio-sostenuto.png'),
        name: 'Mondscheinsonate Adagio Sostenuto',
        artist: 'Beethoven',
        rating: 4.87,
      },
      {
        image: require('../../assets/taste/traditional/track-suites-for-keyboard.png'),
        name: 'Suites for keyboard',
        artist: 'GfHaendel',
        rating: 4.58,
      },
      {
        image: require('../../assets/taste/traditional/track-concierto-de-aranjuez.png'),
        name: 'Concierto de Aranjuez',
        artist: 'Rodrigo',
        rating: 4.09,
      },
      {
        image: require('../../assets/taste/traditional/track-zauberflaute.png'),
        name: 'Zauberflaute',
        artist: 'Mozart',
        rating: 3.3,
      },
    ] as TrackData[],
  },
} as const;

type SupportedVoteProfileKey = keyof typeof CONTENT;

function isSupported(
  key: keyof RemoteDiscoveryVoteProfile
): key is SupportedVoteProfileKey {
  return CONTENT.hasOwnProperty(key);
}
