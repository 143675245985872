import Color from 'color';
import React, { useMemo } from 'react';
import { useTheme } from 'react-native-paper';
import Svg, { Line } from 'react-native-svg';

export function Background({
  width,
  height,
  transparentTheme,
}: {
  width: number;
  height: number;
  transparentTheme?: boolean;
}) {
  const {
    colors: { surface },
  } = useTheme();

  const stroke = useMemo(() => {
    if (transparentTheme) {
      return '#FFFFFF4D';
    }

    const color = new Color(surface);
    return color.isDark()
      ? color.lighten(0.7).alpha(0.54).toString()
      : color.darken(0.4).alpha(0.54).toString();
  }, [surface, transparentTheme]);

  return (
    <Svg
      style={{ width, height, position: 'absolute' }}
      viewBox="0 0 375 812"
      preserveAspectRatio="none"
      pointerEvents="none"
    >
      <Line
        y1={42.5 + 162}
        y2={42.5}
        x1={87.5}
        x2={87.5 + 113}
        strokeWidth={1}
        stroke={stroke}
      />

      <Line
        y1={175.5 + 94}
        y2={175.5}
        x1={178.5}
        x2={178.5 + 170}
        strokeWidth={1}
        stroke={stroke}
      />

      <Line
        y1={376.5}
        y2={376.5}
        x1={214}
        x2={214 + 134}
        strokeWidth={1}
        stroke={stroke}
      />

      <Line
        y1={479.5}
        y2={479.5 + 94}
        x1={178.5}
        x2={178.5 + 170}
        strokeWidth={1}
        stroke={stroke}
      />

      <Line
        y1={544.5}
        y2={661.5}
        x1={87.5}
        x2={162.5}
        strokeWidth={1}
        stroke={stroke}
      />
    </Svg>
  );
}
