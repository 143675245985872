import { DISCOVERY_EVENT_ID, SPECIAL_GPVNL_EVENTS } from '../config';
import { ApiEvent } from './useEvent';

export function isDiscoveryEvent(
  data: { event: { _links: { self: { href: string } } } } | null | undefined
) {
  if (!data) {
    return false;
  }

  const {
    event: {
      _links: {
        self: { href },
      },
    },
  } = data;

  return href.endsWith(DISCOVERY_EVENT_ID);
}

export function isGrotePrijsEvent(
  data: { event: { _links: { self: { href: string } } } } | null | undefined
) {
  if (!data) {
    return false;
  }

  const {
    event: {
      _links: {
        self: { href },
      },
    },
  } = data;

  return SPECIAL_GPVNL_EVENTS.some((id) => href.endsWith(id));
}
