import { PixelRatio } from 'react-native';

export const IMAGE_PIXEL_RATIO = Math.min(
  2,
  Math.min(4, Math.round(PixelRatio.get()))
);

export function variantImageUrl(
  src: string | null | undefined,
  prefix: 'thumbnail' | 'size' = 'thumbnail'
) {
  return src
    ? src.replace(
        /(%7[Bb]|{)variant(%7[Dd]|})/,
        `${prefix}_${IMAGE_PIXEL_RATIO}x`
      )
    : undefined;
}
