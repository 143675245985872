import * as WebBrowser from 'expo-web-browser';
import { AnimatePresence } from 'moti';
import React, { Fragment } from 'react';
import { View } from 'react-native';
import { HelperText } from 'react-native-paper';
import { Presence } from '../components/Presence';

import { Apple } from './logins/Apple';
import { Facebook } from './logins/Facebook';
import { Google } from './logins/Google';
import { Spotify } from './logins/Spotify';
import { useExternalAuthenticate } from './useAuthentication';

WebBrowser.maybeCompleteAuthSession();

export function SocialLogin() {
  const { isLoading, error, mutateAsync } = useExternalAuthenticate();

  return (
    <Fragment>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 8,
        }}
      >
        <AnimatePresence>
          <Presence key="spotify" delay={120}>
            <Spotify isLoading={isLoading} mutateAsync={mutateAsync} />
          </Presence>
          <Presence key="facebook" delay={140}>
            <Facebook isLoading={isLoading} mutateAsync={mutateAsync} />
          </Presence>
          <Presence key="apple" delay={160}>
            <Apple isLoading={isLoading} mutateAsync={mutateAsync} />
          </Presence>
          <Presence key="google" delay={180}>
            <Google isLoading={isLoading} mutateAsync={mutateAsync} />
          </Presence>
        </AnimatePresence>
      </View>
      <AnimatePresence exitBeforeEnter>
        {error ? (
          <Presence>
            <HelperText type="error">{error.message}</HelperText>
          </Presence>
        ) : (
          <HelperText type="error">&nbsp;</HelperText>
        )}
      </AnimatePresence>
    </Fragment>
  );
}
