import { View, ScrollView, Platform } from 'react-native';
type Props = React.ComponentProps<typeof ScrollView> & {
  useWindowScrolling?: boolean;
};

export function ScreenScrollView({
  style,
  useWindowScrolling = true, // defaults to true
  ...props
}: Props) {
  const Component = Platform.select({
    web: useWindowScrolling
      ? (View as unknown as typeof ScrollView)
      : ScrollView,
    default: ScrollView,
  });
  return (
    <Component
      {...props}
      style={Platform.select({
        web: useWindowScrolling ? undefined : style,
        default: style,
      })}
    />
  );
}
