import { EasingFunction, motion, MotionProps } from 'framer-motion';
import { ComponentProps, useReducer } from 'react';

export function AnimatedIcon({
  animateOpacity,
  animateY,
  animateScale,
  delay,
  easing,
  overshootClamping,
  style,
  ...props
}: Omit<MotionProps, 'from' | 'animate' | 'delay'> & {
  animateOpacity?: boolean;
  animateY?: number;
  animateScale?: number;
  delay?: number;
  easing?: EasingFunction;
  overshootClamping?: boolean;
}) {
  return (
    <motion.div
      {...props}
      initial={{
        ...(animateOpacity ? { opacity: 0 } : {}),
        ...(animateY ? { translateY: animateY } : {}),
        ...(animateScale ? { scale: animateScale } : {}),
      }}
      animate={{ opacity: 1, translateY: 0, scale: 1 }}
      style={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
      transition={{
        delay: (delay ?? 0) / 1000,
        easing,
      }}
    />
  );
}
