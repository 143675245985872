import React from 'react';
import { Platform, View } from 'react-native';

export function FormWrapper({ children }: { children: React.ReactNode }) {
  if (Platform.OS === 'web') {
    return (
      <form
        style={{
          marginLeft: 38,
          marginRight: 38,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        {children}
      </form>
    );
  }

  return (
    <View
      style={{
        marginHorizontal: 38,
        marginVertical: 20,
      }}
    >
      {children}
    </View>
  );
}
