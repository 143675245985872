import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { useProfile } from '../account/useUserProfile';
import { isAtLeastV2, useConfiguration } from '../hooks/useConfiguration';
import { useLocale } from '../hooks/useLocale';
import { useToken } from '../hooks/useToken';
import { authorization } from '../utils/authorization';

export type SoundersProfileTagsCollection = {
  _links: {
    self: { href: string };
  };
  _embedded: readonly { tag: SoundersProfileTag }[];
};

export type SoundersProfileTag = {
  _links: {
    self: { href: string; slug: string };
  };
  name: string;
};

export function useUserProfileTags({
  enabled = true,
  onError,
  ...options
}: UseQueryOptions<
  SoundersProfileTagsCollection,
  FetchMediaError | Error
> = {}) {
  const { data: profile } = useProfile();
  const { ref, token, logout } = useToken();
  const locale = useLocale();

  const url = profile?._links.profile_tags.href;
  return useQuery<SoundersProfileTagsCollection, FetchMediaError | Error>(
    [locale, url] as QueryKey,
    async ({ signal }) => {
      if (!ref.current) {
        throw new Error('Expected logged in, actual: no token');
      }

      const response = await fetchMedia(url!, {
        headers: {
          accept: [
            'application/vnd.soundersmusic.profile-tag.v1.collection+json',
          ].join(', '),
          acceptLanguage: locale,
          authorization: authorization(ref.current)!,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
      });

      if (typeof response === 'string' || !response) {
        throw new Error(`Expected profile tags, but got a ${typeof response}`);
      }

      if (!Object.prototype.hasOwnProperty.call(response, 'tags')) {
        throw new Error(
          `Expected profile tags, but got ${Object.keys(response).join(', ')}`
        );
      }

      return (response as { tags: SoundersProfileTagsCollection }).tags;
    },
    {
      enabled: Boolean(url && token && enabled),

      onError(error) {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            logout();
          }
        }

        if (onError) {
          onError(error);
        }
      },

      ...options,
    }
  );
}

export function usePublicProfileTags({
  enabled = true,
  ...options
}: UseQueryOptions<
  SoundersProfileTagsCollection,
  FetchMediaError | Error
> = {}) {
  const { data: configuration } = useConfiguration();
  const url = isAtLeastV2(configuration)
    ? configuration._links.profile_tags.href
    : undefined;
  const locale = useLocale();

  return useQuery<SoundersProfileTagsCollection, FetchMediaError | Error>(
    [locale, url] as QueryKey,
    async ({ signal }) => {
      const response = await fetchMedia(url!, {
        headers: {
          accept: [
            'application/vnd.soundersmusic.profile-tag.v1.collection+json',
          ].join(', '),
          acceptLanguage: locale,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
      });

      if (typeof response === 'string' || !response) {
        throw new Error(`Expected profile tags, but got a ${typeof response}`);
      }

      if (!Object.prototype.hasOwnProperty.call(response, 'tags')) {
        throw new Error(
          `Expected profile tags, but got ${Object.keys(response).join(', ')}`
        );
      }

      return (response as { tags: SoundersProfileTagsCollection }).tags;
    },
    {
      enabled: Boolean(url && enabled),
      ...options,
    }
  );
}
