import { ImageInfo, ImagePickerResult } from 'expo-image-picker';
import { useCallback, useMemo, useState } from 'react';
import { useIsMounted } from 'use-is-mounted';
import { usePickImageFromLibrary } from '../hooks/usePickMedia';

export function usePickedImage(updateImage: (next: FormData) => void) {
  const [pickedImage, setPickedImage] = useState<ImageInfo | undefined>();
  const isMounted = useIsMounted();

  const onPickedImage = useCallback((result: ImagePickerResult) => {
    if (result.canceled || !result.assets[0]) {
      return;
    }

    const picked = result.assets[0];
    setPickedImage(picked);

    // Make formData
    const uriParts = picked.uri.split('.');
    const fileType = uriParts[uriParts.length - 1];

    const formData = new FormData();

    // Add the file to the form data
    const promise = picked.uri.startsWith('data:')
      ? fetch(picked.uri)
          .then((res) => res.blob())
          .then((blob) => formData.append('file', blob))
      : Promise.resolve().then(() =>
          formData.append('file', {
            uri: picked.uri,
            name: `file.${fileType}`,
            type: `image/${fileType}`,
          } as any)
        );

    // no-op
    return promise
      .then(() => isMounted.current && updateImage(formData))
      .catch(() => {});
  }, []);

  const openFilePicker = usePickImageFromLibrary(onPickedImage);

  return useMemo(() => {
    return [pickedImage, openFilePicker] as const;
  }, [pickedImage, openFilePicker]);
}
