import { useIsFocused, useRoute } from '@react-navigation/native';
import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { View } from 'react-native';
import { ActivityIndicator, HelperText } from 'react-native-paper';
import { useQueryClient } from 'react-query';
import { ErrorScreen } from '../components/ErrorScreen';
import { LoadingScreen } from '../components/LoadingScreen';
import { DISCOVERY_EVENT_ID } from '../config';
import { ApiEvent, useEvent } from '../events/useEvent';
import { useEvents } from '../events/useEvents';
import { useVoteExperience } from '../events/useVoteExperience';
import { usePrivateConfiguration } from '../hooks/usePrivateConfiguration';
import { i18n } from '../locale';
import { prepareUrl } from '../navigation/LinkingConfiguration';
import { resetToPath } from '../navigation/utils';
import { MediaPlayer } from '../player/MediaPlayer';
import { ResponsiveSwipeExperience } from '../swipe/SwipeExperience';

export function ProfilerScreen() {
  const focused = useIsFocused();
  const {
    data: eventsData,
    error: eventsError,
    isLoading: isLoadingEvents,
    isFetched: isFetchedEvents,
  } = useEvents({ enabled: focused });

  const eventUrl = useMemo(
    () =>
      eventsData?.events._index.find((item) =>
        item.href.includes(`/${DISCOVERY_EVENT_ID ?? '__not-set'}`)
      ),
    [eventsData]
  );

  const {
    data: eventData,
    error: eventError,
    isLoading: isLoadingEvent,
    isFetched: isFetchedEvent,
  } = useEvent(eventUrl?.href, {
    enabled: focused,
  });

  const isLoading = isLoadingEvent || isLoadingEvents;
  const isFetched = (isFetchedEvent || eventsError) && isFetchedEvents;
  const error = eventError || eventsError;

  const playerRef = useRef<MediaPlayer | null>(null);

  if (!eventData) {
    if (isLoading || !isFetched) {
      return <LoadingScreen />;
    }

    if (error) {
      return <ErrorScreen error={error} />;
    }

    return (
      <ErrorScreen error={new Error('Expected an event, but got nothing')} />
    );
  }

  return (
    <MediaPlayer ref={playerRef}>
      <SamplerVoteExperience event={eventData.event} playerRef={playerRef} />
    </MediaPlayer>
  );
}

function SamplerVoteExperience({
  event,
  playerRef,
}: ApiEvent & { playerRef: RefObject<MediaPlayer | null> }) {
  const queryClient = useQueryClient();
  const { cursor } = (useRoute().params as any) || { cursor: undefined };

  const onVerificationRequired = useCallback(() => {
    resetToPath(
      [
        `/verifications/${prepareUrl(event._links.self.href)}`,
        cursor ? `cursor=${encodeURIComponent(cursor)}` : undefined,
      ]
        .filter(Boolean)
        .join('?')
    );
  }, []);

  const onExplanationRequired = useCallback(() => {
    resetToPath('/getting-started/how-it-works');
  }, []);

  const { track, loading, hrefs, error, done, ...experience } =
    useVoteExperience(
      event._links.tracks.href,
      playerRef,
      true,
      onVerificationRequired,
      onExplanationRequired
    );

  const { data } = usePrivateConfiguration();
  const url = data?.configuration._links.my_profile.href;

  useEffect(() => {
    if (!done) {
      return;
    }

    queryClient.cancelQueries(['tracks', i18n.locale, event._links.self.href]);
    queryClient.invalidateQueries([
      'tracks',
      i18n.locale,
      event._links.self.href,
    ]);
    queryClient.invalidateQueries([i18n.locale, url]);

    setTimeout(() => resetToPath('/settings/profile?initial=yes'), 0);
  }, [done, event._links.self.href, event._links.self.title]);

  if (!track || !(hrefs.wave || hrefs.cover) || loading) {
    if (error) {
      return (
        <HelperText
          type="error"
          style={{ alignSelf: 'center', textAlign: 'center' }}
        >
          {error.message}
        </HelperText>
      );
    }

    return (
      <View
        style={{
          paddingTop: 32,
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <ActivityIndicator size="large" />
        {/*<HelperText type="info">
          loading: {String(loading)}, track: {String(!!track)}, wave:{' '}
          {String(!!waveHref)}
      </HelperText>*/}
      </View>
    );
  }

  return (
    <View
      style={{
        paddingTop: 32,
        maxWidth: 800,
        width: '100%',
        marginHorizontal: 'auto',
        alignSelf: 'center',
        position: 'relative',
      }}
    >
      <ResponsiveSwipeExperience
        track={track}
        {...experience}
        allowSeek={false}
        playerRef={playerRef}
      />
    </View>
  );
}
