import * as Updates from 'expo-updates';
import { MemoryValue, useMutableMemoryValue } from 'expo-use-memory-value';
import { useCallback, useEffect, useRef } from 'react';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { defineTranslations, i18n } from '../locale';
import { usePersistentSnackbar } from './SnackbarProvider';

const OTA_DEBUG = __DEV__ && false;
const LAST_UPDATE_CHECK = new MemoryValue<number>();

export const INTERVAL_OTA_CHECK = 1000 * 60 * 15;

defineTranslations({
  en: {
    app: {
      updates: {
        ready_message: 'An update has been downloaded and is ready to launch.',
      },
    },
  },

  nl: {
    app: {
      updates: {
        ready_message: 'Er staat een belangrijke update klaar voor deze app.',
      },
    },
  },
});

export function OtaUpdater() {
  const [lastUpdate, setLastUpdate] = useMutableMemoryValue(LAST_UPDATE_CHECK);
  const now = useInaccurateTimestamp({ every: 1000 * (__DEV__ ? 10 : 60) });
  const isMountedRef = useRef(true);
  const { show, hide } = usePersistentSnackbar('updater');

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, [isMountedRef]);

  useEffect(() => {
    // Updated recently
    if (lastUpdate !== undefined && now - lastUpdate < INTERVAL_OTA_CHECK) {
      const diff = (now - lastUpdate) / 1000;
      OTA_DEBUG &&
        console.debug(
          `[ota] skipping because last check was ${diff}s ago, need ${
            INTERVAL_OTA_CHECK / 1000
          }s`
        );
      return;
    }

    OTA_DEBUG && console.debug('[ota] attempting ota');

    setLastUpdate(now);

    Updates.checkForUpdateAsync()
      .then((value) => {
        if (!value.isAvailable) {
          return Promise.reject(new Error('No updates available'));
        }

        return Updates.fetchUpdateAsync();
      })
      .then((value) => {
        if (!value.isNew) {
          return Promise.reject(new Error('No new updates available'));
        }

        if (isMountedRef.current) {
          show(i18n.translate('app.updates.ready_message'), {
            type: 'info',
            onPress: restart,
            label: 'Restart',
          });
        }
      })
      .catch(() => {});
  }, [now, lastUpdate, show]);

  const restart = useCallback(() => {
    if (isMountedRef.current) {
      hide();
    }

    Updates.reloadAsync().catch(() => {});
  }, []);

  return null;
}
