import { RouteProp, useIsFocused, useRoute } from '@react-navigation/native';
import { fetchMediaWrapped } from 'fetch-media';
import { useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, View } from 'react-native';
import {
  Button,
  HelperText,
  Surface,
  Text,
  TextInput,
} from 'react-native-paper';
import { debug } from 'react-native-reanimated';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useMutation, useQueryClient } from 'react-query';
import { BackHeader } from '../components/BackHeader';
import { ScreenReaderHidden } from '../components/ScreenReaderHidden';
import { useSnackbar } from '../components/SnackbarProvider';
import { usePrivateConfiguration } from '../hooks/usePrivateConfiguration';
import { useToken } from '../hooks/useToken';
import { defineTranslations, i18n } from '../locale';
import { prepareUrl } from '../navigation/LinkingConfiguration';
import { resetToPath } from '../navigation/utils';
import { PASTEL_PINK, PRIMARY_DARK, PRIMARY_LIGHT } from '../theming';
import { RootStackParamList } from '../types';
import { authorization } from '../utils/authorization';
import { useThemedColor } from '../utils/useThemedColor';

defineTranslations({
  en: {
    app: {
      verification: {
        phone: {
          title: 'Phone verification',
          description:
            'In order to vote on these songs, you must go through the one-time process of verifying a Dutch mobile phone number. We take this measure against abuse and fraud. Please fill in your 06 number below and wait for the SMS.',
        },
        actions: {
          send: 'Send',
          resend: 'Resend',
          resend_wait: 'Resend ({{wait}}s)',
          entry: 'Confirm',
          change: 'Change number',
        },
      },

      fields: {
        phone: {
          label: 'Mobile (06) number',
          helper_text:
            'Enter your own 06 number in order to verify, without any spaces or hyphens.',
        },
        verification: {
          label: 'Verification code',
          helper_text: 'Enter the 8 digit code we sent you.',
        },
      },
    },
  },

  nl: {
    app: {
      verification: {
        phone: {
          title: 'Telefoon verificatie',
          description:
            'Om op deze nummers te kunnen stemmen moet je eenmalig een Nederlands mobiel nummer verifieren. Dit doen we om misbruik en fraude tegen te gaan. Vul je 06 nummer hier in en wacht op de SMS.',
        },
        actions: {
          send: 'Verstuur',
          resend: 'Nogmaals',
          resend_wait: 'Nogmaals ({{wait}}s)',
          entry: 'Bevestig',
          change: 'Verander nummer',
        },
      },

      fields: {
        phone: {
          label: 'Mobiel (06) nummer',
          helper_text:
            'Vul je eigen 06 nummer in ter verificatie, zonder spaties of streepjes.',
        },
        verification: {
          label: 'Verificatie code',
          helper_text: 'Vul de 8 cijferige code in die we je hebben gestuurd.',
        },
      },
    },
  },
});

export function PhoneVerificationScreen() {
  const { token } = useToken();
  const isFocused = useIsFocused();

  const { data } = usePrivateConfiguration({ enabled: isFocused });
  const { configuration } = data ?? {};
  const href = configuration?._links.my_phone_numbers?.href;

  const [nextRequestAt, setNextRequest] = useState<number | null>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [incorrectCodeDetected, setIncorrectCodeDetected] = useState(false);

  const timestamp = useInaccurateTimestamp({ every: 500 });
  const waiting =
    nextRequestAt === null
      ? 0
      : Math.max(0, (nextRequestAt - timestamp) / 1000);

  const enabled = Boolean(
    href && token && waiting === 0 && phoneNumber.length > 7
  );

  const { show } = useSnackbar();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (number: string) => {
      const result = await fetchMediaWrapped(href!, {
        method: 'POST',
        headers: {
          authorization: authorization(token)!,
          accept: '*/*',
          contentType:
            'application/vnd.soundersmusic.phone-number.v1.request+json',
        },
        body: {
          request: number,
        },
      });

      if (result.ok()) {
        return true;
      }

      switch (result.response.status) {
        case 400: {
          show('Dat is geen volledig 06 nummer');
          break;
        }
        case 422: {
          // too many numbers attached
          show(
            'Je hebt teveel verschillende nummers geprobeerd. Foutje? Stuur een bericht naar support@soundersmusic.com met meer informatie.'
          );
          break;
        }
        case 409: {
          // belongs to someone else
          show('Dit nummer is reeds door iemand anders gevalideerd.');
          break;
        }
        case 429: {
          // too_many_requests
          show('Je hebt recent al een verzoek gedaan. Even wachten!');
          break;
        }
      }
    },
    onSettled: () => {
      setIncorrectCodeDetected(false);
      setNextRequest(new Date().getTime() + 90 * 1000);
    },
  });

  const { url } =
    useRoute<RouteProp<RootStackParamList, 'Event'>>().params ?? {};
  const queryClient = useQueryClient();
  const { cursor } = (useRoute().params as any) || { cursor: undefined };

  const { mutateAsync: verifyAsync, isLoading: isVerifiying } = useMutation({
    mutationFn: async ({
      phoneNumber,
      verificationToken,
    }: {
      phoneNumber: string;
      verificationToken: string;
    }) => {
      const result = await fetchMediaWrapped(href!, {
        method: 'POST',
        headers: {
          authorization: authorization(token!),
          accept: '*/*',
          contentType:
            'application/vnd.soundersmusic.phone-number.v1.verification+json',
        },
        body: {
          verification: { phone_number: phoneNumber, token: verificationToken },
        },
      });

      if (result.ok()) {
        return true;
      }

      show(
        'Code incorrect. De code die gestuurd is is nu ook ongeldig. Vraag een nieuwe aan!'
      );

      result.unwrap();
    },
    onSuccess: () => {
      queryClient.invalidateQueries([i18n.locale, url]);
      queryClient.invalidateQueries([url]);
      queryClient.clear();

      const path = [
        `/events/${prepareUrl(url)}`,
        cursor ? `cursor=${encodeURIComponent(cursor)}` : undefined,
      ]
        .filter(Boolean)
        .join('?');

      resetToPath(path);
    },
    onError: () => {
      setIncorrectCodeDetected(true);
    },
    onSettled: () => {
      setCode('');
    },
  });

  const outlineColor = useThemedColor(PRIMARY_DARK, PASTEL_PINK);
  const inputColor = useThemedColor(PRIMARY_LIGHT, PASTEL_PINK);

  return (
    <View
      style={{
        width: '100%',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <ScrollView
        style={{
          flex: 1,
          width: '100%',
        }}
        contentContainerStyle={{
          padding: 8,
          paddingTop: 128,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        keyboardShouldPersistTaps="handled"
      >
        <View style={{ paddingHorizontal: 8 }}>
          <Surface
            style={{
              padding: 24,
              borderRadius: 12,
              maxWidth: 430,
            }}
          >
            <Text variant="headlineLarge" style={{ display: 'flex' }}>
              {i18n.translate('app.verification.phone.title')}
            </Text>
            <Text
              variant="bodyMedium"
              style={{ marginTop: 12, maxWidth: 300, display: 'flex' }}
            >
              {i18n.translate('app.verification.phone.description')}
            </Text>

            <View
              style={{
                position: 'relative',
                width: '100%',
                marginTop: 12,
              }}
            >
              <TextInput
                mode="flat"
                style={{
                  backgroundColor: 'transparent',
                  paddingHorizontal: 0,
                  textAlignVertical: 'center',
                  includeFontPadding: false,
                }}
                label={i18n.translate('app.fields.phone.label')}
                numberOfLines={1}
                keyboardType="phone-pad"
                returnKeyType="send"
                autoCapitalize="words"
                autoCorrect={false}
                autoComplete="tel"
                maxLength={13}
                textContentType="telephoneNumber"
                theme={{
                  colors: {
                    primary: useThemedColor(PRIMARY_LIGHT, PASTEL_PINK),
                  },
                }}
                accessibilityLabelledBy="phone-name-description"
                value={phoneNumber}
                onChangeText={setPhoneNumber}
                disabled={nextRequestAt !== null || isLoading}
                onSubmitEditing={() =>
                  enabled && mutateAsync(phoneNumber).catch(() => {})
                }
              />
              <ScreenReaderHidden
                webStyle={{ marginTop: 8 }}
                nativeStyle={{ marginTop: 8 }}
              >
                <HelperText
                  nativeID="phone-name-description"
                  type="info"
                  style={{ paddingHorizontal: 0 }}
                >
                  {i18n.translate('app.fields.phone.helper_text')}
                </HelperText>
              </ScreenReaderHidden>
            </View>

            <Button
              mode="contained"
              style={{ marginTop: 12 }}
              disabled={!enabled || isLoading}
              loading={isLoading}
              onPress={() => mutateAsync(phoneNumber).catch(() => {})}
              textColor="#FFF"
            >
              {nextRequestAt === null
                ? i18n.translate('app.verification.actions.send')
                : waiting > 0
                ? i18n.translate('app.verification.actions.resend_wait', {
                    wait: Math.round(waiting),
                  })
                : i18n.translate('app.verification.actions.resend')}
            </Button>

            {nextRequestAt === null ? null : (
              <Button
                mode="outlined"
                textColor={outlineColor}
                style={{ marginTop: 6 }}
                disabled={!enabled || isLoading}
                onPress={() => {
                  setPhoneNumber('');
                  setNextRequest(null);
                }}
              >
                {i18n.translate('app.verification.actions.change')}
              </Button>
            )}
          </Surface>
        </View>

        {nextRequestAt !== null && !incorrectCodeDetected ? (
          <View
            style={{
              marginTop: 6,
              padding: 32,
              borderRadius: 12,
              maxWidth: 430,
              width: '100%',
            }}
          >
            <View
              style={{
                position: 'relative',
                width: '100%',
                marginTop: 12,
              }}
            >
              <TextInput
                mode="flat"
                style={{
                  backgroundColor: 'transparent',
                  paddingHorizontal: 0,
                  textAlignVertical: 'center',
                  includeFontPadding: false,
                }}
                label={i18n.translate('app.fields.verification.label')}
                numberOfLines={1}
                keyboardType="numeric"
                returnKeyType="send"
                autoCapitalize="words"
                autoCorrect={false}
                autoComplete="sms-otp"
                maxLength={13}
                textContentType="oneTimeCode"
                {...Platform.select({ web: { pattern: '\\d{8}' } })}
                theme={{
                  colors: { primary: inputColor },
                }}
                accessibilityLabelledBy="code-name-description"
                value={code}
                onChangeText={setCode}
                disabled={isVerifiying}
                onSubmitEditing={() => {
                  if (
                    code.length !== 8 ||
                    !/\d{8}/.test(code) ||
                    isVerifiying
                  ) {
                    return;
                  }

                  verifyAsync({ phoneNumber, verificationToken: code }).catch(
                    () => {}
                  );
                }}
              />
              <ScreenReaderHidden
                webStyle={{ marginTop: 8 }}
                nativeStyle={{ marginTop: 8 }}
              >
                <HelperText
                  nativeID="code-name-description"
                  type="info"
                  style={{ paddingHorizontal: 0 }}
                >
                  {i18n.translate('app.fields.verification.helper_text')}
                </HelperText>
              </ScreenReaderHidden>
            </View>

            <Button
              mode="contained"
              style={{ marginTop: 12 }}
              disabled={
                code.length !== 8 || !/\d{8}/.test(code) || isVerifiying
              }
              loading={isVerifiying}
              onPress={() =>
                verifyAsync({ phoneNumber, verificationToken: code }).catch(
                  () => {}
                )
              }
              textColor="#FFF"
            >
              {i18n.translate('app.verification.actions.entry')}
            </Button>
          </View>
        ) : null}

        <View style={{ height: 480 }} />
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          maxWidth: 600,
          alignSelf: 'center',
          width: '100%',
        }}
      >
        <BackHeader />
      </View>
    </View>
  );
}
