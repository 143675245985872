import { applicationId } from 'expo-application';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import React, { useEffect, useRef } from 'react';
import { Button, Dialog, TextInput } from 'react-native-paper';
import { useEndpoint, useSetEndpoint } from './hooks/useEndpoint';

export const IS_DEBUG =
  __DEV__ ||
  ['temporary', 'test'].some((debugChannel) =>
    (Updates.releaseChannel ?? '').includes(debugChannel)
  );

export const IS_PREVIEW =
  IS_DEBUG ||
  ['preview'].some((previewChannel) =>
    (Updates.releaseChannel ?? '').includes(previewChannel)
  ) ||
  applicationId === 'com.soundersmusic.app.test';

export function DebugDialog({
  visible,
  onDismiss,
}: {
  visible: boolean;
  onDismiss(): void;
}) {
  const previousEndpoint = useEndpoint();
  const nextEndpointRef = useRef(previousEndpoint);
  const setEndpoint = useSetEndpoint();

  // Update previousEndpoint until nextEndpoint is set at least once
  useEffect(() => {
    if (nextEndpointRef.current) {
      return;
    }

    nextEndpointRef.current = previousEndpoint;
  }, [previousEndpoint, nextEndpointRef]);

  return (
    <Dialog visible={visible} onDismiss={onDismiss}>
      <Dialog.Title>Settings</Dialog.Title>
      <Dialog.Content>
        <TextInput
          disabled
          label="Release channel"
          value={Updates.releaseChannel ?? Constants.manifest?.releaseChannel}
          mode="outlined"
          style={{ marginBottom: 4 }}
        />

        <TextInput
          disabled
          label="Project ID"
          value={
            Constants.manifest?.currentFullName ??
            Constants.manifest?.originalFullName ??
            '<empty>'
          }
          mode="outlined"
          style={{ marginBottom: 8 }}
        />

        <TextInput
          key={previousEndpoint ? 'set' : 'loading'}
          disabled={previousEndpoint === undefined}
          label="Endpoint"
          defaultValue={nextEndpointRef.current || ''}
          onChangeText={(next) => (nextEndpointRef.current = next)}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <Button onPress={onDismiss}>Cancel</Button>
        <Button
          onPress={() => {
            if (nextEndpointRef.current) {
              setEndpoint(nextEndpointRef.current).then(onDismiss);
            }
          }}
        >
          Save changes
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
