import { useState, useCallback } from 'react';

export interface ModalState<T extends (...args: A) => P, A extends any[], P> {
  showing: boolean;

  show(): void;
  dismiss(): void;
  finish(...args: A): P;
}

export function useModalState<T extends (...args: any[]) => any>(
  onFinish: T
): ModalState<T, Parameters<T>, ReturnType<T>> {
  const [showing, setShowing] = useState(false);
  const show = useCallback(() => setShowing(true), [setShowing]);
  const dismiss = useCallback(() => setShowing(false), [setShowing]);
  const finish = useCallback(
    (...args: Parameters<T>) => {
      const result = onFinish(...args);
      dismiss();
      return result;
    },
    [dismiss, onFinish]
  );

  return { showing, show, dismiss, finish };
}
