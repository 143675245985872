import { motion } from 'framer-motion';

export function AnimatedBackground({
  active,
  space,
  backgroundColor,
  children,
}: {
  active: boolean;
  space: number;
  backgroundColor: string;
  children: React.ReactNode;
}) {
  return (
    <motion.div
      animate={{
        borderRadius: active ? 26 : 0,
        translateX: active ? space : 0,
        scale: active ? 550 / 812 : 1,
      }}
      transition={{
        type: 'spring',
        damping: 40,
        mass: 0.4,
        stiffness: 300,
      }}
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor,
        position: 'absolute',
        overflow: 'hidden',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      {children}
    </motion.div>
  );
}
