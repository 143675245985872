import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FetchMediaError, StructuredErrors } from 'fetch-media';
import { View } from 'react-native';
import { Surface, Title, Paragraph, Card, useTheme } from 'react-native-paper';
import { AspectRatio } from '../components/AspectRatio';

export function ErrorCard({ ratio, error }: { ratio: number; error: Error }) {
  const { dark } = useTheme();
  const title = ' ';
  const messsage =
    error instanceof FetchMediaError
      ? error instanceof StructuredErrors
        ? error.message
        : `(${error.response.status})`
      : error.message;

  return (
    <AspectRatio
      style={[
        {
          width: '100%',
          overflow: 'visible',
          marginBottom: 16,
        },
        { aspectRatio: ratio },
      ]}
      key="discovery"
    >
      <Surface
        key="event"
        theme={{ roundness: 26 / 4 }}
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          backgroundColor: dark ? '#C70D00' : '#EA9999',
          borderRadius: 10,
        }}
        elevation={1}
      >
        <View
          style={{
            paddingHorizontal: 20,
            paddingVertical: 15,
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <Title
            numberOfLines={2}
            style={{
              letterSpacing: 0.2,
              maxWidth: 400,
              marginRight: 72,
              minHeight: 40,
              includeFontPadding: false,
              color: dark ? '#FFF' : '#F9E6E5',
            }}
          >
            {title}
          </Title>
          <Paragraph style={{ color: dark ? '#FFF' : '' }}>
            {messsage || null}
          </Paragraph>
        </View>

        <Card
          elevation={1}
          style={{
            width: 52,
            height: 52,
            borderRadius: 26,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: 20,
            right: 20,
            backgroundColor: `#222`,
          }}
        >
          <MaterialCommunityIcons
            name="exclamation-thick"
            size={24}
            color="#fff"
          />
        </Card>
      </Surface>
    </AspectRatio>
  );
}
