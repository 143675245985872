import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { ReadonlyDeep } from 'type-fest';
import { useLocale } from '../hooks/useLocale';
import { usePrivateConfiguration } from '../hooks/usePrivateConfiguration';
import { useToken } from '../hooks/useToken';
import { authorization } from '../utils/authorization';

export type ApiEventListItem = ReadonlyDeep<{
  href: string;
  title: string;
}>;

export type ApiEventList = ReadonlyDeep<{
  events: {
    _links?: {
      self: {
        href: string;
      };
      shared_tracks: {
        href: string;
      };
    };

    _index: readonly ApiEventListItem[];
  };
}>;

export function useEvents({
  enabled = true,
  onError,
  ...options
}: UseQueryOptions<ApiEventList, FetchMediaError | Error> = {}) {
  const { data } = usePrivateConfiguration();
  const { ref, token, logout } = useToken();
  const url = data?.configuration._links.events.href;
  const locale = useLocale();

  return useQuery<ApiEventList, FetchMediaError | Error>(
    [locale, url, 'index'] as QueryKey,
    async ({ signal }) => {
      if (!ref.current) {
        throw new Error('Expected logged in, actual: no token');
      }

      const response = await fetchMedia(url!, {
        headers: {
          accept: ['application/vnd.soundersmusic.event.v2.index+json'].join(
            ', '
          ),
          acceptLanguage: locale,
          authorization: authorization(ref.current)!,
        },
        method: 'GET',
        disableFormData: true,
        disableFormUrlEncoded: true,
        signal,
      });

      if (response && typeof response === 'object') {
        if ('events' in response) {
          return response as ApiEventList;
        }

        throw new FetchMediaError(
          'Expected events in response, actual: ' +
            Object.keys(response).join(', '),
          { status: 500, statusText: '' }
        );
      }

      throw new FetchMediaError(
        'Expected events response, actual:' + typeof response,
        { status: 500, statusText: '' }
      );
    },
    {
      enabled: Boolean(url && token && enabled),

      onError(error) {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            logout();
          }
        }

        if (onError) {
          onError(error);
        }
      },

      ...options,
    }
  );
}
