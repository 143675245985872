import React from 'react';
import { Platform, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';

export function FormWrapper({
  children,
  authenticating,
  marginHorizontal = 38,
  marginVertical = 20,
}: {
  children: React.ReactNode;
  authenticating?: boolean;
  marginHorizontal?: number;
  marginVertical?: number;
}) {
  if (authenticating) {
    return (
      <View
        style={{
          flex: 1,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (Platform.OS === 'web') {
    return (
      <form
        style={{
          marginLeft: marginHorizontal,
          marginRight: marginHorizontal,
          marginTop: marginVertical,
          marginBottom: marginVertical,
        }}
      >
        {children}
      </form>
    );
  }

  return (
    <View
      style={{
        marginHorizontal,
        marginVertical,
      }}
    >
      {children}
    </View>
  );
}
