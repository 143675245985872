import React from 'react';
import { StyleSheet, ViewProps } from 'react-native';

export function AspectRatio(props: ViewProps) {
  /*
  const [layout, setLayout] = useState<LayoutRectangle | null>(null);

  const { aspectRatio = 1, ...inputStyle } =
    StyleSheet.flatten(props.style) || {};
  const style = [inputStyle, { aspectRatio }];

  if (layout) {
    const { width = 0, height = 0 } = layout;

    if (width === 0) {
      style.push({ width: height * aspectRatio, height });
    } else {
      style.push({ width, height: width * aspectRatio });
    }
  }

  return (
    <View
      {...props}
      style={style}
      onLayout={({ nativeEvent: { layout } }) => setLayout(layout)}
    />
  );
  */

  const { aspectRatio = 1, ...inputStyle } =
    StyleSheet.flatten(props.style) || {};

  return (
    <div
      style={{
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        ...(inputStyle as React.CSSProperties),
      }}
    >
      <div
        style={{
          display: 'block',
          width: '100%',
          paddingBottom: `${100 / aspectRatio}%`,
        }}
      />
      <div
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
