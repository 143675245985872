import { useEffect, useState } from 'react';
import { i18n } from '../locale';

export function useLocale() {
  const [locale, setLocale] = useState(() => i18n.locale);

  useEffect(() => {
    return i18n.onChange((nextI18n) => setLocale(nextI18n.locale));
  }, []);

  return locale;
}
