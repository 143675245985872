import { fetchMedia, FetchMediaError } from 'fetch-media';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { ReadonlyDeep } from 'type-fest';
import { IS_DEBUG } from '../debug';
import { i18n } from '../locale';
import { authorization } from '../utils/authorization';
import { useLocale } from './useLocale';
import { SoundersToken, useToken } from './useToken';

const DEBUG_CONFIGURATION = false;

export type ApiPrivateConfiguration = ReadonlyDeep<{
  configuration: {
    _links: {
      self: { href: string };

      my_permit: { href: string };
      my_profile: { href: string };
      my_tracks: { href: string };
      my_invitations: { href: string };
      my_journeys: { href: string };
      my_shares: { href: string };
      my_push_tokens: { href: string };
      my_phone_numbers?: { href: string };

      competitions: { href: string };
      events: { href: string };
    };
  };
}>;

export function usePrivateConfiguration({
  enabled = true,
  ...options
}: UseQueryOptions<ApiPrivateConfiguration, FetchMediaError> = {}) {
  const { token, ref, logout } = useToken();
  const endpoint = token?._links.configuration.href;
  const locale = useLocale();

  return useQuery<ApiPrivateConfiguration, FetchMediaError>(
    [locale, endpoint, 'private-configuration'] as QueryKey,
    ({ signal }) => {
      return fetchPrivateConfiguration(endpoint!, ref.current!, locale, signal);
    },
    {
      enabled: Boolean(enabled && endpoint && token),
      cacheTime: 15 * 60 * 1000, // 15 minutes fresh
      staleTime: 60 * 1000, // 60 seconds stale

      ...options,

      onError(error) {
        if (error instanceof FetchMediaError) {
          if (error.response.status === 401) {
            logout();
          }
        }

        if (options.onError) {
          return options.onError(error);
        }
      },
    }
  );
}

export async function fetchPrivateConfiguration(
  endpoint: string,
  token: SoundersToken,
  locale = i18n.locale,
  signal?: AbortSignal
) {
  if (!endpoint) {
    throw new Error('Requires endpoint to be set');
  }

  const response = await fetchMedia(endpoint, {
    method: 'GET',
    headers: {
      accept: [
        'application/vnd.soundersmusic.configuration.v4+json, application/vnd.soundersmusic.configuration.v3+json; q=0.9',
      ].join(', '),
      acceptLanguage: locale,
      authorization: authorization(token)!,
    },

    disableFormData: true,
    disableFormUrlEncoded: true,
    disableText: true,

    debug: IS_DEBUG && DEBUG_CONFIGURATION,
    signal,
  });

  if (response && typeof response === 'object') {
    if ('configuration' in response) {
      return response as ApiPrivateConfiguration;
    }

    throw new FetchMediaError(
      'Expected configuration in response, actual: ' +
        Object.keys(response).join(', '),
      { status: 500, statusText: '' }
    );
  }

  throw new FetchMediaError(
    'Expected configuration response, actual:' + typeof response,
    { status: 500, statusText: '' }
  );
}
