import Color from 'color';
import { openURL } from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { useCallback } from 'react';
import { useTheme } from 'react-native-paper';
import {
  BRANCH_API_KEY,
  DOMAIN,
  DOMAIN_BRANCH,
  DOMAIN_BRANCH_ALTERNATE,
  DOMAIN_NEXT,
} from '../config';

const domains: string[] = [
  DOMAIN,
  DOMAIN_NEXT,
  DOMAIN_BRANCH,
  DOMAIN_BRANCH_ALTERNATE,
].filter((val): val is string => Boolean(val));

export async function openExternalUrl(
  url: string | null | undefined,
  primary: string
): Promise<unknown> {
  const url_ = url && url.trim();

  if (!url_) {
    throw new Error('url missing');
  }

  const finalUrl = url_.split('/').shift()!.includes(':')
    ? url_
    : `https://${url_}`;

  if (
    domains.every((d) => !finalUrl.includes(d)) &&
    finalUrl.startsWith('http')
  ) {
    return WebBrowser.openBrowserAsync(finalUrl, {
      toolbarColor: primary,
      controlsColor: new Color(primary).isDark() ? '#FFFFFF' : '#222222',
    });
  }

  return openURL(finalUrl);
}

export function useOpenUrl(url: string) {
  const {
    colors: { primary },
  } = useTheme();

  return useCallback(() => {
    return openExternalUrl(url, primary);
  }, [url, primary]);
}
