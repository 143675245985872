import Color from 'color';
import { AVPlaybackStatusSuccess } from 'expo-av';
import React, {
  Fragment,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Image, LayoutChangeEvent, StyleSheet, View } from 'react-native';
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
  TapGestureHandler,
  TapGestureHandlerGestureEvent,
} from 'react-native-gesture-handler';
import { Card, Surface, Text } from 'react-native-paper';
import Animated, {
  Easing,
  interpolate,
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import Svg, { G, Path } from 'react-native-svg';
import { MediaPlayer } from '../player/MediaPlayer';
import { PASTEL_PURPLE, PRIMARY_DARK, PRIMARY_LIGHT } from '../theming';
import { useArtist } from '../tracks/useArtist';
import { ApiVotedTrack } from '../tracks/useVotedTracks';
import { useForceUpdate } from '../utils/useForceUpdate';
import { useValidColor } from '../utils/useValidColor';
import { IMAGE_PIXEL_RATIO, variantImageUrl } from '../utils/variants';

export function OnScreenPlayer({
  playerRef,
  visible,
  track,
}: {
  playerRef: RefObject<MediaPlayer | null>;
  visible: boolean;
  track: ApiVotedTrack | null;
}) {
  const force = useForceUpdate();
  const statusRef = useRef<AVPlaybackStatusSuccess | null>(null);

  const isVisible = useSharedValue<number>(visible ? 1 : 0);
  const animation = useDerivedValue(
    () =>
      withTiming(isVisible.value, {
        duration: 230,
        easing: Easing.inOut(Easing.cubic),
      }),
    [isVisible]
  );

  // Update shared value
  useEffect(() => {
    isVisible.value = visible ? 1 : 0;
  }, [visible]);

  const togglePlay = playerRef.current?.togglePlay;

  useEffect(() => {
    const remove = playerRef.current?.addStatusListener((status) => {
      if (statusRef.current?.isPlaying !== status.isPlaying) {
        statusRef.current = status;
        force();
      } else {
        statusRef.current = status;
      }
    });

    return () => {
      remove && remove();
    };
  }, [playerRef]);

  const style = useAnimatedStyle(() => ({
    transform: [{ translateY: interpolate(animation.value, [0, 1], [140, 0]) }],
  }));

  const url = track?.track._links.artist?.href;
  const { data } = useArtist(url);
  const { artist } = data || { artist: null };
  const src = track?.track._links.cover_image?.href;

  const size = IMAGE_PIXEL_RATIO * 52;
  const uri = variantImageUrl(src);

  return (
    <Animated.View
      style={[
        {
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: 123,
        },
        style,
      ]}
    >
      <Surface
        style={{
          elevation: 8,
          backgroundColor: PRIMARY_DARK,
          width: '100%',
          height: '100%',
        }}
      >
        <View
          style={{
            maxWidth: 800,
            alignSelf: 'center',
            marginHorizontal: 'auto',
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              paddingHorizontal: 36,
              paddingVertical: 16,
            }}
          >
            <Card
              style={{
                width: 52,
                height: 52,
                borderRadius: 5,
                backgroundColor: PRIMARY_LIGHT,
              }}
              onPress={togglePlay}
            >
              {uri ? (
                <Image
                  source={{ uri, width: size, height: size }}
                  style={{
                    width: 52,
                    height: 52,
                    borderRadius: 5,
                    backgroundColor: PRIMARY_LIGHT,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                  }}
                  accessibilityLabel={`Cover for ${track?.track.name} by ${artist?.name}`}
                />
              ) : null}
              <View
                style={{
                  position: 'absolute',
                  top: (52 - 28) / 2,
                  left: (52 - 28) / 2,
                  width: 28,
                  height: 28,
                  borderRadius: 14,
                  backgroundColor: 'white',
                }}
              >
                {statusRef.current?.isPlaying ? (
                  <Svg
                    viewBox="0 0 9 9"
                    width={10}
                    height={10}
                    style={{
                      position: 'absolute',
                      top: 9,
                      left: 9,
                    }}
                    accessibilityLabel="Pause song"
                  >
                    <G
                      fill="none"
                      stroke={PRIMARY_DARK}
                      strokeLinecap="round"
                      strokeWidth={3}
                    >
                      <Path d="M0 0L0 6" transform="translate(1.5 1.5)" />
                      <Path d="M0 0L0 6" transform="translate(7.5 1.5)" />
                    </G>
                  </Svg>
                ) : (
                  <Svg
                    width={11}
                    height={10}
                    viewBox="0 0 20 23"
                    style={{
                      position: 'absolute',
                      top: 9,
                      left: 9,
                    }}
                    accessibilityLabel="Play song"
                  >
                    <Path
                      d="M9.766,3.015a2,2,0,0,1,3.468,0L21.277,17a2,2,0,0,1-1.734,3H3.457a2,2,0,0,1-1.734-3Z"
                      transform="translate(20) rotate(90)"
                      fill={PRIMARY_DARK}
                    />
                  </Svg>
                )}
              </View>
            </Card>
            <View style={{ marginLeft: 16, justifyContent: 'center', flex: 1 }}>
              <Text
                variant="titleSmall"
                style={{
                  fontWeight: '600',
                  color: '#FFFFFF',
                  display: 'flex',
                }}
              >
                {track?.track.name}
              </Text>
              <Text
                variant="labelMedium"
                style={{
                  fontWeight: 'normal',
                  marginTop: 2,
                  color: '#FEFEFEAA',
                  includeFontPadding: false,
                  display: 'flex',
                }}
              >
                {artist?.name || ''}
              </Text>
            </View>
            <View style={{ justifyContent: 'center' }}>
              <View
                style={{
                  flexDirection: 'row',
                  marginLeft: 16,
                  alignItems: 'center',
                  minHeight: 18,
                  borderWidth: StyleSheet.hairlineWidth,
                  borderColor: `#FFFFFF1A`,
                  borderRadius: 40,
                  paddingHorizontal: 4,
                  width: 36,
                }}
              >
                <Svg
                  viewBox="0 0 11 10"
                  style={{ width: 11, height: 10, marginRight: 4 }}
                  accessibilityLabel="Your rating"
                >
                  <Path
                    fill="#ffbe16"
                    d="M5.5,0,7.15,3.357,11,3.82,8.17,6.357,8.9,10,5.5,8.211,2.1,10,2.83,6.357,0,3.82l3.85-.463Z"
                  />
                </Svg>

                <Text
                  style={{
                    fontSize: 12,
                    color: '#fff',
                    display: 'flex',
                  }}
                >
                  {track?.track.vote}
                </Text>
              </View>
            </View>
          </View>

          <ProgressControl allowSeek playerRef={playerRef} />
        </View>
      </Surface>
    </Animated.View>
  );
}

export function ProgressControl({
  allowSeek = true,
  showProgress = false,
  padding = 0,
  backgroundColor,
  barBackgroundColor,
  barColor,
  playerRef,
  height = 30,
  seekTopOffset = 0,
  paddingHorizontal = 37,
}: {
  playerRef: RefObject<MediaPlayer | null>;
  allowSeek?: boolean;
  showProgress?: boolean;
  padding?: number;
  backgroundColor?: string;
  barBackgroundColor?: string;
  barColor?: string;
  height?: number;
  seekTopOffset?: number;
  paddingHorizontal?: number;
}) {
  const isSeeking = useSharedValue(playerRef.current?.isSeeking ? 1 : 0);
  const isShowProgress = useSharedValue(showProgress && allowSeek ? 1 : 0);
  const progress = useSharedValue(playerRef.current?.progress ?? 0);
  const barWidth = useSharedValue(376);
  const positionX = useSharedValue(0);
  const duration = useSharedValue(
    playerRef.current?.state.playbackInstanceDuration ?? -1
  );

  const width = useAnimatedStyle(
    () => ({ width: `${Math.round(progress.value * 100)}%` }),
    [progress]
  );

  const seekStyle = useAnimatedStyle(() => {
    return {
      opacity: withSpring(isSeeking.value || isShowProgress.value, {
        overshootClamping: true,
      }),
      transform: [
        {
          translateX:
            Math.min(
              Math.max(padding / 2, positionX.value),
              barWidth.value - padding / 2
            ) -
            21 / 2,
          // translateY: height / 2,
        },
      ],
    };
  }, [positionX, barWidth, isSeeking, isShowProgress, padding]);

  const onLayout = useCallback(
    (event: LayoutChangeEvent) =>
      (barWidth.value = event.nativeEvent.layout.width),
    []
  );

  const onUpdate = useCallback(
    (position: {
      progress: number | null;
      current: number | null;
      total: number | null;
    }) => {
      progress.value = position.progress || 0;
      duration.value = position.total || -1;

      if (!isSeeking.value && isShowProgress.value) {
        positionX.value =
          padding / 2 + progress.value * (barWidth.value - padding);
      }
    },
    [progress, positionX, isSeeking, isShowProgress, barWidth]
  );

  useEffect(() => {
    const removeStatus = playerRef.current?.addStatusListener((status) => {
      const progress = playerRef.current?.progress ?? null;

      onUpdate({
        progress,
        current: status.positionMillis,
        total: status.durationMillis ?? null,
      });
    });

    const removeSeek = playerRef.current?.addSeekListener((from, to) => {
      const total = playerRef.current?.state.playbackInstanceDuration;
      if (!total) {
        return;
      }

      const progress = to / total;

      onUpdate({
        progress,
        current: to,
        total,
      });
    });

    return () => {
      removeSeek && removeSeek();
      removeStatus && removeStatus();
    };
  }, [playerRef]);

  const seek = useCallback((value: number) => {
    return playerRef.current?.seek(value);
  }, []);

  const startSeeking = useCallback(() => {
    return playerRef.current?.startSeeking();
  }, []);

  const onPanGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    { x: number }
  >(
    {
      onStart: ({ x }, ctx) => {
        ctx.x = x;
        positionX.value = x;
        isSeeking.value = 1;

        runOnJS(startSeeking)();
      },

      onActive: ({ translationX }, ctx) => {
        isSeeking.value = 1;
        positionX.value = translationX + ctx.x;
      },

      onEnd: ({ translationX }, ctx) => {
        positionX.value = translationX + ctx.x;
        isSeeking.value = 0;

        const size = barWidth.value - padding;
        const position = positionX.value - padding / 2;
        const percentage = position / size;

        if (duration.value <= 0) {
          return;
        }

        runOnJS(seek)(Math.round(duration.value * percentage));
      },
    },
    [seek, startSeeking]
  );

  const onTapGestureEvent = useAnimatedGestureHandler<
    TapGestureHandlerGestureEvent,
    // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  >(
    {
      onStart: ({ x }, __) => {
        positionX.value = x;
        isSeeking.value = 1;
      },

      onActive: ({ x }, _) => {
        positionX.value = x;
      },

      onFail: () => {
        isSeeking.value = 0;
      },

      onEnd: ({ x }, _) => {
        isSeeking.value = 0;

        if (duration.value <= 0) {
          return;
        }

        const size = barWidth.value - padding;
        const position = x - padding / 2;
        const percentage = position / size;

        runOnJS(seek)(Math.round(duration.value * percentage));
      },
    },
    [seek]
  );

  const finalBarColor = useValidColor(barColor, PASTEL_PURPLE);
  const finalBarBackgroundColor = useValidColor(
    barBackgroundColor,
    useMemo(
      () => new Color(finalBarColor).alpha(0.25).toString(),
      [finalBarColor]
    )
  );

  return (
    <Animated.View
      style={{
        paddingHorizontal,
        maxWidth: 542 + paddingHorizontal * 2,
        width: '100%',
      }}
    >
      <TapGestureHandler enabled={allowSeek} onGestureEvent={onTapGestureEvent}>
        <Animated.View
          style={{
            width: '100%',
            position: 'relative',
            height: 52,
          }}
        >
          <PanGestureHandler
            enabled={allowSeek}
            onGestureEvent={onPanGestureEvent}
          >
            <Animated.View
              onLayout={onLayout}
              style={{
                height,
                backgroundColor: useValidColor(
                  backgroundColor,
                  `${PRIMARY_DARK}1A`
                ),
                borderRadius: 26,
                paddingTop: (height - 8) / 2,
                paddingHorizontal: padding / 2,
              }}
            >
              <View
                style={{
                  width: '100%',
                  height: 8,
                  borderRadius: 8,
                  backgroundColor: finalBarBackgroundColor,
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <Animated.View
                  style={[
                    width,
                    {
                      position: 'absolute',
                      height: '100%',
                      borderRadius: 8,
                      backgroundColor: finalBarColor,
                    },
                  ]}
                />
              </View>
              <Animated.View
                style={[
                  seekStyle,
                  {
                    position: 'absolute',
                    top: 6 + seekTopOffset,
                    left: 0,
                  },
                ]}
              >
                <Card
                  elevation={5}
                  style={{
                    backgroundColor: 'white',
                    width: 21,
                    height: 21,
                    borderRadius: 16,
                  }}
                >
                  <Fragment />
                </Card>
              </Animated.View>
            </Animated.View>
          </PanGestureHandler>
        </Animated.View>
      </TapGestureHandler>
    </Animated.View>
  );
}
