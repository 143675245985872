import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useIsFocused, useLinkProps } from '@react-navigation/native';
import Color from 'color';
import { FetchMediaError } from 'fetch-media';
import React, { useReducer } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Caption,
  Card,
  Paragraph,
  Surface,
  Text,
  Title,
  useTheme,
} from 'react-native-paper';
import { Easing } from 'react-native-reanimated';
import { AnimatedBackgroundView } from '../components/AnimatedBackgroundView';
import { AnimatedImage } from '../components/AnimatedImage';
import { AspectRatio } from '../components/AspectRatio';
import { PressableSurface } from '../components/PressableSurface';
import { DISCOVERY_EVENT_ID } from '../config';
import { ApiEvent, useEvent } from '../events/useEvent';
import { ApiEventProgress, useEventProgress } from '../events/useEventProgress';
import { i18n } from '../locale';
import { prepareUrl } from '../navigation/LinkingConfiguration';
import { DARK_PURPLE, PRIMARY_DARK, PRIMARY_LIGHT } from '../theming';
import { sanitizeColor } from '../utils/sanitzeColor';
import { useLerpWidth } from '../utils/useLerpWidth';
import { IMAGE_PIXEL_RATIO, variantImageUrl } from '../utils/variants';
import { ErrorCard } from './ErrorCard';
import { IndexedSkeletonCard, skeletonBackground } from './SkeletonCard';

export function DiscoverEventCard({
  href,
  title,
  index,
}: {
  href: string;
  title: string;
  index: number;
}) {
  const { dark } = useTheme();
  const enabled = useIsFocused();
  const { data: eventData, isLoading, error } = useEvent(href, { enabled });
  const { data: progressData, isLoading: isLoadingProgress } = useEventProgress(
    href,
    { enabled }
  );

  const isDone =
    progressData?.event &&
    progressData.event.progress >= progressData.event.total_count;
  const skeleton = skeletonBackground(index, dark);

  if (eventData && !isLoadingProgress) {
    return isDone ? (
      <CompletedCard key="done" event={eventData.event} />
    ) : (
      <EventCard
        key="event"
        event={eventData.event}
        progress={progressData}
        skeleton={skeleton}
      />
    );
  }

  if (error) {
    const isDiscovery = href.endsWith(DISCOVERY_EVENT_ID || '___');
    const ratio = isDiscovery ? 1 : 346 / 280;

    if (
      error instanceof FetchMediaError &&
      (error.response.status === 410 || error.response.status === 404)
    ) {
      return null;
    }

    return <ErrorCard ratio={ratio} error={error} />;
  }

  return <IndexedSkeletonCard index={index} />;
}

export function EventCard({
  event,
  progress,
  skeleton,
  cursor,
}: {
  progress: ApiEventProgress | undefined;
  skeleton?: string;
  cursor?: string;
} & ApiEvent) {
  const { dark } = useTheme();

  const backgroundColor = sanitizeColor(
    event.background_color,
    dark ? DARK_PURPLE : '#FFF'
  );
  const primaryColor = sanitizeColor(event.primary, PRIMARY_LIGHT);
  const textColor = new Color(backgroundColor).isDark() ? '#fff' : PRIMARY_DARK;
  const primaryTextColor = new Color(primaryColor).isDark() ? '#fff' : '#111';

  const isDiscovery = event._links.self.href.endsWith(
    DISCOVERY_EVENT_ID || '___'
  );

  const ratio = isDiscovery ? 1 : 346 / 280;
  const linkProps = useLinkProps({
    to: [
      `/events/${prepareUrl(event._links.self.href)}`,
      cursor ? `cursor=${encodeURIComponent(cursor)}` : undefined,
    ]
      .filter(Boolean)
      .join('?'),
  });

  // 375 designed width
  //
  // title:
  // - font size 30
  // - line height 34
  // - margin bottom 32
  //
  // description:
  // - font size 16
  // - line height 22

  const lerpWidth = useLerpWidth([315, 0], [375, 1]);

  return (
    <AspectRatio
      style={[
        {
          width: '100%',
          overflow: 'visible',
          marginBottom: 16,
        },
        { aspectRatio: ratio },
      ]}
    >
      <PressableSurface
        key="event"
        theme={{ roundness: 26 / 4 }}
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          backgroundColor: skeleton ?? (dark ? DARK_PURPLE : '#666'),
          borderRadius: 10,
        }}
        elevation={2}
        linkProps={linkProps}
        accessibilityLabel={i18n.translate('app.discovery.actions.goto_event')}
      >
        <BackgroundImage
          src={event?._links?.background_image?.href}
          color={backgroundColor}
          cover={!isDiscovery}
        />
        <ForegroundImage
          src={event?._links?.foreground_image?.href}
          animate={isDiscovery}
        />
        <ForegroundImage src={event?._links?.icon?.href} iconify />

        <View
          style={{
            padding: 20,
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <Text
            variant="titleMedium"
            numberOfLines={2}
            style={{
              letterSpacing: 0.2,
              maxWidth: isDiscovery ? 160 + lerpWidth * 50 : 400,
              marginRight: 72,
              minHeight: 40,
              includeFontPadding: false,
              color: textColor,
              fontWeight: '600',
              display: 'flex',

              fontSize: 24 + lerpWidth * 6, // target: 30
              lineHeight: 30 + lerpWidth * 4, // target: 34
              marginBottom: 12 + 0.2 * 20, // target: 32
            }}
          >
            {event._links.self.title || ' '}
            {'\n'}
          </Text>

          <Text
            variant="bodyMedium"
            numberOfLines={4}
            style={{
              color: textColor,
              includeFontPadding: false,
              display: 'flex',

              fontSize: 12 + lerpWidth * 2, // target: 16
              lineHeight: 20 + lerpWidth * 2, // target: 22
            }}
          >
            {event.description}{' '}
            {progress?.event.progress !== undefined ? (
              <Text
                variant="labelSmall"
                style={{ opacity: 0.5, color: textColor, display: 'flex' }}
              >
                {progress.event.progress}/{progress.event.total_count}
              </Text>
            ) : null}
          </Text>
        </View>

        <Card
          elevation={2}
          style={{
            width: 52,
            height: 52,
            borderRadius: 26,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: 20,
            right: 20,
            backgroundColor: primaryColor,
          }}
        >
          <MaterialCommunityIcons
            name="arrow-right"
            size={24}
            color={primaryTextColor}
          />
        </Card>
      </PressableSurface>
    </AspectRatio>
  );
}

function BackgroundImage({
  src,
  color,
  cover = true,
}: {
  src?: string;
  color: string;
  cover?: boolean;
}) {
  const { roundness } = useTheme();

  if (!src) {
    const backgroundColor = sanitizeColor(color, '#FFFFFFFF');

    return (
      <AnimatedBackgroundView
        from="#ffffff00"
        animate={backgroundColor}
        style={[
          StyleSheet.absoluteFill,
          {
            borderRadius: roundness,
            backgroundColor: color,
          },
        ]}
      />
    );
  }

  const size = IMAGE_PIXEL_RATIO * 346;
  const uri = variantImageUrl(src, 'size');

  return (
    <AnimatedImage
      fadeDuration={0}
      animateOpacity
      resizeMode={cover ? 'cover' : 'contain'}
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: roundness,
      }}
      source={{ uri, width: size, height: size }}
    />
  );
}

function ForegroundImage({
  src,
  iconify,
  animate,
}: {
  src?: string;
  iconify?: boolean;
  animate?: boolean;
}) {
  const [loaded, setLoaded] = useReducer(() => true, false);

  if (!src) {
    return null;
  }

  const size = IMAGE_PIXEL_RATIO * 346;
  const uri = variantImageUrl(src, 'size');

  return (
    <AnimatedImage
      fadeDuration={0}
      animateOpacity
      animateY={animate ? 30 : undefined}
      resizeMode="contain"
      transition={{ overshootClamping: true, easing: Easing.out(Easing.cubic) }}
      style={
        iconify
          ? {
              width: 60,
              maxHeight: 60,
              top: 16,
              right: 16,
              position: 'absolute',
            }
          : {
              width: '100%',
              height: '50%',
              position: 'absolute',
              bottom: 0,
            }
      }
      source={{ uri, width: size / 2, height: size / 2 }}
      onLoad={setLoaded}
    />
  );
}

function CompletedCard({ event }: ApiEvent) {
  return (
    <Card
      style={[
        {
          height: 72,
          width: '100%',
          marginBottom: 16,
          overflow: 'hidden',
          borderRadius: 10,
        },
      ]}
      elevation={1}
    >
      <View
        style={{
          paddingHorizontal: 20,
          paddingVertical: 15,
          maxHeight: '100%',
          maxWidth: '100%',
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <Text
          variant="titleMedium"
          numberOfLines={1}
          style={{
            marginRight: 72,
            color: '#888',
            maxWidth: undefined,
            fontSize: 32,
            lineHeight: 42,
            display: 'flex',

            includeFontPadding: false,
          }}
        >
          {event._links.self.title}
        </Text>

        <Card
          elevation={0}
          style={[
            {
              width: 52,
              height: 52,
              borderRadius: 26,
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              right: 20,
              top: 12,
              backgroundColor: '#fefefe00',
              flexShrink: 0,
            },
          ]}
        >
          <MaterialCommunityIcons name="check" size={24} color="#888" />
        </Card>
      </View>
    </Card>
  );
}
