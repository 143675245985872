import { Platform, View } from 'react-native';
import { HelperText } from 'react-native-paper';
import { BackHeader } from './BackHeader';
import { ScreenScrollView } from './ScreenScrollView';

export function ErrorScreen({ error }: { error: Error }) {
  return (
    <ScreenScrollView
      style={{
        width: '100%',
        height: '100%',
        maxHeight: Platform.select({ web: '100vh', default: '100%' }),
      }}
      contentContainerStyle={{
        width: '100%',
        maxWidth: 600,
        minHeight: '100%',
        alignSelf: 'center',
        paddingVertical: 64,
      }}
      useWindowScrolling={false}
    >
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <HelperText
          type="error"
          style={{ alignSelf: 'center', textAlign: 'center' }}
        >
          {error.message}
        </HelperText>
      </View>

      <View
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <BackHeader />
      </View>
    </ScreenScrollView>
  );
}
