import { applicationId } from 'expo-application';
import { StoredMemoryValue } from 'expo-use-memory-value';
import {
  createContext,
  createElement,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useIsMounted } from 'use-is-mounted';

const STORAGE_KEY = `${applicationId ?? 'com.soundersmusic.app'}.redirect-back`;
const REDIRECT_BACK = new StoredMemoryValue<string>(
  STORAGE_KEY,
  false,
  undefined
);

const RedirectContext = createContext<
  RedirectState & {
    redirect(next: string | null): Promise<unknown>;
    redirected(): Promise<unknown>;
  }
>({
  ...makeInitialState(),
  redirect() {
    return Promise.reject(new Error(''));
  },
  redirected() {
    return Promise.reject(new Error(''));
  },
});

export function RedirectProvider({
  children,
}: React.PropsWithChildren<object>) {
  const result = useProvideRedirect();
  return createElement(RedirectContext.Provider, { value: result }, children);
}

export function useRedirectBack() {
  const value = useContext(RedirectContext);
  if (value === null) {
    throw new Error(
      'Expected provided RedirectContext value, got null. Did you forget to wrap the app in a RedirectProvider?'
    );
  }

  return value;
}

function useProvideRedirect() {
  const isMountedRef = useIsMounted();
  const [state, dispatch] = useReducer(redirectReducer, null, makeInitialState);

  const redirect = useCallback(
    async (originalPath: string) => {
      await REDIRECT_BACK.emit(originalPath);
      if (!isMountedRef.current) {
        return;
      }

      dispatch({ type: 'redirect', path: originalPath });
      // TODO: navigation redirect?
    },
    [dispatch]
  );

  const redirected = useCallback(async () => {
    await REDIRECT_BACK.emit(null);
    if (!isMountedRef.current) {
      return;
    }

    dispatch({ type: 'redirected' });
  }, [dispatch]);

  // Hydrate the store, reducer, and redirect path when it first mounts. This
  // will also set the current value of the global REDIRECT variable, which will
  // remain being set even if this provider (hook) unmounts.
  useEffect(() => {
    if (state.hydrated) {
      return;
    }

    let mounted = true;
    REDIRECT_BACK.hydrate()
      .then(() => {
        if (!mounted) {
          return;
        }

        dispatch({ type: 'hydrated', path: REDIRECT_BACK.current ?? null });
      })
      .catch((e) => {
        console.log('Tried to hydrated', state.hydrated, e);

        if (!mounted) {
          // no-op
          return;
        }

        dispatch({ type: 'hydrated', path: REDIRECT_BACK.current ?? null });
      });

    return () => {
      mounted = false;
    };
  }, [state.hydrated]);

  return {
    ...state,
    redirect,
    redirected,
  };
}

function makeInitialState(): RedirectState {
  return {
    path: null,
    hydrated: REDIRECT_BACK.current !== undefined,
  };
}

type RedirectState = {
  path: null | string;
  hydrated: boolean;
};

type RedirectAction =
  | {
      type: 'hydrated';
      path: null | string;
    }
  | {
      type: 'redirect';
      path: null | string;
    }
  | { type: 'redirected' };

function redirectReducer(
  state: RedirectState,
  action: RedirectAction
): RedirectState {
  switch (action.type) {
    case 'hydrated': {
      return { ...state, path: action.path, hydrated: true };
    }
    case 'redirect': {
      return { ...state, path: action.path ?? state.path };
    }
    case 'redirected': {
      return { ...state, path: null };
    }
  }

  return state;
}
