import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { ImageSourcePropType, PixelRatio, Platform } from 'react-native';
import Animated, {
  Easing,
  interpolate,
  runOnUI,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

const EMPTY_IMAGE = {
  uri: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOU5Tf5DwACEgFhfOFoMQAAAABJRU5ErkJggg==',
  width: 1,
  height: 1,
};

export function CoverImage({
  src,
  isPlaying,
  containerSize,
}: {
  src?: string;
  isPlaying: boolean;
  containerSize: number;
}) {
  const ratio = Math.min(1, Math.min(3, Math.round(PixelRatio.get()))) + 1;
  const size = ratio * 346;

  const [source, setSource] = useState<ImageSourcePropType>(() => EMPTY_IMAGE);

  const discImage =
    ratio <= 2
      ? require('../../assets/disc_2x.png')
      : require('../../assets/disc_3x.png');

  useLayoutEffect(() => {
    const uri = src
      ? src.replace(/(%7[Bb]|{)variant(%7[Dd]|})/, `size_${ratio}x`)
      : undefined;

    // iOS has the "default source" property, which can be used to show an image
    // during network load. The other platforms don't have this and need some
    // fiddling to achieve the same.

    if (Platform.OS !== 'ios') {
      setSource(() => EMPTY_IMAGE);

      const requestedFrame = setTimeout(
        () => setSource(uri ? { uri, width: size, height: size } : discImage),
        0
      );
      return () => clearTimeout(requestedFrame);
    } else {
      setSource(uri ? { uri, width: size, height: size } : discImage);
    }
  }, [src]);

  const loop = useSharedValue(0);
  const currentOffset = useSharedValue(0);

  // Make it loop indefinitely
  useEffect(() => {
    const startLoop = () => {
      loop.value = withRepeat(
        withTiming(1, { duration: 5 * 1000, easing: Easing.linear }),
        -1
      );
    };

    runOnUI(startLoop)();
  }, [loop]);

  const animation = useDerivedValue(
    () => interpolate(loop.value, [0, 1], [0, 360]),
    [loop]
  );

  // Make sure that when the song is paused, the offset is stored and when the
  // song is unpaused, it doesn't jump (that much).
  const storeLastValue = useCallback(
    (isPlaying: boolean) => {
      if (isPlaying) {
        currentOffset.value = currentOffset.value - animation.value;
      } else {
        currentOffset.value = currentOffset.value + animation.value;
      }
    },
    [currentOffset]
  );

  useEffect(() => {
    runOnUI(storeLastValue)(isPlaying);
  }, [isPlaying, storeLastValue]);

  const rotation = useDerivedValue(() => {
    return isPlaying
      ? (currentOffset.value + animation.value) % 360
      : currentOffset.value % 360;
  }, [animation, currentOffset, isPlaying]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: `${rotation.value}deg`,
        },
      ],
    };
  }, [rotation]);

  return (
    <Animated.Image
      key={src}
      source={source}
      resizeMode="cover"
      style={[
        animatedStyle,
        {
          left: 0,
          top: 0,
          width: containerSize,
          height: containerSize,
          borderRadius: containerSize / 2,
          position: 'absolute',
        },
      ]}
      onError={() => {
        setSource(discImage);
      }}
    />
  );
}
